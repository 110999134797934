import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import 'react-toastify/dist/ReactToastify.css';
import Tablereact from '../common/tabulator/tablereact'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DirectorioInicio = (props) => {
	const [directorio, setDirectorio] = useState(false);

	useEffect(() => {
		fetch('/p_directorio')
		.then((res) => res.json())
		.then((res) => {
			if(res.status === 'OK'){ setDirectorio(res.result) }
			else{ console.log('ERROR') }
		})
		.catch((err) => { console.log('ERROR: '+err) })
	}, []);

	const cabecera = [
        { Header: <b>Id</b>, accessor: 'ID', style: { textAlign: 'center'}, width:'80', show:false },
        { Header: <b>Titulo</b>, accessor: 'NOMBRE', style: { textAlign: 'center'}, width:'auto', editable:true , show:true}
	];

	const agregar=()=>props.history.push('/directorio/agregar')
	const actualizar=(dato)=>props.history.push('/directorio/editar/'+dato.ID)
	const eliminar=(dato)=>{
		fetch('/p_directorio',{ method: 'DELETE', body: JSON.stringify({ID: dato.ID}), headers: { 'Content-Type': 'application/json' }})
		.then( res => res.json() )
		.then( res => {
			if(res.status === 'OK'){
				setDirectorio(directorio.filter(x=>x.ID!==dato.ID))
				toast.success("Se eliminó el registro", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else
				toast.warn("No se pudo borrar el registro", { position: toast.POSITION.BOTTOM_RIGHT })
		})
		.catch( (err) => {
			toast.error("Ha ocurrido un error", { position: toast.POSITION.BOTTOM_RIGHT })
		})
	}

	return (
		<Fragment>
			<Breadcrumb parent="directorio"/>
			<div className="card">
				<div className="container-fluid">
					{
						!directorio ?
						<div className="d-flex justify-content-center align-items-center">
							<div className="loader-box mr-4">
								<div className="loader">
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
								</div>
							</div>
							<h5 className="text-uppercase">Cargando...</h5>
						</div>
						:
						<div className="row">
							<div className="col-sm-12 mt-3">
								<Tablereact
									myData={directorio}
									cabecera={cabecera}
									pageSize={10}
									paginacion={true}
									buscador='Buscar'
									eliminar_actualizar={{eliminar,actualizar}}
									boton={{text:'Agregar',function:agregar}}
								/>
							</div>
						</div>
					}
				</div>
			</div>
		</Fragment>
		);
}
 
export default DirectorioInicio;