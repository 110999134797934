import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import 'react-bootstrap-typeahead/css/Typeahead.css';

//CUSTOM COMPONENTS
import Elemts from '../common/elemts/elemts2';
import Dropimage from '../common/dropimage';

//options
const editable = true;

const Editor = props => {
	const [error, setError] = useState(false);
	const [id_sabias_que] = useState(props.match.params.id);

	//ELEMENTOS LISTA
	const [elemts, setElements] = useState(
		[{ "order": 0, "key": 0, "data": {}, "title": "Descripcion" }]
	);
	
	//DATOS DE LA NOTICIA
	const [data, setData] = useState({
		titulo: '',
		portada: {},
		fuente: '',
		etiquetas: '',
	});

	//validacion
	const val = {
		titulo: error && data.titulo === '',
		portada: error && (data.portada.base ? data.portada.base === '' : true),
		fuente: error && data.fuente === '',
		etiquetas: error && data.etiquetas === '',
	}

	useEffect(() => {
		const getDatoCotidiano = async () => {
			let response = await fetch('/z_sabias_que', {
				method: 'POST',
				body: JSON.stringify({ID: id_sabias_que}),
				headers: {'Content-Type': 'application/json'}
			});
			try {
				let res = await response.json();
				if(res.data.length){
					setData({
						titulo:res.data[0].TITULO,
						portada:{name:res.data[0].TITULO,base:res.data[0].PORTADA},
						fuente: res.data[0].FUENTE,
						etiquetas: res.data[0].ETIQUETAS
					})
		
					const element_temp=[{ "order": 0, "key": 0, "data": {"code":res.data[0].CONTENIDO}, "title": "Descripcion" }]
		
					let galerias = {};
					res.elemts.map(e=>{
						if(e.TIPO==="Galería"){
							let imagen ={
								order:e.POSICION_IMAGEN,
								active:true,
								image:{
								  name:e.DESCRIPCION+'.png',
								  base:e.IMAGEN
								},
								des:e.DESCRIPCION
							}
							galerias['galeria_'+e.POSICION]={
								order:e.POSICION,
								key:1,
								data:{
								  list:galerias['galeria_'+e.POSICION]?
									galerias['galeria_'+e.POSICION].data.list=[...galerias['galeria_'+e.POSICION].data.list,imagen]
								  :
									[imagen]
								},
								title:"Galería"
							}
						}

						return e
					})
					Object.values(galerias).map(e=>element_temp.push(e));
		
					res.elemts.map(e=>{
						if(e.TIPO==="Video")
							element_temp.push({order:e.POSICION,key:2,data:{key:(e.TIPO_VIDEO==="YouTube"?0:1),title:e.TIPO_VIDEO,link:e.URL,code:e.CODE},title:"Video"})
						return e
					})
	
					setElements([...element_temp.sort((a, b)=>{
						if (a.order > b.order) {
						  return 1;
						}
						if (a.order < b.order) {
						  return -1;
						}
						return 0;
					})]);
				}
				else{
					props.history.push('/cotidiano');
				}
			}
			catch(err){
				console.log('El error',err)
			}
		}
		getDatoCotidiano();
		// eslint-disable-next-line
	}, []);

	//CAMBIOS NOTICIAS
	const set = (name, value) => {
		if (name !== 'elemts') {
			var temp = data;
			temp[name] = value;
			setData({
				titulo: temp.titulo,
				portada: temp.portada,
				etiquetas: temp.etiquetas,
				fuente: temp.fuente,
			});
		}
		else {
			setElements([...value]);
		}
	}

	let lock = true;

	//GUARDAR NOTICIA
	const guardar = async () => {
		console.log('Datos',data,'Elementos',elemts)
		var flag = (data.titulo !== ''
			&& data.portada.base ? data.portada.base !== '' : false
			&& data.fuente !== ''
			&& data.etiquetas !== ''
		);
		if (flag && lock) {
			const toastId = toast.warn('Guardando...', { autoClose: 10000 });
			lock = false;
			const data_send = {
				titulo: data.titulo,
				portada: data.portada.base,
				etiquetas: data.etiquetas,
				fuente: data.fuente,
			}
			console.log('Datos',data_send,'Elementos',elemts)
			fetch('/p_sabias', {
				method: 'PUT',
				body: JSON.stringify({
					ID: id_sabias_que,
					data: data_send,
					elementos: elemts
				}),
				headers: { 'Content-Type': 'application/json' }
			})
			.then(res=>res.json())
			.then(res=>{
				console.log(res)
				lock = true;
				toast.dismiss(toastId.current);
				toast.success('Guardado!!',{autoClose: 1500})
				setTimeout(() => props.history.push('/sabias'), 1500)
			})
			.catch(err=>{
				console.log(err)
				lock = true;
				toast.dismiss(toastId.current);
				setTimeout(() => toast.warn('Error...'), 1500)
			})
		}
		else{
			setError(true);
			setTimeout(() => toast.warn('No puedes dejar campos vacíos.'), 1500)
		}
	}

	return (
		<Fragment>
			<Breadcrumb title="Editar Sabías qué" parent="Sabías qué" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12" >
						<Formulario noticia={data} set={set} val={val} />
						<Elemts noticia={elemts} set={set} editable={editable} guardar={guardar}/>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

const Formulario = props => {
	const { val } = props;
	const noticia = props.noticia;
	const set = e => { props.set(e.target.name, e.target.value) }

	return (
		<Fragment>
			<div className="card mt-2">
				<div className="card-body">

					<div className="form-row" >
						{/* INPUT PORTADA */}
						<div className="col-md-4 mb-2">
							<label>Portada</label>
							<Dropimage
								imagen={noticia.portada}
								onUpload={val => props.set('portada', val)}
								// maxSize={307200}
							/>
							<span className="error text-danger">
								{val.portada ? 'Seleccione una portada' : ''}
							</span>
						</div>
						<div className="col-md-8 mb-2">
							<div className="form-row" >
								{/* INPUT TITULO */}
								<div className="col-md-12 mb-3">
									<label>¿Sabías qué?...</label>
									<input
										className="form-control"
										name="titulo"
										type="text"
										autoComplete="off"
										placeholder="¿Sabías qué?..."
										value={noticia.titulo}
										onChange={set}
									/>
									<span className="error text-danger">
										{val.titulo ? 'Ingresa el titulo' : ''}
									</span>
								</div>
								{/* INPUT FUENTE */}
								<div className="col-md-12 mb-2">
									<label>Fuente</label>
									<input
										className="form-control"
										name="fuente"
										type="text"
										autoComplete="off"
										placeholder="Fuente..."
										value={noticia.fuente}
										onChange={set}
									/>
									<span className="error text-danger">
										{val.fuente ? 'Ingrese la fuente' : ''}
									</span>
								</div>
							</div>

						</div>
					</div>

					<div className="form-row">
						<div className="col-md-12 mb-3">
							<label>Etiquetas</label>
							<input
								className="form-control"
								name="etiquetas"
								type="text"
								autoComplete="off"
								placeholder="Etiquetas..."
								value={noticia.etiquetas}
								onChange={set}
							/>
							<span className="error text-danger">
								{val.etiquetas ? 'Ingrese la etiqueta' : ''}
							</span>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Editor;