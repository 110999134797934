import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';
import App from "./components/app";

// Import custom Components 
import Nosotros from './components/Nosotros/nosotros'

// Import custom Components 
import IntroComunicados from './components/Comunicados/intro'
import Comunicado from './components/Comunicados/Comunicado'
import ComunicadosInicio from './components/Comunicados/ComunicadosInicio'
import ComunicadosRecibidos from './components/Comunicados/ComunicadosRecibidos'
import ComunicadosPublicados from './components/Comunicados/ComunicadosPublicados'
import ComunicadosAgregar from './components/Comunicados/ComunicadosAgregar'
import ComunicadosEditar from './components/Comunicados/ComunicadosEditar'

import CapitalHumanoPublicados from './components/CapitalHumano/CapitalHumanoPublicados'
import CapitalHumanoAgregar from './components/CapitalHumano/CapitalHumanoAgregar'
import CapitalHumanoEditar from './components/CapitalHumano/CapitalHumanoEditar'
import CapitalHumano from './components/CapitalHumano/CapitalHumano'

import Categorias from './components/Categorias/Categorias'
import IntroNoticias from './components/Publicaciones/intro'
import Publicacion from './components/Publicaciones/Publicacion'
import Publicaciones from './components/Publicaciones/Publicaciones'
import PublicacionesInicio from './components/Publicaciones/PublicacionesInicio'
import PublicacionesAgregar from './components/Publicaciones/PublicacionesAgregar'
import PublicacionesEditar from './components/Publicaciones/PublicacionesEditar'
import Cotidiano from './components/Cotidiano/Cotidiano'
import CotidianoAgregar from './components/Cotidiano/CotidianoAgregar'
import CotidianoEditar from './components/Cotidiano/CotidianoEditar'
import CotidianoFeed from './components/Cotidiano/CotidianoFeed'
import NotaCotidiana from './components/Cotidiano/NotaCotidiana'
import SabiasQue from './components/SabiasQue/SabiasQue'
import SabiasQueTabla from './components/SabiasQue/SabiasQueTabla'
import SabiasQueAgregar from './components/SabiasQue/SabiasQueAgregar'
import SabiasQueEditar from './components/SabiasQue/SabiasQueEditar'
import Empresas from './components/Empresas/Empresas'
import EmpresasAgregar from './components/Empresas/EmpresasAgregar'
import EmpresasEditar from './components/Empresas/EmpresasEditar'
import IntroDirectorio from './components/Directorio/intro'
import Directorio from './components/Directorio/Directorio'
import DirectorioInicio from './components/Directorio/DirectorioInicio'
import DirectorioAgregar from './components/Directorio/DirectorioAgregar'
import DirectorioEditar from './components/Directorio/DirectorioEditar'
import IntroCalendario from './components/Calendario/intro'
import Calendario from './components/Calendario/Calendario'
import Eventos from './components/Calendario/Eventos'
import EventosAgregar from './components/Calendario/EventosAgregar'
import EventosEditar from './components/Calendario/EventosEditar'
import Festividades from './components/Festividades/Festividades'
import FestividadesAgregar from './components/Festividades/FestividadesAgregar'
import FestividadesEditar from './components/Festividades/FestividadesEditar'
import Solicitudes from './components/Solicitudes/Solicitudes'
import SolicitudAgregar from './components/Solicitudes/SolicitudAgregar'
import Divisiones from './components/Divisiones/Divisiones'
import Departamentos from './components/Departamentos/Departamentos'
import Puestos from './components/Puestos/Puestos'
import Usuarios from './components/Usuarios/Usuarios'
import UsuariosAgregar from './components/Usuarios/UsuariosAgregar'
import UsuariosEditar from './components/Usuarios/UsuariosEditar'
import EditarPerfil from './components/Usuarios/Perfil'
import PerfilContra from './components/Usuarios/PerfilContra'
import Notificaciones from './components/Notificaciones/Notificaciones'
import CambiarContrasena from './pages/change_password';
import Recuperar from './pages/reset';
import TelefonoRojo from './components/TelefonoRojo/TelefonoRojo'
import TelefonoRojoAgregar from './components/TelefonoRojo/TelefonoRojoAgregar'
import Comentario from './components/TelefonoRojo/Comentario'
import PreguntasFrecuentes from './components/Ayuda/PreguntasFrecuentes'
import Login from './pages/login';
import TelefonoRojoNuevo from './pages/BuzonRojo'
import CerrarSesion from './components/common/cerrar_sesion';
import Autenticacion from './pages/auth'
import Informacion from './components/Informacion/Informacion';

import "./assets/scss/tabulator_styles.css"

function Root() {

	return (
		<div className="App">
			<Provider store={store}>
				<BrowserRouter basename={'/'}>
					<ScrollContext>
						<Switch>
						<Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
						<Route exact path={`${process.env.PUBLIC_URL}/cerrar_sesion`} component={CerrarSesion} />
						<Route exact path={`${process.env.PUBLIC_URL}/cambiar_contrasena`} component={CambiarContrasena} />
						<Route exact path={`${process.env.PUBLIC_URL}/new_pass`} component={Recuperar} />
						<Route exact path={`${process.env.PUBLIC_URL}/buzon_rojo/nuevo`} component={TelefonoRojoNuevo} />
						<Route exact path={`${process.env.PUBLIC_URL}/autenticacion`} component={Autenticacion} />
						<Route exact path={`${process.env.PUBLIC_URL}/informacion`} component={Informacion} />
							<Fragment>
								<App>
										<Route path={`${process.env.PUBLIC_URL}/nosotros`} component={Nosotros} />
										<Route path={`${process.env.PUBLIC_URL}/notificaciones`} component={Notificaciones} />
										<Route exact path={`${process.env.PUBLIC_URL}/comunicados`} component={IntroComunicados} />
										<Route path={`${process.env.PUBLIC_URL}/comunicados/comunicado/:id`} component={Comunicado} />
										<Route path={`${process.env.PUBLIC_URL}/comunicados/inicio`} component={ComunicadosInicio} />
										<Route path={`${process.env.PUBLIC_URL}/comunicados/recibidos`} component={ComunicadosRecibidos} />
										<Route path={`${process.env.PUBLIC_URL}/comunicados/publicados`} component={ComunicadosPublicados} />
										<Route path={`${process.env.PUBLIC_URL}/comunicados/agregar`} component={ComunicadosAgregar} />
										<Route path={`${process.env.PUBLIC_URL}/comunicados/editar/:id`} component={ComunicadosEditar} />

										<Route path={`${process.env.PUBLIC_URL}/capitalhumano/publicados`} component={CapitalHumanoPublicados} />
										<Route path={`${process.env.PUBLIC_URL}/capitalhumano/agregar`} component={CapitalHumanoAgregar} />
										<Route path={`${process.env.PUBLIC_URL}/capitalhumano/editar/:id`} component={CapitalHumanoEditar} />
										<Route path={`${process.env.PUBLIC_URL}/capitalhumano/nota/:id`} component={CapitalHumano} />
										
										<Route exact path={`${process.env.PUBLIC_URL}/categorias`} component={Categorias} />

										<Route path={`${process.env.PUBLIC_URL}/publicacion/:id`} component={Publicacion} />
										<Route exact path={`${process.env.PUBLIC_URL}/noticias`} component={IntroNoticias} />
										<Route exact path={`${process.env.PUBLIC_URL}/publicaciones`} component={Publicaciones} />
										<Route exact path={`${process.env.PUBLIC_URL}/noticias/lista`} component={PublicacionesInicio} />
										<Route path={`${process.env.PUBLIC_URL}/publicaciones/agregar`} component={PublicacionesAgregar} />
										<Route path={`${process.env.PUBLIC_URL}/publicaciones/editar/:id`} component={PublicacionesEditar} />

										<Route exact path={`${process.env.PUBLIC_URL}/cotidiano`} component={Cotidiano} />
										<Route path={`${process.env.PUBLIC_URL}/cotidiano/agregar`} component={CotidianoAgregar} />
										<Route path={`${process.env.PUBLIC_URL}/cotidiano/editar/:id`} component={CotidianoEditar} />
										<Route path={`${process.env.PUBLIC_URL}/notascotidianas`} component={CotidianoFeed} />
										<Route path={`${process.env.PUBLIC_URL}/notas_cotidianas/:id`} component={NotaCotidiana} />

										<Route path={`${process.env.PUBLIC_URL}/sabiasque/agregar`} component={SabiasQueAgregar} />
										<Route path={`${process.env.PUBLIC_URL}/sabiasque/editar/:id`} component={SabiasQueEditar} />
										<Route path={`${process.env.PUBLIC_URL}/sabias`} component={SabiasQueTabla} />
										<Route path={`${process.env.PUBLIC_URL}/sabias_que/:id`} component={SabiasQue} />


										<Route exact path={`${process.env.PUBLIC_URL}/empresas`} component={Empresas} />
										<Route path={`${process.env.PUBLIC_URL}/empresas/agregar`} component={EmpresasAgregar} />
										<Route path={`${process.env.PUBLIC_URL}/empresas/editar/:id`} component={EmpresasEditar} />

										<Route path={`${process.env.PUBLIC_URL}/comentario/:id`} component={Comentario} />

										<Route path={`${process.env.PUBLIC_URL}/preguntas_frecuentes`} component={PreguntasFrecuentes} />

										<Route exact path={`${process.env.PUBLIC_URL}/buzon_rojo`} component={TelefonoRojo} />
										<Route path={`${process.env.PUBLIC_URL}/buzon_rojo/agregar`} component={TelefonoRojoAgregar} />

										<Route exact path={`${process.env.PUBLIC_URL}/directorio/contactos`} component={Directorio} />
										<Route exact path={`${process.env.PUBLIC_URL}/directorio`} component={IntroDirectorio} />
										<Route path={`${process.env.PUBLIC_URL}/directorio/inicio`} component={DirectorioInicio} />
										<Route path={`${process.env.PUBLIC_URL}/directorio/agregar`} component={DirectorioAgregar} />
										<Route path={`${process.env.PUBLIC_URL}/directorio/editar/:id`} component={DirectorioEditar} />

										<Route exact path={`${process.env.PUBLIC_URL}/calendario`} component={IntroCalendario} />
										<Route exact path={`${process.env.PUBLIC_URL}/calendario/lista`} component={Calendario} />
										<Route exact path={`${process.env.PUBLIC_URL}/eventos`} component={Eventos} />
										<Route path={`${process.env.PUBLIC_URL}/eventos/agregar`} component={EventosAgregar} />
										<Route path={`${process.env.PUBLIC_URL}/eventos/editar/:id`} component={EventosEditar} />

										<Route exact path={`${process.env.PUBLIC_URL}/festividades`} component={Festividades} />
										<Route path={`${process.env.PUBLIC_URL}/festividades/agregar`} component={FestividadesAgregar} />
										<Route path={`${process.env.PUBLIC_URL}/festividades/editar/:id`} component={FestividadesEditar} />

										<Route exact path={`${process.env.PUBLIC_URL}/solicitudes`} component={Solicitudes} />
										<Route exact path={`${process.env.PUBLIC_URL}/solicitudes/agregar`} component={SolicitudAgregar} />

										<Route exact path={`${process.env.PUBLIC_URL}/divisiones`} component={Divisiones} />

										<Route exact path={`${process.env.PUBLIC_URL}/departamentos`} component={Departamentos} />

										<Route exact path={`${process.env.PUBLIC_URL}/puestos`} component={Puestos} />

										<Route exact path={`${process.env.PUBLIC_URL}/usuarios`} component={Usuarios} />
										<Route path={`${process.env.PUBLIC_URL}/usuarios/agregar`} component={UsuariosAgregar} />
										<Route path={`${process.env.PUBLIC_URL}/usuarios/editar/:id`} component={UsuariosEditar} />
										<Route path={`${process.env.PUBLIC_URL}/perfil`} component={EditarPerfil} />
										<Route path={`${process.env.PUBLIC_URL}/perfil_password`} component={PerfilContra} />

									</App>
							</Fragment>
						</Switch>
					</ScrollContext>
				</BrowserRouter>
			</Provider>
		</div>
	);
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();