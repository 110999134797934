import React from 'react';
import IconoLogo from '../assets/images/logo/logo_200.png'
import notie from 'notie'

const Login = () => {

	const changePassword=()=>{
        document.getElementById('alert_contra_vacia').style['display'] = 'none'
        document.getElementById('btn').style['display'] = 'none'
        if(document.getElementById('codigo').value === '' || document.getElementById('contra1').value === '' || document.getElementById('contra2').value === ''){
            document.getElementById('alert_contra_vacia').style['display'] = 'block'
        }
        else if(document.getElementById('contra1').value !== document.getElementById('contra2').value){
            document.getElementById('alert_contra').style['display'] = 'block'
        }else{
			document.getElementById('alert_contra').style['display'] = 'none'
			document.getElementById('alert_contra').style['display'] = 'none'
            fetch('/z_nueva_contrasena',{
                method: 'POST',
				headers:{'Content-Type': 'application/json'},
				body: JSON.stringify({
					password:  document.getElementById('contra1').value,
					codigo: (document.getElementById('codigo').value).toUpperCase()
				})
            }).then(res => res.json())
            .then(res => {
				console.log(res);
				if(res.RESULT===1){
					notie.alert({ type: 1, text: 'La contraseña se ha restablecido', time: 3 })
					setTimeout(function(){window.location.href = '/login'},3000)
				}
				else{
					document.getElementById('btn').style['display'] = 'block'
					notie.alert({ type: 2, text: 'El codigo es incorrecto', time: 3 })
				}
            })
            .catch(err => {
                notie.alert({ type: 3, text: 'Ha ocurrido un error, inténtelo más tarde', time: 3 })
            })
        }
	}

	return (
		<div>
			<div className="page-wrapper">
				<div className="container-fluid p-0">
					<div className="authentication-main bg-color-shape">
						<div className="row">
							<div className="col-md-12">
								<div className="auth-innerright">
									<div className="authentication-box ">
										<div className="text-center"><img className="img-responsive" src={IconoLogo} alt="Buzón Público" /></div>
										<div className="card mt-4">
											<div className="card-body">
												<div className="text-center">
													<h4 className="txt-warning">NUEVA CONTRASEÑA</h4>
													<h6>Por favor escriba el codigo y una nueva contraseña</h6>
												</div>
												<div className="form-group" autoComplete="off">
													<label className="col-form-label pt-0">Codigo</label>
													<input className="form-control" type="text" id="codigo" name="codigo" style = {{"text-transform":'uppercase'}} required=""/>
												</div>
												<div className="form-group">
													<label className="col-form-label pt-0">Nueva contraseña</label>
													<input className="form-control" type="password" id="contra1" name="contra1" autoComplete="new-password" required="" />
												</div>
												<div className="form-group">
													<label className="col-form-label">Confirmar nueva contraseña</label>
													<input className="form-control" type="password" id="contra2" name="contra2" autoComplete="new-password" required="" />
												</div>
												<div className="form-group form-row mt-3 mb-0">
													<button id="btn" onClick={changePassword} className="btn btn-warning btn-block" type="button">Continuar</button>
												</div>
                                                <div id="alert_contra" className="alert alert-danger mt-3 mb-0" style={{display: 'none'}}>Las contraseñas no coinciden, inténtalo de nuevo.</div>
                                                <div id="alert_contra_vacia" className="alert alert-danger mt-3 mb-0" style={{display: 'none'}}>Debes llenar los campos para continuar.</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
 
export default Login;