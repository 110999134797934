import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tablereact from '../common/tabulator/tablereact'

const Festividades = (props) => {

	const [festividades, setFestividades] = useState(false);

	useEffect(() => {
		fetch('/p_festividades').then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status === 'OK'){
				setFestividades(res.result)
			}else{
				console.log('Error');
			}
		})
		.catch(err => {console.log('Error: '+err);})
	}, []);

	const cabecera = [
        { Header: <b>Id</b>, accessor: 'ID', style: { textAlign: 'center'}, width:'80', show:false },
        { Header: <b>Titulo</b>, accessor: 'TITULO', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
        { Header: <b>Fecha de publicación</b>, accessor: 'FECHA', style: { textAlign: 'center'}, width:'200', editable:true , show:true}
	];

	const actualizar=(dato)=>props.history.push('/festividades/editar/'+dato.ID)
	const agregar=()=>props.history.push('/festividades/agregar')
	const eliminar=(dato)=>{
		fetch('/p_festividades',{ method: 'DELETE', body: JSON.stringify({ID: dato.ID}), headers: { 'Content-Type': 'application/json' }})
		.then( res => res.json() )
		.then( res => {
			if(res.status === 'OK'){
				setFestividades(festividades.filter(x=>x.ID!==dato.ID))
				toast.success("Se eliminó el registro", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else
				toast.warn("No se pudo borrar el registro", { position: toast.POSITION.BOTTOM_RIGHT })
		})
		.catch( (err) => {
			toast.error("Ha ocurrido un error", { position: toast.POSITION.BOTTOM_RIGHT })
		})
	}
	
	return (
	<Fragment>
		<Breadcrumb parent="Festividades"/>
		
		<div className="card">
			<div className="container-fluid">
				{
					!festividades ?
					<div className="d-flex justify-content-center align-items-center">
						<div className="loader-box mr-4">
							<div className="loader">
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
							</div>
						</div>
						<h5 className="text-uppercase">Cargando...</h5>
					</div>
					:
					<div className="row">
						<div className="col-sm-12 mt-3">
						<Tablereact
                        	myData={festividades}
                        	cabecera={cabecera}
                        	pageSize={10}
                        	paginacion={true}
                        	buscador='Buscar'
                        	eliminar_actualizar={{eliminar,actualizar}}
                        	boton={{text:'Agregar',function:agregar}}
                        />
						</div>
					</div>
				}
			</div>
		</div>
	</Fragment>
	);
}
 
export default Festividades;