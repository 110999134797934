import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import CKEditors from "react-ckeditor-component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import Button from 'react-bootstrap-button-loader';
import Switch from '@material-ui/core/Switch'

const Comunicado = (props) => {

	const [filtros, setFiltros] = useState(false);
	const [portada, setPortada] = useState([]);
	const [empresas, setEmpresas] = useState([]);
	const [divisiones, setDivisiones] = useState([]);
	const [departamentos, setDepartamentos] = useState([]);
	const [puestos, setPuestos] = useState([]);
	const [id, setId] = useState(0);
	const [look, setLook] = useState(false);
	const [mostrar, setMostrar] = useState(true);
	const [archivos_adjuntos, setArchivos_adjuntos] = useState([]);
	const [contenido_html, setContenido_html] = useState('');
	const [filtro_empresas, setFiltro_empresas] = useState([]);
	const [filtro_divisiones, setFiltro_divisiones] = useState([]);
	const [filtro_departamentos, setFiltro_departamentos] = useState([]);
	const [filtro_puestos, setFiltro_puestos] = useState([]);
	const [estatusComunicado, setEstatusComunicado] = useState(true);

	useEffect(() => {
		fetch('/p_comunicados')
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
			if(res.status === 1){
				setFiltros(true)
				setEmpresas(res.result[0])
				setDivisiones(res.result[1])
				setDepartamentos(res.result[2])
				setPuestos(res.result[3])
			}else{console.log('ERROR')}
		})
		.catch((err) => {console.log('ERROR: '+err)})
	}, []);

	const saveButton=()=>{
		setLook(true)
		let form_data = new FormData(document.getElementById('form_comunicados'))
		form_data.append('empresas', JSON.stringify(filtro_empresas))
		form_data.append('divisiones', JSON.stringify(filtro_divisiones))
		form_data.append('departamentos', JSON.stringify(filtro_departamentos))
		form_data.append('puestos', JSON.stringify(filtro_puestos))
		form_data.append('autor', localStorage.getItem('id_usr'))
		form_data.append('descripcion', contenido_html)
		form_data.append('portada', portada[0])
		form_data.append('estatus_comunicado', estatusComunicado)

		archivos_adjuntos.forEach((file, index) => {
			console.log(file);
			form_data.append('archivo_'+index, file)
			console.log('Archivo adjunto',index);
		})

		fetch('/p_comunicados', { method: 'POST', body: form_data })
		.then(res => res.json())
		.then(res => {
			if(res.status === 1 && res.rows[0] > 0){
				toast.success("Agregado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
				setMostrar(false)
				setId(res.result[0].ID)
			}
			else{
				console.log('No se pudo agregar');
				setLook(false)
			}
		})
		.catch(err => {console.log('Error: '+err);})
	}

	const backButton = ()=> {
		props.history.push('/comunicados/publicados')
	}

	const enviar=()=>{
		if(id){
			fetch('/z_enviar_comunicado', {
				method: 'POST',
				body: JSON.stringify({id}), 
				headers: {'Content-Type': 'application/json'}
			})
			.then(res => res.json())
			.then(res => {
				if(res.status === 1 && res.rows[0] > 0){
					toast.success("Enviado", { position: toast.POSITION.BOTTOM_RIGHT })
					setTimeout(() => props.history.push('/comunicados/publicados'), 1500)
				}
			})
			.catch(err => {console.log('Error: '+err);})
		}
		else{
			toast.warning("Primero debes completar y guardar comunicado para enviar", { position: toast.POSITION.BOTTOM_RIGHT })
		}
		
	}

	const editorData=(event)=>{
		setContenido_html(event.editor.getData())
	}

	const changeDropzone=({ meta, file }, status)=>{
		var archivos_adjuntos_temp=archivos_adjuntos
		if(status === 'done'){
			archivos_adjuntos_temp.push(file)
			setArchivos_adjuntos(archivos_adjuntos_temp)
		}
		if(status === 'removed'){
			console.log('removed')
			let index = archivos_adjuntos_temp.findIndex(item => item === file)
			archivos_adjuntos_temp.splice(index , 1)
			setArchivos_adjuntos(archivos_adjuntos_temp)
		}
	}

	const changeDropzonePortada=({ meta, file }, status)=>{
		console.log(file);
		if(status === 'done'){
			setPortada([file])
		}
		if(status === 'removed'){
			setPortada([])
		}
	}

	return (
		<Fragment>
			<Breadcrumb title="Crear comunicado" parent="Comunicados" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form id="form_comunicados" className="form theme-form">

							<div className="card">
								<div className="card-header">
									<h5>Información del comunicado</h5>
								</div>
								<div className="card-body add-post row">
										<div className="col-12">
											<div className="form-group">
												<label className="form-label">Portada</label>
												<Dropzone inputContent="Toque para insertar imagen" maxFiles={1} maxSizeBytes={10485760} inputContent="Arrastre o toque para insertar imagen" inputWithFilesContent="Agregar imagen"  onChangeStatus={changeDropzonePortada} accept="image/*" />
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<label htmlFor="validationCustom01">Título:</label>
												<input className="form-control" id="validationCustom01" name="titulo" type="text" placeholder="Título del comunicado" required="" />
											</div>
										</div>
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="validationCustom03">De:</label>
												<input className="form-control" id="validationCustom03" name="remitente" type="text"  required="" />
											</div>
										</div>
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="validationCustom04">Asunto:</label>
												<input className="form-control" id="validationCustom04" name="asunto" type="text" required="" />
											</div>
										</div>
										<div className="col-sm-12">
											{
												filtros === true ? 
												<Fragment>
												<div className="form-group">
													<label>Empresas: </label>
													<Typeahead
														id="filtro_empresas"
														clearButton
														labelKey="NOMBRE"
														multiple
														options={empresas}
														placeholder="Todos"
														onChange = {(selected) => {
															setFiltro_empresas(selected)
														}}
													/>
												</div>
												<div className="form-group">
													<label>Divisiones: </label>
													<Typeahead
														id="filtro_divisiones"
														clearButton
														labelKey="NOMBRE"
														multiple
														options={divisiones}
														placeholder="Todos"
														onChange = {(selected) => {
															setFiltro_divisiones(selected)
														}}
													/>
												</div>
												<div className="form-group">
													<label>Departamentos: </label>
													<Typeahead
														id="filtro_departamentos"
														clearButton
														labelKey="NOMBRE"
														multiple
														options={departamentos}
														placeholder="Todos"
														onChange = {(selected) => {
															setFiltro_departamentos(selected)
														}}
													/>
												</div>
												<div className="form-group">
													<label>Puestos: </label>
													<Typeahead
														id="filtro_puestos"
														clearButton
														labelKey="NOMBRE"
														multiple
														options={puestos}
														placeholder="Todos"
														onChange = {(selected) => {
															setFiltro_puestos(selected)
														}}
													/>
												</div>
												<div className="form-group">
													<label className="form-label">Estatus del Comunicado: </label>
													<Switch color="primary" onClick={()=>setEstatusComunicado(!estatusComunicado)} checked={estatusComunicado}/>
													<label className={`form-label`}>{estatusComunicado?'Visible':'Oculto'}</label>
												</div>
												</Fragment>
												:
												<label>Cargando...</label>
											}
											<div className="form-group">
												<label className="form-label">Archivos adjuntos</label>
												<Dropzone inputContent="Toque para insertar archivos" inputWithFilesContent="Agregar archivos" onChangeStatus={changeDropzone} submitButtonContent="" accept="*" />
											</div>
											<div className="form-group">
												<label className="form-label">Mensaje del comunicado:</label>
												<CKEditors
													config={{versionCheck: false}}
													content={contenido_html}
													activeclassName="p10"
													events={{
														"change": editorData
													}}
													heigth='600px'
												/>
											</div>
										</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-6 col-sm-6 col-lg-6">
											<button onClick={backButton} className="btn btn-light">Atrás</button>
										</div>
										<div className="col-6 col-sm-6 col-lg-6 text-right">
											<button onClick={enviar} className="btn btn-info mr-2" type="button">Enviar</button>
											{mostrar && <Button onClick={saveButton} className='btn btn-primary mr-1' type="button" loading={look}>Guardar</Button>}
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default Comunicado;