import React, { Fragment, useState } from 'react';
import '../../assets/css/notie.css'
import notie from 'notie'
import logo from './../../assets/images/logo/icono-logo.png'
import estadosJSON from './estados.json'
import ciudadesJSON from './municipios.json'

const Informacion=()=>{
	const datos={ nombre:'', apellido_paterno:'', apellido_materno:'', fecha_nacimiento:'', curp:'', rfc:'', calle:'', numero:'', colonia:'', codigo_postal:'', estado:'', ciudad:'', telefono_particular:'', telefono_oficina:'', extension_oficina:'', correo_particular:'', correo_empresarial:'', telefono_celular:'', departamento:'', puesto:'' }
	const [error, setError] = useState({errnombre:false,errapellido_paterno:false,errapellido_materno:false,errfecha_nacimiento:false,errcurp:false,errrfc:false,errcalle:false,errnumero:false,errcolonia:false,errcodigo_postal:false,errestado:false,errciudad:false,errtelefono_particular:false,errtelefono_oficina:false,errextension_oficina:false,errcorreo_particular:false,errcorreo_empresarial:false,errtelefono_celular:false,errdepartamento:false,errpuesto:false});
	const [info, setInfo] = useState(datos);
	const [ciudades, setCiudades] = useState([]);
	const [leyenda, setLeyenda] = useState(false);
	const [bloquearBtn, setBloquearBtn] = useState(false);
	const {errnombre,errapellido_paterno,errapellido_materno,errfecha_nacimiento,errcurp,errrfc,errcalle,errnumero,errcolonia,errcodigo_postal,errestado,errciudad,errtelefono_particular,errtelefono_oficina,errextension_oficina,errcorreo_particular,errcorreo_empresarial,errtelefono_celular,errdepartamento,errpuesto}=error
	const {nombre, apellido_paterno, apellido_materno, fecha_nacimiento, curp, rfc, calle, numero, colonia, codigo_postal, estado, ciudad, telefono_particular, telefono_oficina, extension_oficina, correo_particular, correo_empresarial, telefono_celular, departamento, puesto}=info
	
	const actualizarState=(e)=>{
		([e.target.name]==='estado' && [e.target.value]!=='') && setCiudades(ciudadesJSON[e.target.value])
		setInfo({ ...info, [e.target.name]:(e.target.value)})
	}
	
	const sinEspacio=e=>(e.keyCode === 32) && e.preventDefault()
	const sinEspacioTexto=e=>(e.keyCode === (8||46)||e.key==='Tab') ? true : (e.keyCode === (32) || isNaN(e.key)) && e.preventDefault()
	const regexCorreo = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	const regexCURP=/([A-Z]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[A-Z]{3}[0-9A-Z]\d)/
	const regexRFC= /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$/
	
	const submitFormulario=e=>{
		e.preventDefault()
		let temperror={
			errcurp: !regexCURP.test(curp),
			errrfc: !regexRFC.test(rfc),
			errcorreo_particular: correo_particular==='' ? false : !regexCorreo.test(correo_particular),
            errcorreo_empresarial: !regexCorreo.test(correo_empresarial),
		}
		setError(temperror)
		if(!Object.values(temperror).includes(true)){
            setBloquearBtn(true)
            fetch('/z_informacion', {
				method: 'POST',
				body: JSON.stringify(info),
				headers: {'Content-Type': 'application/json'}
			})
			.then(res => res.json())
			.then(res => {
				if(res.status === 'OK'){
					console.log(res);
					if(res.result[0].RESULT!==2){
						setLeyenda(true)
						setInfo(datos)
						notie.alert({ type: 'success', text: "Se ha registrado correctamente", stay:true, position:"bottom" })
					}
					else{
						setBloquearBtn(false)
						notie.alert({ type: 'warning', text: "El correo empresarial ya ha sido registrado, por favor elija otro correo.", time:5, position:"bottom" })
					}
				}
			})
			.catch(err => {
				console.log('Error: '+err)
				notie.alert({ type: 'danger', text: "Error, no se ha podido registrar", time: 3, position:"bottom" })
			})
        }
	}
	
	return( 
		<Fragment>
			<form id="form_usuarios" className="card container my-5" onSubmit={submitFormulario}>
				<div className="card-header text-center d-flex justify-content-center align-items-center">
					<img src={logo} alt="Logo" height="140"/>
					<h3 className="mt-4 mr-4"><strong>Datos del Usuario</strong></h3>
				</div>
				<div className="card-body">
					<div className="row">
						<Fragment>
						<div className="col-sm-12 col-md-12 mb-2"><h5><strong>Datos Personales</strong></h5></div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errnombre && 'text-danger'}`}>Nombre(s)*</label>
									<input placeholder="Introduce tu nombre(s)" name="nombre" className={`form-control ${errnombre && 'is-invalid'}`} type="text" value={nombre} onInput={()=>setError({...error,errnombre:false})} onInvalid={()=>setError({...error,errnombre:true})} onChange={actualizarState} minLength="2" required disabled={leyenda}/>
									<div className="invalid-feedback">Nombre(s) inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errapellido_paterno && 'text-danger'}`}>Apellido paterno*</label>
									<input placeholder="Introduce tu apellido paterno" name="apellido_paterno" className={`form-control ${errapellido_paterno && 'is-invalid'}`} type="text" value={apellido_paterno} onInput={()=>setError({...error,errapellido_paterno:false})} onInvalid={()=>setError({...error,errapellido_paterno:true})} minLength="2" onChange={actualizarState} required disabled={leyenda}/>
									<div className="invalid-feedback">Apellido paterno inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errapellido_materno && 'text-danger'}`}>Apellido materno*</label>
									<input placeholder="Introduce tu apellido materno" name="apellido_materno" className={`form-control ${errapellido_materno && 'is-invalid'}`} type="text" value={apellido_materno} onInput={()=>setError({...error,errapellido_materno:false})} onInvalid={()=>setError({...error,errapellido_materno:true})} minLength="2" onChange={actualizarState} required disabled={leyenda}/>
									<div className="invalid-feedback">Apellido materno inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errfecha_nacimiento && 'text-danger'}`}>Fecha de nacimiento*</label>
									<input name="fecha_nacimiento" className={`form-control ${errfecha_nacimiento && 'is-invalid'}`} type="date" value={fecha_nacimiento} onInput={()=>setError({...error,errfecha_nacimiento:false})} onInvalid={()=>setError({...error,errfecha_nacimiento:true})} onChange={actualizarState} required disabled={leyenda}/>
									<div className="invalid-feedback">Fecha de nacimiento inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errcurp && 'text-danger'}`}>CURP*</label>
									<input placeholder="Introduce tu CURP" onKeyDown={sinEspacio} pattern="([A-Z]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[A-Z]{3}[0-9A-Z]\d)" name="curp" className={`form-control ${errcurp && 'is-invalid'}`} type="text" onKeyUp={()=>setInfo({...info,curp:curp.toUpperCase()})} value={curp} onInput={()=>setError({...error,errcurp:false})} onInvalid={()=>setError({...error,errcurp:true})} onChange={actualizarState} minLength="18" maxLength="18" required disabled={leyenda}/>
									<div className="invalid-feedback">CURP inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errrfc && 'text-danger'}`}>RFC*</label>
									<input placeholder="Introduce tu RFC" onKeyDown={sinEspacio} name="rfc" pattern="^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$" className={`form-control ${errrfc && 'is-invalid'}`} type="text" onKeyUp={()=>setInfo({...info,rfc:rfc.toUpperCase()})} value={rfc} onInput={()=>setError({...error,errrfc:false})} onInvalid={()=>setError({...error,errrfc:true})} onChange={actualizarState} minLength="10" maxLength="13" required disabled={leyenda}/>
									<div className="invalid-feedback">RFC inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errcalle && 'text-danger'}`}>Calle*</label>
									<input placeholder="Introduce la calle" name="calle" className={`form-control ${errcalle && 'is-invalid'}`} type="text" value={calle} onInput={()=>setError({...error,errcalle:false})} onInvalid={()=>setError({...error,errcalle:true})} onChange={actualizarState} required disabled={leyenda}/>
									<div className="invalid-feedback">Calle inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-2">
								<div className="form-group">
									<label className={`form-label ${errnumero && 'text-danger'}`}>Número*</label>
									<input placeholder="Introduce el num." onKeyDown={sinEspacioTexto} name="numero" className={`form-control ${errnumero && 'is-invalid'}`} type="text" value={numero} onInput={()=>setError({...error,errnumero:false})} onInvalid={()=>setError({...error,errnumero:true})} onChange={actualizarState} maxLength="6" autoComplete="off" required disabled={leyenda}/>
									<div className="invalid-feedback">Número inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errcolonia && 'text-danger'}`}>Colonia*</label>
									<input placeholder="Introduce la colonia" name="colonia" className={`form-control ${errcolonia && 'is-invalid'}`} type="text" value={colonia} onInput={()=>setError({...error,errcolonia:false})} onInvalid={()=>setError({...error,errcolonia:true})} onChange={actualizarState} required disabled={leyenda}/>
									<div className="invalid-feedback">Colonia inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-2">
								<div className="form-group">
									<label className={`form-label ${errcodigo_postal && 'text-danger'}`}>Codigo postal*</label>
									<input placeholder="Introduce el CP." onKeyDown={sinEspacioTexto} name="codigo_postal" className={`form-control ${errcodigo_postal && 'is-invalid'}`} type="text" minLength='5' maxLength='5' value={codigo_postal} onInput={()=>setError({...error,errcodigo_postal:false})} onInvalid={()=>setError({...error,errcodigo_postal:true})} onChange={actualizarState} autoComplete="off" required disabled={leyenda}/>
									<div className="invalid-feedback">Codigo postal inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label className={`form-label ${errestado && 'text-danger'}`}>Estado*</label>
									<select className={`form-control ${errestado && 'is-invalid'}`} name="estado" value={estado} onClick={()=>setError({...error,errestado:false})} onInvalid={()=>setError({...error,errestado:true})} onChange={actualizarState} required disabled={leyenda}>
										<option value=''>--Seleccione su Estado--</option>
										{estadosJSON.map(x=><option key={x.nombre} value={x.nombre}>{x.nombre}</option>)}
									</select>
									<div className="invalid-feedback">Estado inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label className={`form-label ${errciudad && 'text-danger'}`}>Ciudad*</label>
									<select className={`form-control ${errciudad && 'is-invalid'}`} name="ciudad" value={ciudad} onClick={()=>setError({...error,errciudad:false})} onInvalid={()=>setError({...error,errciudad:true})} onChange={actualizarState} required disabled={leyenda}>
										<option value=''>--Seleccione su Ciudad--</option>
										{ciudades.map(x=><option key={x} value={x}>{x}</option>)}
									</select>
									<div className="invalid-feedback">Ciudad inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errtelefono_particular && 'text-danger'}`}>Teléfono particular*</label>
									<input placeholder="(xxx) xxx-xxxx" onKeyDown={sinEspacioTexto} name="telefono_particular" className={`form-control ${errtelefono_particular && 'is-invalid'}`} type="tel" value={telefono_particular} onInput={()=>setError({...error,errtelefono_particular:false})} onInvalid={()=>setError({...error,errtelefono_particular:true})} onChange={actualizarState} minLength="10" maxLength="10" autoComplete="off" required disabled={leyenda}/>
									<div className="invalid-feedback">Teléfono particular inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errtelefono_celular && 'text-danger'}`}>Teléfono celular*</label>
									<input placeholder="(xxx) xxx-xxxx" onKeyDown={sinEspacioTexto} name="telefono_celular" className={`form-control ${errtelefono_celular && 'is-invalid'}`} type="tel" value={telefono_celular} onInput={()=>setError({...error,errtelefono_celular:false})} onInvalid={()=>setError({...error,errtelefono_celular:true})} onChange={actualizarState} minLength="10" maxLength="10" autoComplete="off" required disabled={leyenda}/>
									<div className="invalid-feedback">Teléfono celular inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errcorreo_particular && 'text-danger'}`}>Correo particular</label>
									<input placeholder="Introduce el correo particular" onKeyDown={sinEspacio} name="correo_particular" className={`form-control ${errcorreo_particular && 'is-invalid'}`} type="email" value={correo_particular} onInput={()=>setError({...error,errcorreo_particular:false})} onInvalid={()=>setError({...error,errcorreo_particular:true})} onChange={actualizarState} autoComplete="off" disabled={leyenda}/>
									<div className="invalid-feedback">Correo particular inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-12">
								<hr/>
							</div>
							<div className="col-sm-12 col-md-12 my-2"><h5><strong>Datos del Trabajador</strong></h5></div>
							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label className={`form-label ${errdepartamento && 'text-danger'}`}>Departamento*</label>
									<input placeholder="Introduce el departemento" name="departamento" className={`form-control ${errdepartamento && 'is-invalid'}`} type="text" value={departamento} onInput={()=>setError({...error,errdepartamento:false})} onInvalid={()=>setError({...error,errdepartamento:true})} minLength="3" onChange={actualizarState} autoComplete="off" required disabled={leyenda}/>
									<div className="invalid-feedback">Departamento inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label className={`form-label ${errpuesto && 'text-danger'}`}>Puesto*</label>
									<input placeholder="Introduce tu puesto" name="puesto" className={`form-control ${errpuesto && 'is-invalid'}`} type="text" value={puesto} onInput={()=>setError({...error,errpuesto:false})} onInvalid={()=>setError({...error,errpuesto:true})} minLength="3" onChange={actualizarState} autoComplete="off" required disabled={leyenda}/>
									<div className="invalid-feedback">Puesto inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label className={`form-label ${errcorreo_empresarial && 'text-danger'}`}>Correo empresarial*</label>
									<input placeholder="Introduce el correo empresarial" onKeyDown={sinEspacio} name="correo_empresarial" className={`form-control ${errcorreo_empresarial && 'is-invalid'}`} type="email" value={correo_empresarial} onInput={()=>setError({...error,errcorreo_empresarial:false})} onInvalid={()=>setError({...error,errcorreo_empresarial:true})} onChange={actualizarState} autoComplete="off" required disabled={leyenda}/>
									<div className="invalid-feedback">Correo empresarial inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className={`form-label ${errtelefono_oficina && 'text-danger'}`}>Teléfono oficina*</label>
									<input placeholder="(xxx) xxx-xxxx" onKeyDown={sinEspacioTexto} name="telefono_oficina" className={`form-control ${errtelefono_oficina && 'is-invalid'}`} type="tel" value={telefono_oficina} onInput={()=>setError({...error,errtelefono_oficina:false})} onInvalid={()=>setError({...error,errtelefono_oficina:true})} onChange={actualizarState} minLength="10" maxLength="10" autoComplete="off" required disabled={leyenda}/>
									<div className="invalid-feedback">Teléfono oficina inválido.</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-2">
								<div className="form-group">
									<label className={`form-label ${errextension_oficina && 'text-danger'}`}>Extensión oficina*</label>
									<input placeholder="Introduce la ext." onKeyDown={sinEspacioTexto} name="extension_oficina" className={`form-control ${errextension_oficina && 'is-invalid'}`} type="text" minLength='2' maxLength='4' value={extension_oficina} onInput={()=>setError({...error,errextension_oficina:false})} onInvalid={()=>setError({...error,errextension_oficina:true})} onChange={actualizarState} autoComplete="off" required disabled={leyenda}/>
									<div className="invalid-feedback">Extensión oficina inválido.</div>
								</div>
							</div>
						</Fragment>
					</div>
				</div>
				{
					!bloquearBtn &&
					<div className="mb-3 d-flex justify-content-center">
						<div className="col-sm-4">
							<button className="btn btn-success btn-lg btn-block" type="submit" disabled={bloquearBtn}>Enviar</button>
						</div>
					</div>
				}
				
			</form>
		</Fragment>
	)
}

export default Informacion;