import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';

import SelectArray from '../common/select_array'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class UsuariosAgregar extends React.Component{
	// RESCATAR ESTADO Y SELECCIONAR EL SELECT DE ESTADOS
	constructor(props){
		super(props)
		this.id_usuario = this.props.match.params.id;
		this.state = {
			filtros: false,
			ready: false,
			usuario: {},
			estados: []
		}

		fetch('/z_usuarios', {
			method: 'POST',
			body: JSON.stringify({id: this.id_usuario}),
			headers: {
				'Content-Type': 'application/json'
			}

		})
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
				if(res.status === 'OK'){
						this.usuario = res.result[5][0]

						this.empresas = res.result[0]
						this.divisiones = res.result[1]
						this.departamentos = res.result[2]
						this.puestos = res.result[3]
						this.estados = res.result[4]
						
						this.setState((s) => ({ ready: true }))
				}else{
						console.log('ERROR')
				}
		})
		.catch((err) => {
			console.log('ERROR: '+err)
		})
	}

	sendButton(){

		fetch('/p_send', {
			method: 'POST',
			headers:{'Content-Type': 'application/json'},
			body: JSON.stringify({ ID:this.id_usuario})
		})
		.then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status === 'OK'){
				toast.success("Se ha envidado los datos de acceso", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else{
				toast.warn("No se pudo actualizar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
				console.log('No se pudo agregar');
			}
		})
		.catch(err => {toast.error("Ha ocurrido un error, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })})
	}

	saveButton(){
		let form_data = new FormData(document.getElementById('form_usuarios'))
		console.log(this.usuario);
		form_data.append('id', this.usuario.ID)
		form_data.append('fotografia', this.usuario.FOTOGRAFIA)

		fetch('/p_usuarios', {
			method: 'PUT',
			body: form_data
		})
		.then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status === 1 && res.rows[0] > 0){
				toast.success("Actualizado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
				console.log('Agregado');
				if(res.image)
					document.getElementById('img_usr').src = '/image?img='+res.image
			}
			else{
				toast.warn("No se pudo actualizar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
				console.log('No se pudo agregar');
			}
		})
		.catch(err => {toast.error("Ha ocurrido un error, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })})
	}

	backButton(){
		this.props.history.push('/usuarios')
	}

	render(){
		return( 
			<Fragment>
			<Breadcrumb parent="Usuarios" title="Editar" />
			<div className="container-fluid">
				<div className="edit-profile">
					{this.state.ready === false ? <div className="card">
						<div className="card-body d-flex justify-content-center align-items-center">
							<div className="loader-box mr-4">
								<div className="loader">
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
								</div>
							</div>
							<h5 className="text-uppercase">Cargando...</h5>
						</div>
					</div>:
					<div className="row">
						<div className="col-12">
							<form id="form_usuarios" className="card">
								<div className="card-header">
									<h4 className="card-title mb-0">Datos del usuario</h4>
									<div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-12 mb-4">
											<div className="avatar text-center">
												<img id="img_usr" className="rounded-circle img-thumbnail pro img-responsive" alt="" src={`/image?img=${this.usuario.FOTOGRAFIA!=null?(this.usuario.ID+this.usuario.FOTOGRAFIA):'avatar.png'}`} data-intro="This is Profile image" style={{width: 150}} />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Nombre(s)</label>
												<input id="form_nombre" name="nombre" className="form-control" type="text" defaultValue={this.usuario.NOMBRE}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Apellido paterno</label>
												<input id="form_apellido_paterno" name="apellido_paterno" className="form-control" type="text" defaultValue={this.usuario.APELLIDO_PATERNO}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Apellido materno</label>
												<input id="form_apellido_materno" name="apellido_materno" className="form-control" type="text"  defaultValue={this.usuario.APELLIDO_MATERNO}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Fecha de nacimiento</label>
												<input id="form_fecha_nacimiento" name="fecha_nacimiento" className="form-control" type="date"  defaultValue={this.usuario.NACIMIENTO}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">CURP</label>
												<input id="form_curp" name="curp" className="form-control" type="text"  defaultValue={this.usuario.CURP}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">RFC</label>
												<input id="form_rfc" name="rfc" className="form-control" type="text"  defaultValue={this.usuario.RFC}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Calle</label>
												<input id="form_calle" name="calle" className="form-control" type="text"  defaultValue={this.usuario.CALLE}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Número</label>
												<input id="form_numero" name="numero" className="form-control" type="text"  defaultValue={this.usuario.NUMERO}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Colonia</label>
												<input id="form_colonia" name="colonia" className="form-control" type="text"  defaultValue={this.usuario.COLONIA}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Codigo postal</label>
												<input id="form_codigo_postal" name="codigo_postal" className="form-control" type="text"  defaultValue={this.usuario.CODIGO_POSTAL}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												options={this.estados}
												element_id='form_estado'
												default_selected={this.usuario.ESTADO}
												element_name='estado'
												label_text='Estado'
											/>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Fotografía</label>
												<input id="form_fotografia" name="fotografia" className="form-control" type="file" accept=".png,.jpg"/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Teléfono particular</label>
												<input id="form_telefono_particular" name="telefono_particular" className="form-control" type="text"  defaultValue={this.usuario.TELEFONO_PARTICULAR}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Teléfono oficina</label>
												<input id="form_telefono_oficina" name="telefono_oficina" className="form-control" type="text"  defaultValue={this.usuario.TELEFONO_OFICINA}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Extensión oficina</label>
												<input id="form_extension_oficina" name="extension_oficina" className="form-control" type="text" defaultValue={this.usuario.EXTENSION_OFICINA}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Correo particular</label>
												<input id="form_correo_particular" name="correo_particular" className="form-control" type="email" defaultValue={this.usuario.CORREO_PARTICULAR}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Correo empresarial</label>
												<input id="form_correo_empresarial" name="correo_empresarial" className="form-control" type="email" defaultValue={this.usuario.CORREO_EMPRESARIAL}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Teléfono celular</label>
												<input id="form_telefono_celular" name="telefono_celular" className="form-control" type="text" defaultValue={this.usuario.TELEFONO_CELULAR}/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												default_selected={this.usuario.EMPRESA}
												options={this.empresas}
												element_id='form_empresas'
												element_name='empresa'
												label_text='Empresa'
											/>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												default_selected={this.usuario.DIVISION}
												options={this.divisiones}
												element_id='form_divisiones'
												element_name='division'
												label_text='División'
											/>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												default_selected={this.usuario.DEPARTAMENTO}
												options={this.departamentos}
												element_id='form_departamentos'
												element_name='departamento'
												label_text='Departamento'
											/>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												default_selected={this.usuario.PUESTO}
												options={this.puestos}
												element_id='form_puestos'
												element_name='puesto'
												label_text='Puesto'
											/>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm-12 d-flex justify-content-between">
										<div>
											<button onClick={this.sendButton.bind(this)} className="btn btn-primary mr-1" type="button"><i class="fa fa-envelope"></i> Enviar datos de acceso</button>
										</div>
										<div>
											<button onClick={this.saveButton.bind(this)} className="btn btn-primary mr-1" type="button">Guardar</button>
											<button onClick={this.backButton.bind(this)} className="btn btn-light">Atrás</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
					}
				</div>
			</div>
		</Fragment>
		)
	}
}