import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default class Tabulator extends React.Component{
	constructor(props){
		super(props)
		this.user_data = this.props.user_data
	}

	render(){
		const capitalizar=(x)=>{
			return x.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
		}

		const settings = {
			className: "center",
			autoplay: true,
			centerMode: true,
			speed: 500,
			autoplaySpeed: 5000,
			arrows: false,
			centerPadding: "5px",
			slidesToShow: 1,
			slidesToScroll: 1
		  };
		return(

			<Slider {...settings}>
				{
					this.user_data.map((user_data, index) => {
						return <div key={index} className="item">
							<div className="card-profile">
								<img className="rounded-circle mx-auto" src={`/image?img=${user_data.FOTOGRAFIA?(user_data.ID+user_data.FOTOGRAFIA):('avatar.png')}`} alt="" />
							</div>
							<div className="text-center profile-details">
								<h4>{capitalizar(user_data.NOMBRE+' '+user_data.APELLIDO_PATERNO+' '+user_data.APELLIDO_MATERNO)}</h4>
								<h6>{user_data.NOMBRE_PUESTO}</h6>
							</div>	
						</div>
					})
				}
            </Slider>
		);
	}

}