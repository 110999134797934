import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tablereact from '../common/tabulator/tablereact'

const TelefonoRojo = (props) => {

	const [comentarios, setComentarios] = useState(false);

	var types={
		'1':'Queja',
		'2':'Acoso laboral',
		'3':'Acoso sexual',
		'4':'Explotación laboral',
		'5':'Robo',
		'6':'Corrupción',
		'7':'Fraude',
		'8':'Abuso de autoridad',
		'9':'Incumplimiento de leyes',
		'10':'Negligencia'
	}

	useEffect(() => {
		fetch('/p_telefono_rojo')
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
			if(res.status === 'OK'){
				var comentarios_temp=res.result.map(x=>{return {...x,TIPO:types[x.TIPO]}})
				setComentarios(comentarios_temp)
			}else{console.log('ERROR')}
		})
		.catch((err) => {
				console.log('ERROR: '+err)
		})
		// eslint-disable-next-line
	}, []);

	const cabecera = [
        { Header: <b>Id</b>, accessor: 'ID', style: { textAlign: 'center'}, width:'80', show:false },
        { Header: <b>Tipo</b>, accessor: 'TIPO', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
        { Header: <b>Fecha de publicación</b>, accessor: 'FECHA', style: { textAlign: 'center'}, width:'200', editable:true , show:true}
	];

	const detalle_dato=(dato)=>props.history.push('/comentario/'+dato.ID)

	return (
		<Fragment>
			<Breadcrumb parent="Buzón Rojo"/>
			<div className="card">
				<div className="container-fluid">
					{
						!comentarios ?
						<div className="d-flex justify-content-center align-items-center">
							<div className="loader-box mr-4">
								<div className="loader">
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
								</div>
							</div>
							<h5 className="text-uppercase">Cargando...</h5>
						</div>
						:
						<div className="row">
							<div className="col-sm-12 mt-3">
								<Tablereact
                            		myData={comentarios}
                            		cabecera={cabecera}
                            		pageSize={10}
                            		paginacion={true}
                            		buscador='Buscar'
                            		detalle={detalle_dato}
                        		/>
							</div>
						</div>
					}
				</div>
			</div>
		</Fragment>
	);
}
 
export default TelefonoRojo;