import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default class CardProx extends React.Component{
	constructor(props){
		super(props)
		this.user_data = this.props.user_data
	}

	render(){
		const capitalizar=(x)=>{
			return x.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
		}

		const settings = {
			//dots:true,
			className: "center",
			autoplay: true,
			centerMode: true,
			speed: 500,
			autoplaySpeed: 3000,
			arrows: false,
			centerPadding: "1px",
			slidesToShow: 1,
			slidesToScroll: 1
		  };
		return(

			<Slider {...settings}>
				{
					this.user_data.map((user_data, index) => {
						return <div className="card no-shadow mb-0 marco_cumple" style={{background: 'transparent'}}>
								<div className="card-body mt-2">
									<div key={index} className="d-flex align-items-center justify-content-center">
										<div className="mr-2">
											<img className="img-60 rounded-circle img-fluid" src={`/image?img=${user_data.FOTOGRAFIA?(user_data.ID+user_data.FOTOGRAFIA):('avatar.png')}`} alt="" />
										</div>
										<div>
											<h6 className="mb-0">{capitalizar(user_data.NOMBRE+' '+user_data.APELLIDO_PATERNO+' '+user_data.APELLIDO_MATERNO)}</h6>
											<div className="text-muted">{capitalizar(user_data.FECHA)}</div>
											<h6>{user_data.NOMBRE_PUESTO}</h6>
										</div>
									</div>
								</div>
							</div>
					})
				}
            </Slider>
		);
	}

}