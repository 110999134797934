import React, { Fragment, useState, useEffect} from 'react';
import Breadcrumb from '../common/breadcrumb';

const Solicitud = ({view, setView}) => {

	const [solicitud, setsolicitud] = useState(false);

	useEffect(() => {
		fetch('/z_solicitudes', {
			method: 'POST',
			body: JSON.stringify({ID: view}),
			headers: {'Content-Type': 'application/json'}
		})
		.then((res) => res.json())
		.then((res) => {
			if(res.status === 'OK'){
				setsolicitud(res.result[0])
			}else{
				setsolicitud([])
				console.log('ERROR')
			}
		})
		.catch((err) => {
			console.log('ERROR: '+err)
		})
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Detalles" parent="Solicitud" />
			<div className="container-fluid">
				<div className="row timeline-custom">
					<div className="col-12">
						{solicitud === false ? <div className="card">
							<div className="card-body d-flex justify-content-center align-items-center">
									<div className="loader-box mr-4">
										<div className="loader">
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
										</div>
									</div>
									<h5 className="text-uppercase">Cargando...</h5>
								</div>
							</div> :(
						<div className="card">
							<div className="card-header">
								<h5>{solicitud.TIPO === 1 ? 'Solicitud de vacaciones':'Solicitud de permiso'}</h5>
							</div>
							<div className="card-body">
								<div >
									<p><strong>Solicitante: </strong>{solicitud.NOMBRE+' '+solicitud.APELLIDO_PATERNO+' '+solicitud.APELLIDO_MATERNO}</p>
									<p><strong>Fecha de solicitud: </strong>{solicitud.FECHA}</p>
									{
										solicitud.TIPO === 1 ?
										<Fragment>
											<p><strong>Periodo laboral: </strong>{solicitud.PERIODO}</p>
											<p><strong>Periodo de vacaciones: </strong>Del {solicitud.FECHA_INICIO} al {solicitud.FECHA_FIN}</p>
										</Fragment>
										:
										<Fragment>
											<p><strong>Fecha de permiso: </strong>{solicitud.FECHA_INICIO}</p>
											<p><strong>Horario laboral: </strong>{ solicitud.HORA_ENTRADA && solicitud.HORA_SALIDA ? 'De '+solicitud.HORA_ENTRADA+' a '+solicitud.HORA_SALIDA: 'Sin horario' }</p>
										</Fragment>
									}
									<p className="m-b-20"><strong>Comentarios: </strong> {solicitud.DESCRIPCION ? solicitud.DESCRIPCION : 'Sin comentarios'}</p>
								</div>
							</div>
							<div className="card-footer">
								<div className="row">
									<div className="col-6 col-sm-6 col-lg-6">
										<button onClick={()=>{setView(0)}} className="btn btn-light" type="button">Atrás</button>
									</div>
								</div>
							</div>
						</div>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default Solicitud;