import React, { Fragment } from 'react';
import {Link} from 'react-router-dom'
import { User, Mail, Settings, LogOut, Unlock } from 'react-feather';


const UserMenu = () => {
    return (
        <Fragment>
            <li className="onhover-dropdown">
                <Settings />
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><Link to="/perfil"><User />Perfil</Link></li>
                    <li><Link to="/perfil_password"><Unlock />Cambiar Contraseña</Link></li>
                    <li><Link to="/comunicados/recibidos"><Mail />Comunicados</Link></li>
                    <li><Link to="/cerrar_sesion"><LogOut />Salir</Link></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;