import React from "react";

export default function SVGFile() {
  return (
    <svg version="1.1" id="prointernet" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">
        <g>
            <g>
                <path className="st0" d="M30,0H4C1.8,0,0,1.8,0,4v26h5V5h25V0z"/>
            </g>
            <g>
                <polygon className="st0" points="7.5,7.5 7.5,9 7.5,28.1 7.5,30 11.5,30 13.1,30 13.1,7.5 		"/>
                <polygon className="st0" points="15,7.5 15,8.8 15,13.4 15,16.2 15,17.8 30,17.8 30,16.2 30,13.4 30,7.5 		"/>
                <polygon className="st0" points="21.6,19.7 15,19.7 15,21.3 15,27.8 15,30 21.6,30 21.6,25.7 21.6,21.3 		"/>
                <polygon className="st0" points="28.9,19.7 23.4,19.7 23.4,21.3 23.4,27.8 23.4,30 28.9,30 30,30 30,19.7 		"/>
            </g>
        </g>
    </svg>

);
}