import React, { useState } from 'react';
import pro_logo from '../assets/images/pro-logo.png'

const Auth = (props) => {

	const [chk_activo_auth, setChk_activo_auth] = useState(false);

	const setAlert = (type, text) => {
		let alert = document.getElementById('alerta_auth')
		alert.style['display'] = 'block'
		alert.innerText = text
		alert.className = 'alert mt-3 mb-0 alert-' + type
	}

	const validateUser = () => {
		document.getElementById('alerta_auth').style['display'] = 'none'
		if (document.getElementById('codigo') !== '') {
			let form_data = new FormData(document.getElementById('form_auth'));
			form_data.append('ID', localStorage.getItem('id_usr'))

			var object = {};
			form_data.forEach((value, key) => object[key] = value);
			console.log(form_data,object);

			fetch('/z_autenticacion', {
				method: 'POST',
				body: JSON.stringify({
					...object,
					chk_activo_auth
				}),
				headers:{'Content-Type': 'application/json'}
			}).then(res => res.json())
				.then(res => {
					console.log(chk_activo_auth,res.CAUT);
					if (res.RESULT===1) {
						if(chk_activo_auth && res.CAUT){
                            localStorage.setItem('c_auth', res.CAUT)
                        }
						// localStorage.setItem('cd', 1)
						window.location.replace('/comunicados/inicio')
					} else {
						setAlert('danger', 'El código ingresado no coincide')
					}
				})
				.catch(err => {
					setAlert('danger', 'Ha ocurrido un error, inténtelo más tarde')
				})
		} else {
			setAlert('warning', 'Ingrese su código de verificación')
		}
	}

	return (
		<div>
			<div className="page-wrapper">
				<div className="container-fluid p-0">
					{/* <!-- login page start--> */}
					<div className="authentication-main">
						<div className="row">
							<div className="col-md-12">
								<div className="auth-innerright">
									<div className="authentication-box">
										<div className="text-center"><img className="img-responsive" width="100" height="100" src={pro_logo} alt="" /></div>
										<div className="card mt-4">
											<div className="card-body">
												<div className="text-center">
													<h4>Autenticacion</h4>
													<h6>{localStorage.getItem('tipo_envio') == 1 ? 'Se ha enviado un código de verificación a su correo.' : 'Se ha enviado un código de verificación a su celular.'}</h6>
												</div>
												<form id="form_auth" className="theme-form">
													<div className="form-group">
														{/* <label className="col-form-label pt-0">Ingrese el código:</label> */}
														<input className="form-control" type="text" id="codigo" name="CODIGO" placeholder="Ingrese su código" required="" autoComplete="off" />
													</div>

													<div className="form-group my-0">
														<label className="col-form-label">Si confía en esté equipo puedes marcar la siguiente opción:</label>
													</div>
													<div className="form-group btn-showcase my-0">
														<div className="checkbox d-flex justify-content-center">
															<input id="checkbox1" type="checkbox" onChange={() => { setChk_activo_auth(!chk_activo_auth) }} value={chk_activo_auth} />
															<label className="text-muted lead" htmlFor="checkbox1">Desactivar la doble autenticación</label>
														</div>
													</div>

													<div className="col-12 mx-auto">
														<div className="form-group form-row mt-3 mb-0">
															<button onClick={validateUser} className="btn btn-pill btn-warning btn-lg mx-auto" type="button">Continuar</button>
														</div>
													</div>
												</form>
												<div id="alerta_auth" style={{ display: 'none' }}></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- login page end--> */}
				</div>
			</div>
		</div>
	);
}

export default Auth;