import React, { Fragment, useState } from 'react';
import Breadcrumb from "../common/breadcrumb";
import CKEditors from "react-ckeditor-component";
import 'react-dropzone-uploader/dist/styles.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectFetch from '../common/select_fetch';
import Elemts from '../common/elemts/elemts3';

const Comunicado = (props) => {

	const [contenido_html, setContenido_html] = useState('');
	const [elemts, setElemts] = useState([]);

	const set = (name, value) => name === 'elemts' && setElemts([...value])

	const saveButton=()=>{
		let form_data = new FormData(document.getElementById('form_publicaciones'))
		var datosVideo=[]

		for(var i=0;i<elemts.length;i++){
			if(elemts[i].title==="Video" && elemts[i].data.link ){
				var texto_link=elemts[i].data.link
				if(texto_link.trim()!==''){
					let title=elemts[i].data.title
					let link=elemts[i].data.link
					let code=elemts[i].data.code
					datosVideo.push({title,link,code})
				}
				
			}
		}

		form_data.append('AUTOR', localStorage.getItem('id_usr'))
		form_data.append('CONTENIDO', contenido_html)
		form_data.append('datosVideo', JSON.stringify(datosVideo))

		fetch('/p_publicaciones', {
			method: 'POST',
			body: form_data
		})
		.then(res => res.json())
		.then(res => {
			if(res.status === 'OK' && res.rows[0] > 0){
				document.getElementById('form_publicaciones').reset()
				toast.success("Agregado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
				setContenido_html('')
				props.history.push('/publicaciones')
			}
			else{
				console.log('No se pudo agregar');
			}
		})
		.catch(err => {console.log('Error: '+err);})
	}

	const backButton = ()=> {
		props.history.push('/publicaciones')
	}

	const editorData=(event)=>{
		setContenido_html(event.editor.getData())
	}

	return (
		<Fragment>
			<Breadcrumb title="Crear publicación" parent="Publicaciones" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form id="form_publicaciones" className="form theme-form">
							<div className="card">
								<div className="card-header">
									<h5>Información de la publicación</h5>
								</div>
								<div className="card-body add-post">
										<div className="col-sm-12">
											<div className="form-group">
												<label htmlFor="form_titulo">Título:</label>
												<input className="form-control" id="form_titulo" name="TITULO" type="text" placeholder="Título del publicación" required="" />
											</div>
											<div className="form-group">
												<label htmlFor="form_etiquetas">Etiquetas:</label>
												<input className="form-control" id="form_etiquetas" name="ETIQUETAS" type="text" placeholder="Etiquetas de la publicación" required="" />
											</div>
											<div className="form-group">
												<label htmlFor="form_fuente">Fuente:</label>
												<input className="form-control" id="form_fuente" name="FUENTE" type="text" placeholder="Fuente(s) de información" required="" />
											</div>
											<SelectFetch 
												element_id='form_categoria'
												element_name='CATEGORIA'
												label_text='Categoría'
												// default_selected={this.empresa.ESTADO}
												fetch_route='/p_categorias'
											/>

											<div className="form-group">
												<label className="form-label">Imagen de la publicación:</label>
												<input id="form_imagen" name="IMAGEN" className="form-control" type="file" accept=".png,.jpg"/>
											</div>
											<div className="form-group">
												<label className="form-label">Contenido:</label>
												<CKEditors
													config={{versionCheck: false}}
													content={contenido_html}
													activeclassName="p10"
													events={{
														"change": editorData
													}}
													heigth='600px'
												/>
											</div>

											<Elemts noticia={elemts} set={set} editable={true}/>
										</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-12">
											<div className="btn-showcase">
												<button onClick={saveButton} className="btn btn-primary mr-1" type="button">Guardar</button>
												<button onClick={backButton} className="btn btn-light txt-dark" type="button">Atrás</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default Comunicado;