import React, { Fragment } from 'react';

export default class SelectFetch extends React.Component{
	constructor(props){
		super(props)
		this.id = this.props.element_id
		this.fetch_route = this.props.fetch_route
		this.name = this.props.element_name
		this.default = this.props.default_selected
		this.label = this.props.label_text
		this.elements = []
		this.state = {
			ready: false
		}
		console.log(this.default);
		fetch(this.fetch_route)
		.then(res => res.json())
		.then(res => {
			console.log(res);
			this.elements = res
			this.setState((s) => ({ready: true}))
			/* if(res.status == 'OK'){
				this.elements = res.result
				this.setState((s) => ({ready: true}))
			}else{
				console.log('Ha ocurrido un error en el servidor');
			} */
		})
		.catch(err => {console.log('Ha ocurrido un error: '+err);})
	}

	render(){
		return (
		<Fragment>
			{
				this.state.ready === false ? '':
				<div className="form-group">
					<label className="form-label">{this.label}</label>
					<select id={this.id} name={this.name} defaultValue={this.default} className="form-control btn-square">
						<option value="0">Seleccione</option>
						{
							this.elements.map((elem) => <option value={elem.ID} key={elem.ID}>{elem.NOMBRE}</option>)
						}
					</select>
				</div>
			}
		</Fragment>
		)
	}
}