import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Img1 from '../../assets/images/ilustraciones/1.png';
import Img2 from '../../assets/images/ilustraciones/2.png';
import Img3 from '../../assets/images/ilustraciones/3.png';
import Fade from 'react-reveal/Fade';
import { Tag, ShoppingBag, MessageCircle, MinusCircle } from 'react-feather';

const Nosotros = () => {
    return (
        <Fragment>
				<Breadcrumb parent="Nosotros" />
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card">
                                <div className="card-header">
                                    <h3 className="f-w-600 txt-warning mb-0 text-center">Conócenos</h3>
                                </div>
                                <div className="card-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6 mx-auto">
                                                <Fade left>
                                                    <img src={Img3} className="img-fluid" alt=""/>
                                                </Fade>
                                                <Fade top>
                                                    <img src={Img2} className="img-fluid" alt=""/>
                                                </Fade>
                                                <Fade right>
                                                    <img src={Img3} className="img-fluid" alt=""/>
                                                </Fade>
                                                <Fade bottom>
                                                    <img src={Img1} className="img-fluid" alt=""/>
                                                </Fade>
                                            </div>
                                            <div className="col-md-6">
                                                <p>García, Terán & Torres es una empresa de servicios profesionales vinculada con el desarrollo de la contaduría pública en México y comprometida con el desempeño propio de su función.</p>

                                                <p>El ambiente de negocios del siglo XXI, presenta por igual, riesgos, oportunidades y necesidades. Hoy, más que nunca, es fundamental alcanzar estándares globales, estimular la productividad y adaptarse a la demanda de servicios profesionales de excelencia.</p>

                                                <p>En el transcurso de los años, algunos de los socios han ocupado posiciones distinguidas dentro de prestigiadas instituciones académicas y desempeñando paralelamente la docencia.</p>

                                                <p>Nuestra firma ha integrado una organización vanguardista y flexible, que mantiene en constante redimensión su estructura, capacidades y procesos, de acuerdo a las necesidades de sus clientes. Asimismo, aporta soluciones prácticas, anticipa necesidades, ensambla alianzas que permiten ofrecer servicios complementarios de enlace y control; capacita y actualiza a sus asociados, todo ello con el fin de ofrecer una asistencia comprensiva en las siguientes áreas de nuestra especialización:</p>

                                                <ul className="pb-3">
                                                    <li><i className="fa fa-angle-double-right txt-warning m-r-10"></i>Auditoría</li>
                                                    <li><i className="fa fa-angle-double-right txt-warning m-r-10"></i>Contabilidad</li>
                                                    <li><i className="fa fa-angle-double-right txt-warning m-r-10"></i>Asesoría Fiscal</li>
                                                    <li><i className="fa fa-angle-double-right txt-warning m-r-10"></i>Asesoría Financiera</li>
                                                    <li><i className="fa fa-angle-double-right txt-warning m-r-10"></i>Asesoría Patrimonial</li>
                                                    <li><i className="fa fa-angle-double-right txt-warning m-r-10"></i>Asesoría Internacional</li>
                                                    <li><i className="fa fa-angle-double-right txt-warning m-r-10"></i>Asesoría Legal en Materia Fiscal</li>
                                                </ul>

                                                <p>Prestamos servicios a entidades que desempeñan actividades tanto en el ámbito comercial, industrial y de servicios como son: empresas mexicanas, subsidiarias de empresas extranjeras y empresas de gobierno.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-12">
							<div className="card">
                                <div className="card-header">
                                    <h3 className="f-w-600 txt-warning mb-0 text-center">Mensaje de Dirección</h3>
                                </div>
                                <div className="card-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6 mx-auto">
                                                <Fade left>
                                                    <img src={Img3} className="img-fluid" alt=""/>
                                                </Fade>
                                                <Fade top>
                                                    <img src={Img2} className="img-fluid" alt=""/>
                                                </Fade>
                                                <Fade right>
                                                    <img src={Img3} className="img-fluid" alt=""/>
                                                </Fade>
                                                <Fade bottom>
                                                    <img src={Img1} className="img-fluid" alt=""/>
                                                </Fade>
                                            </div>
                                            <div className="col-md-6">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
							<div className="card">
                                <div className="card-header">
                                    <h3 className="f-w-600 txt-warning mb-0 text-center">Mensaje del Departamento Contable</h3>
                                </div>
                                <div className="card-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                
                                    <Fade left>
                                        <img src={Img3} className="img-fluid" alt=""/>
                                    </Fade>
                                    <Fade top>
                                        <img src={Img2} className="img-fluid" alt=""/>
                                    </Fade>
                                    <Fade right>
                                        <img src={Img3} className="img-fluid" alt=""/>
                                    </Fade>
                                    <Fade bottom>
                                        <img src={Img1} className="img-fluid" alt=""/>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                                        
                        <div className="col-sm-6">
							<div className="card">
                                <div className="card-header">
                                    <h3 className="f-w-600 txt-warning mb-0 text-center">Mensaje del Departamento Legal</h3>
                                </div>
                                <div className="card-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                
                                    <Fade left>
                                        <img src={Img3} className="img-fluid" alt=""/>
                                    </Fade>
                                    <Fade top>
                                        <img src={Img2} className="img-fluid" alt=""/>
                                    </Fade>
                                    <Fade right>
                                        <img src={Img3} className="img-fluid" alt=""/>
                                    </Fade>
                                    <Fade bottom>
                                        <img src={Img1} className="img-fluid" alt=""/>
                                    </Fade>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
							<div className="card">
                                <div className="card-header">
                                    <h3 className="f-w-600 txt-warning mb-0">Metas Alcanzadas</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-10 mx-auto activity-scroll">
                                            <div className="activity">
                                                <div className="media">
                                                    <div className="gradient-round m-r-30 gradient-line-1">
                                                        <ShoppingBag />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6>New Sale</h6>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
                                                    </div>
                                                </div>
                                                <div className="media">
                                                    <div className="gradient-round m-r-30 gradient-line-1">
                                                        <MessageCircle />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6>New Message</h6>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
                                                    </div>
                                                </div>
                                                <div className="media">
                                                    <div className="gradient-round m-r-30 small-line">
                                                        <MinusCircle />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6>New Report</h6>
                                                        <p className="activity-xl">Lorem Ipsum is simply dummy text.</p>
                                                    </div>
                                                </div>
                                                <div className="media">
                                                    <div className="gradient-round m-r-30 gradient-line-1">
                                                        <ShoppingBag />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6>New Sale</h6>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
                                                    </div>
                                                </div>
                                                <div className="media">
                                                    <div className="gradient-round m-r-30 medium-line">
                                                        <Tag />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6>New Visits</h6>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </Fragment>
    );
}
 
export default Nosotros;