import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectArray from '../common/select_array'

const SolicitudAgregar = (props) => {

	const [ready, setReady] = useState(false);
	const [fecha_actual, setFecha_actual] = useState('');
	const [anios, setAnios] = useState(0);
	const [vacaciones, setVacaciones] = useState(0);
	const [periodo, setPeriodo] = useState('');
	const [fecha_fin, setFecha_fin] = useState('');

	useEffect(() => {
		fetch('/z_solicitudes', {
			method: 'PUT',
			body: JSON.stringify({ID: localStorage.getItem('id_usr')}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then(res => res.json())
		.then(res => {
			console.log(res);
			var fecha_actual_tmp,anios_tmp,vacaciones_tmp,periodo_tmp,fecha_fin_tmp
			// fecha_alta_tmp = new Date(res.result[0].FECHA_ALTA)
			fecha_actual_tmp = new Date(res.result[0].FECHA_ACTUAL)
			anios_tmp = parseInt(res.result[0].ANTIGUEDAD/365)
			vacaciones_tmp = 0
			if(anios_tmp > 0 && anios_tmp < 5){
			vacaciones_tmp = 4 + anios_tmp*2
			}else if(anios_tmp > 4){
			vacaciones_tmp = 12 +parseInt(anios_tmp/5)*2
			}
			periodo_tmp = fecha_actual_tmp.getFullYear()-1 +' - ' +fecha_actual_tmp.getFullYear()
			fecha_fin_tmp = new Date(fecha_actual_tmp)
			fecha_fin_tmp.setDate(fecha_fin_tmp.getDate()+vacaciones_tmp)
			console.log(fecha_actual_tmp, fecha_fin_tmp);

			setFecha_actual(fecha_actual_tmp)
			setAnios(anios_tmp)
			setVacaciones(vacaciones_tmp)
			setPeriodo(periodo_tmp)
			setFecha_fin(fecha_fin_tmp)
			setReady(true)
		})
		.catch(err => {
			console.log(err);
		})
	}, []);

	const calcularVacaciones=(e)=>{
		let inicio = document.getElementById('form_fecha_inicio').value
		console.log(inicio);
		if(inicio != ''){
			let fecha_fin = new Date(inicio)
			fecha_fin.setDate(fecha_fin.getDate()+vacaciones)
			document.getElementById('form_fecha_fin').value = fecha_fin.toISOString().slice(0,10)
		}
	}

	const onTypeChange=()=>{
		var selected = document.getElementById('form_tipo').value;
		if(selected == 1){
			document.getElementById('campos_vacaciones').style['display'] = 'block'
			document.getElementById('campos_permiso').style['display'] = 'none'
		}else if(selected == 2){
			document.getElementById('campos_vacaciones').style['display'] = 'none'
			document.getElementById('campos_permiso').style['display'] = 'block'
		}else{
			document.getElementById('campos_vacaciones').style['display'] = 'none'
			document.getElementById('campos_permiso').style['display'] = 'none'
		}
	}

	const saveButton=()=>{
		let form_data = new FormData(document.getElementById('form_solicitudes'))
		form_data.append('USUARIO', localStorage.getItem('id_usr'))
		fetch('/p_solicitudes', {
			method: 'POST',
			body: form_data
		})
		.then(res => res.json())
		.then(res => {
			if(res.status == 'OK' && res.rows[0] > 0){
				console.log('Agregado');
				document.getElementById('form_solicitudes').reset()
				toast.success("Agregado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else{
				toast.warn("No se pudo agregar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
			}
		})
		.catch(err => {
			console.log('Error: '+err);
			toast.warn("Ha ocurrido un error, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
		})
	}

	const backButton=()=>props.history.push('..')

	return (
		<Fragment>
			<Breadcrumb title="Agregar" parent="Directorio" />
			<div className="container-fluid">
				<div className="row">
				<div className="col-sm-12">
					<div className="card">
						<div className="card-header">
							<h5>Detalles de solicitud</h5>
						</div>
						
						<form id="form_solicitudes" className="form theme-form">
						<div className="card-body">
							<div className="row">
								<div className="col-12">
									<SelectArray element_id="form_tipo" element_name="TIPO" options={[{ID: 1, NOMBRE: 'Vacaciones'},{ID: 2, NOMBRE: 'Permiso'}]} label_text="Tipo de solicitud:" function_change={onTypeChange} default_selected={1} />
								</div>
								<div id="campos_vacaciones" className="col-12">
								{
								ready ?
								anios > 0 ?
								<div className="row">
										<div className="col-12">
											<p><strong>Días de vacaciones: </strong>{vacaciones}</p>
										</div>
										<div className="form-group col-12 col-lg-4">
											<label className="form-label">Fecha de inicio: </label>
											<input id="form_fecha_inicio" name="FECHA_INICIO" type="text" className="form-control" type="date" defaultValue={fecha_actual.toISOString().slice(0,10)} onChange={calcularVacaciones} required />
										</div>
										<div className="form-group col-6 col-lg-4">
											<label className="form-label">Fecha de terminación: </label>
											<input id="form_fecha_fin" name="FECHA_FIN" type="text" className="form-control" type="date" defaultValue={fecha_fin.toISOString().slice(0,10)} required />
										</div>
										<div className="form-group col-12 col-lg-4">
											<label className="form-label">Periodo: </label>
											<input id="form_periodo" name="PERIODO" type="text" className="form-control" type="text" defaultValue={periodo} required />
										</div>
										<div className="form-group col-12">
											<label>Comentarios: </label>
											<textarea className="form-control" id="form_descripcion" name="DESCRIPCION" rows="5" cols="5" placeholder="Escriba aquí ..."></textarea>
										</div>
								</div>
								:
									<h3 className="f-w-600 mt-4">No aplican vacaciones</h3>
								:
									<div className="card-body d-flex justify-content-center align-items-center">
										<div className="loader-box mr-4">
											<div className="loader">
												<div className="line bg-warning"></div>
												<div className="line bg-warning"></div>
												<div className="line bg-warning"></div>
												<div className="line bg-warning"></div>
											</div>
										</div>
										<h5 className="text-uppercase">Cargando...</h5>
									</div>
								}
								</div>
								<div id="campos_permiso" className="col-12" style={{display: 'none'}}> 
										<div className="form-group">
											<label className="form-label">Fecha de permiso: </label>
											<input id="form_fecha" name="FECHA_PERMISO" type="text" className="form-control" type="date" required />
										</div>
										<div className="form-group">
											<label className="form-label">Hora de entrada: </label>
											<input id="form_hora_entrada" name="HORA_ENTRADA" type="text" className="form-control" type="text" required />
										</div>
										<div className="form-group">
											<label className="form-label">Hora de salida: </label>
											<input id="form_hora_salida" name="HORA_SALIDA" type="text" className="form-control" type="text" required />
										</div>
										<div className="form-group">
											<label>Comentarios: </label>
											<textarea className="form-control" id="form_descripcion" name="DESCRIPCION_PERMISO" rows="5" cols="5" placeholder="Escriba aquí ..."></textarea>
										</div>
								</div>
							</div>
						</div>
						<div className="card-footer">
						<div className="col-sm-12">
							<button onClick={saveButton} className="btn btn-primary mr-1" type="button">Solicitar</button>
						</div>
						</div>
					</form>
					</div>
				</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default SolicitudAgregar;