import React, {Fragment,useState,useEffect} from 'react';
import Breadcrumb from '../common/breadcrumb';

const Comentario = (props) => {

	const [ready, setReady] = useState(false);
	const [id_comentario] = useState(props.match.params.id);
	const [comentario, setComentario] = useState({});

	var types = {
		'1':'Queja',
		'2':'Acoso laboral',
		'3':'Acoso sexual',
		'4':'Explotación laboral',
		'5':'Robo',
		'6':'Corrupción',
		'7':'Fraude',
		'8':'Abuso de autoridad',
		'9':'Incumplimiento de leyes',
		'10':'Negligencia'
	}

	useEffect(() => {
		fetch('/z_telefono_rojo', {
			method: 'POST',
			body: JSON.stringify({
				ID: id_comentario
			}),
			headers: { 'Content-Type': 'application/json' }
		})
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
			if(res.status === 'OK'){
				setComentario(res.result[0])
				setReady(true)
			}else{console.log('ERROR')}
		})
		.catch((err) => {console.log('ERROR: '+err)})
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Ver comentario" parent="Buzón rojo" />
			<div className="container-fluid">
				<div className="row timeline-custom">
					<div className="col-12">
						{ready === false ? <h1>Cargando...</h1> :(
						<div className="card">
							<div className="card-header">
								<h5>Buzón rojo</h5>
							</div>
							<div className="card-body">
								<div className="email-wrapper">
									<p><strong>Tipo de reporte: </strong>{types[comentario.TIPO]}</p>
									<p><strong>Fecha: </strong>{comentario.FECHA}</p>
									<p><strong>Hora: </strong>{comentario.HORA}</p>
									<p className="m-b-20"><strong>Descripción del incidente: </strong><br />{comentario.DESCRIPCION}</p>
								</div>
							</div>
						</div>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default Comentario;
