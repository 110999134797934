import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tablereact from '../common/tabulator/tablereact'
import PuestosAgregar from './PuestosAgregar'

const Puestos = () => {

	const [puestos, setPuestos] = useState(false);
	const [view, setView] = useState(-1);

	useEffect(() => {
		fetch('/p_puestos').then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status === 'OK'){
				setPuestos(res.result)
			}else{
				console.log('Error');
			}
		})
		.catch(err => {console.log('Error: '+err);})
	}, [view]);

	const cabecera = [
        { Header: <b>Id</b>, accessor: 'ID', style: { textAlign: 'center'}, width:'80', show:false },
        { Header: <b>Titulo</b>, accessor: 'NOMBRE', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
        { Header: <b>Fecha de publicación</b>, accessor: 'FECHA_ALTA', style: { textAlign: 'center'}, width:'200', editable:true , show:true}
	];

	const actualizar=(dato)=>setView(dato.ID)
	const agregar=()=>setView(0)
	const eliminar=(dato)=>{
		fetch('/p_puestos',{ method: 'DELETE', body: JSON.stringify({id: dato.ID}), headers: { 'Content-Type': 'application/json' }})
		.then( res => res.json() )
		.then( res => {
			if(res.status === 'OK'){
				setPuestos(puestos.filter(x=>x.ID!==dato.ID))
				toast.success("Se eliminó el registro", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else
				toast.warn("No se pudo borrar el registro", { position: toast.POSITION.BOTTOM_RIGHT })
		})
		.catch( (err) => {
			toast.error("Ha ocurrido un error", { position: toast.POSITION.BOTTOM_RIGHT }) 
		})
	}

	return (
		view === -1 ?
		<Fragment>
			<Breadcrumb parent="Puestos"/>
			
			<div className="card">
				<div className="container-fluid">
					{
						!puestos ?
						<div className="d-flex justify-content-center align-items-center">
							<div className="loader-box mr-4">
								<div className="loader">
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
								</div>
							</div>
							<h5 className="text-uppercase">Cargando...</h5>
						</div>
						:
						<div className="row">
							<div className="col-sm-12 mt-3">
								<Tablereact
    	                    		myData={puestos}
    	                    		cabecera={cabecera}
    	                    		pageSize={10}
    	                    		paginacion={true}
    	                    		buscador='Buscar'
    	                    		eliminar_actualizar={{eliminar,actualizar}}
    	                    		boton={{text:'Agregar',function:agregar}}
    	                    	/>
							</div>
						</div>
					}
				</div>
			</div>
		</Fragment>
		:
		<PuestosAgregar view={view} setView={setView} />
	);
}
 
export default Puestos;