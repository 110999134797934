import React, { Fragment } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-dropzone-uploader/dist/styles.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Comunicado extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			filtros: false,
			empresas: [],
			divisiones: [],
			departamentos: [],
			puestos: []
		}
		this.filtro_empresas = []
		this.filtro_divisiones = []
		this.filtro_departamentos = []
		this.filtro_puestos = []
		fetch('/p_comunicados')
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
				if(res.status === 1){
						this.setState((s) => ({ filtros: true, empresas: res.result[0], divisiones: res.result[1], departamentos: res.result[2], puestos: res.result[3] }))
				}else{
						console.log('ERROR')
				}
		})
		.catch((err) => {
				console.log('ERROR: '+err)
		})
	}

	saveButton(){
		let form_data = new FormData(document.getElementById('form_eventos'))
		form_data.append('EMPRESAS', JSON.stringify(this.filtro_empresas))
		form_data.append('DIVISIONES', JSON.stringify(this.filtro_divisiones))
		form_data.append('DEPARTAMENTOS', JSON.stringify(this.filtro_departamentos))
		form_data.append('PUESTOS', JSON.stringify(this.filtro_puestos))
		form_data.append('AUTOR', localStorage.getItem('id_usr'))
		

		fetch('/p_eventos', {
			method: 'POST',
			body: form_data
		})
		.then(res => res.json())
		.then(res => {
			if(res.status === 'OK' && res.rows[0] > 0){
				document.getElementById('form_eventos').reset()
				toast.success("Agregado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
				console.log('Agregado');
			}
			else{
				console.log('No se pudo agregar');
			}
		})
		.catch(err => {console.log('Error: '+err);})
	}

	backButton = ()=> {
		this.props.history.push('/eventos')
	}

	mostrarFiltros(){
		console.log(this.filtro_empresas);
	}

	showFiles(){
		console.log(document.getElementById('form_archivos').files)
	}

	editorData(event){
		this.contenido_html = event.editor.getData()
	}

	render(){
		return (
		<Fragment>
			<Breadcrumb title="Agregar" parent="Eventos" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form id="form_eventos" className="form theme-form">
							<div className="card">
								<div className="card-header">
									<h5>Información del evento</h5>
								</div>
								<div className="card-body add-post">
										<div className="col-sm-12">
											<div className="form-group">
												<label className="form-label">Título:</label>
												<input className="form-control" name="TITULO" type="text" required="" />
											</div>
											<div className="form-group">
												<label className="form-label">Fecha:</label>
												<input className="form-control" name="FECHA" type="date" required="" />
											</div>
											<div className="form-group">
												<label className="form-label">Horario:</label>
												<input className="form-control" name="HORARIO" type="text" required="" />
											</div>
                                            <h6>Filtrado:</h6>
											{
												this.state.filtros === true ? 
												<Fragment>
												<div className="form-group">
													<label>Empresas: </label>
													<Typeahead
														id="filtro_empresas"
														clearButton
														// defaultSelected={this.state.empresas.slice(0, 1)}
														labelKey="NOMBRE"
														multiple
														options={this.state.empresas}
														placeholder="Todos"
														onChange = {(selected) => {
															this.filtro_empresas = selected
														}}
													/>
												</div>
												<div className="form-group">
													<label>Divisiones: </label>
													<Typeahead
														id="filtro_divisiones"
														clearButton
														// defaultSelected={this.state.divisiones.slice(0, 1)}
														labelKey="NOMBRE"
														multiple
														options={this.state.divisiones}
														placeholder="Todos"
														onChange = {(selected) => {
															this.filtro_divisiones = selected
														}}
													/>
												</div>
												<div className="form-group">
													<label>Departamentos: </label>
													<Typeahead
														id="filtro_departamentos"
														clearButton
														// defaultSelected={this.state.departamentos.slice(0, 1)}
														labelKey="NOMBRE"
														multiple
														options={this.state.departamentos}
														placeholder="Todos"
														onChange = {(selected) => {
															this.filtro_departamentos = selected
														}}
													/>
												</div>
												<div className="form-group">
													<label>Puestos: </label>
													<Typeahead
														id="filtro_puestos"
														clearButton
														// defaultSelected={this.state.puestos.slice(0, 1)}
														labelKey="NOMBRE"
														multiple
														options={this.state.puestos}
														placeholder="Todos"
														onChange = {(selected) => {
															this.filtro_puestos = selected
														}}
													/>
												</div>
												</Fragment>
												:
												<label>Cargando...</label>
											}
											<div className="form-group">
												<label className="form-label">Detalles del evento: </label>
												<textarea name="DESCRIPCION" className="form-control" rows="5" />
											</div>
										</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-12">
											<div className="btn-showcase">
												<button onClick={this.saveButton.bind(this)} className="btn btn-primary mr-1" type="button">Guardar</button>
												<button onClick={this.backButton} className="btn btn-light txt-dark" type="button">Atrás</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	)};
}