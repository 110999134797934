import React, { Fragment } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Icono from '../../assets/images/ilustraciones/directorio/directorio.png';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import hombreLaptop from '../../assets/images/ilustraciones/directorio/directorio-hombre-laptop.png'
import hombreClipboard from '../../assets/images/ilustraciones/directorio/directorio-hombre-clipboard.png'
import nube from '../../assets/images/ilustraciones/directorio/directorio-nube.png'
import directorio from '../../assets/images/ilustraciones/directorio/directorio-directorio.png'
import base from '../../assets/images/ilustraciones/directorio/directorio-base.png'
import bgShape from '../../assets/images/ilustraciones/noticias/noticias-bg.png'
import nubes from '../../assets/images/ilustraciones/directorio/directorio-nubes.png'

export default class Comunicado extends React.Component{

	redirectPublicaciones(){
		this.props.history.push('/directorio/contactos')
	}

	render(){
		return (
		<Fragment>
			<div className="authentication-main p-0 container-fluid">
				<div className="row">
					<div className="col-sm-12 col-md-6 col-lg-8 bg-ilustracion">
						<div className="animation-wrapper">
							<div className="center z3 text-center">
								<Bounce duration={1400} left>
									<img className="img-fluid" src={hombreLaptop} alt="" />
								</Bounce>
							</div>
							<div className="center z3 text-center">
								<Bounce duration={1400} right>
									<img className="img-fluid" src={hombreClipboard} alt=""/>
								</Bounce>
							</div>
							<div className="center z2 text-center">
								<Bounce delay={300} top>
									<img className="img-fluid" src={directorio} alt=""/>
								</Bounce>
							</div>
							<div className="center z3 text-center">
								<Fade duration={5000} left>
									<img className="img-fluid" src={nube} alt=""/>
								</Fade>
							</div>
							<div className="center z3 text-center">
								<Bounce duration={1400} bottom>
									<img className="img-fluid" src={base} alt=""/>
								</Bounce>
							</div>
							<div className="center z1 text-center">
								<Fade duration={5000} left>
									<img className="img-fluid" src={nubes} alt=""/>
								</Fade>
							</div>
							<div className="center-animation bg-shape">
								<Fade>
									<img className="img-fluid" src={bgShape} alt=""/>
								</Fade>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-6 col-lg-4">
						<div className="auth-innerright">
							<div className="authentication-box p-4">
								<div className="row">
									<div className="col-6 col-md-6">
										<img className="img-fluid" src={Icono} alt=""></img>
										<h3 className="text-uppercase f-w-600 txt-primary">Directorio</h3>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-12 col-lg-7">
										<p className="text-dark">
										Consulta el directorio telefónico, contiene los nombres, números y extensiones de todos los miembros de la empresa.
										</p>
										<button className="btn btn-pill btn-warning btn-sm mx-auto" onClick={this.redirectPublicaciones.bind(this)}>Ver más</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)};
}