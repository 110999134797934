import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PInicio = () => {

	const [pagina, setPagina] = useState(1);
	const [orden, setOrden] = useState(1);
	const [cantidad] = useState(10);
	const [total,setTotal] = useState(0);
	const [paginas, setPaginas] = useState([]);
	const [estado, setEstado] = useState(0);
	const [publicaciones, setPublicaciones] = useState([]);

	useEffect(() => {
		/*fetch('/z_noticias?orden=1&cantidad='+cantidad)
		.then((res) => res.json())
		.then((res) => {
			if(res.status === 'OK'){
				if(res.result[1].length > 0){
					let i, fin, total = Math.ceil(res.result[0][0].TOTAL/cantidad)
					if(pagina - 2 < 1){
						i = 1
						fin = 5
					}else{
						i = pagina - 2
						fin = pagina + 2
					}

					if(pagina + 2 > total){
						fin = total
					}
					console.log(i, fin, total);
					var paginas_temp=[]
					for(i; i <= fin; i++){
						paginas_temp.push(i)
					}
					setPaginas(paginas_temp)
					setPublicaciones(res.result[1])
					setEstado(1)
				}else{
					setPublicaciones(res.result[1])
					setEstado(2)
				}
				setPublicaciones(res.result[1])
			}else{
				setPublicaciones([])
				setEstado(2)
			}
		})
		.catch((err) => { console.log('ERROR: '+err); })*/

		setEstado(0)
		fetch('/z_noticias',{
			method: 'POST',
			body: JSON.stringify({
				orden,
				indice: (pagina - 1) * cantidad,
				cantidad
			}),
			headers: {'Content-Type': 'application/json'}
		})
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
			if(res.status === 'OK'){
				window.scrollTo(0,1000)
				let i,fin, total_tmp=Math.ceil(res.result[1][0].TOTAL/cantidad)
				let paginas_tmp=[]
				if(pagina - 2 < 1){
					i = 1
					fin = 5
				}else{
					i = pagina - 2
					fin = pagina + 2
				}
				if(pagina + 2 > total_tmp){
					fin = total_tmp
				}
				
				for(i; i <= fin; i++){
					paginas_tmp.push(i)
				}
				setTotal(total_tmp)
				setPaginas(paginas_tmp)
				setPublicaciones(res.result[0])
				setEstado(1)
				window.scrollTo(0,1000)
			}else{
				console.log('ERROR')
			}
		})
		.catch((err) => { console.log('ERROR: '+err); })
		// eslint-disable-next-line
	}, [pagina]);

	const loadPage=(page)=>{
		setPagina(page)
		/*setEstado(0)
		fetch('/z_noticias',{
			method: 'POST',
			body: JSON.stringify({
				orden,
				indice: (page - 1) * cantidad,
				cantidad
			}),
			headers: {'Content-Type': 'application/json'}
		})
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
				if(res.status === 'OK'){
					let i, fin
					if(page - 2 < 1){
						i = 1
						fin = 5
					}else{
						i = page - 2
						fin = page + 2
					}
					if(page + 2 > total){
						fin = total
					}
					var paginas_temp=[]
					for(i; i <= fin; i++){
						paginas_temp.push(i)
					}
					setPaginas(paginas_temp)
					setPagina(page)
					setPublicaciones(res.result)
					setEstado(1)
					window.scrollTo(0,1000)
				}else{
					console.log('ERROR')
				}
		})
		.catch((err) => { console.log('ERROR: '+err); })*/
	}

	const paginator=()=>{
		return (
			<Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-warning pagination-lg">
				{
					pagina !== 1 ?
					<Fragment>
						<PaginationItem>
							<PaginationLink first onClick={() => {loadPage(1)}} />
						</PaginationItem>
						<PaginationItem>
							<PaginationLink previous onClick={() => {loadPage(pagina - 1)}} />
						</PaginationItem>
					</Fragment>
					:
					<Fragment>
						<PaginationItem disabled>
							<PaginationLink first/>
						</PaginationItem>
						<PaginationItem disabled>
							<PaginationLink previous/>
						</PaginationItem>
					</Fragment>
				}
				{
					paginas.map((page) => 
					{
						return(
						pagina === page ? 
						<PaginationItem key={page} active>
							<PaginationLink>{page}</PaginationLink>
						</PaginationItem>:
						<PaginationItem key={page}>
							<PaginationLink onClick={() => {loadPage(page)}}>{page}</PaginationLink>
						</PaginationItem>)
					}	
					)
				}
				{
					total !== pagina ? 
					<Fragment>
						<PaginationItem>
							<PaginationLink next onClick={() => {loadPage(pagina + 1)}} />
						</PaginationItem>
						<PaginationItem>
							<PaginationLink last onClick={() => {loadPage(total)}} />
						</PaginationItem>
					</Fragment>:
					<Fragment>
						<PaginationItem disabled>
							<PaginationLink next/>
						</PaginationItem>
						<PaginationItem disabled>
							<PaginationLink last/>
						</PaginationItem>
					</Fragment>
				}
			</Pagination>
		)
	}

	const sortBy=(sortBy)=>{
		if (sortBy === 'Reciente') {
			setOrden(1)
		} else if (sortBy === 'Antiguo') {
			setOrden(2)
		} else if (sortBy === 'AZ') {
			setOrden(3)
		} else {
			setOrden(4)
		}
		loadPage(0);
		setTimeout(function(){loadPage(1)},50);
	}

	return (
		<Fragment>
			<Breadcrumb parent="Noticias" />
			<div className="container-fluid">
				<div className="row timeline-custom">
					<div className="col-12">
						<div className="card">
							<div className="card-header">
								<div className="row">
									<div className="col-md-6">
										<h5>Noticias.</h5>
									</div>
									<div className="col-md-6 text-right">
										<div className="select2-drpdwn-product select-options d-inline-block">
											<select className="form-control btn-square" onChange={(e) => sortBy(e.target.value)}>
												<option value="">Ordernar por</option>
												<option value="Reciente">Más reciente</option>
												<option value="Antiguo">Más antiguo</option>
												<option value="AZ">Ascendente (A - Z)</option>
												<option value="ZA">Descendente (Z - A)</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
    	                        <div className="col-12">
								{estado === 0 ? <div className="card-body d-flex justify-content-center align-items-center">
										<div className="loader-box mr-4">
											<div className="loader">
												<div className="line bg-warning"></div>
												<div className="line bg-warning"></div>
												<div className="line bg-warning"></div>
												<div className="line bg-warning"></div>
											</div>
										</div>
										<h5 className="text-uppercase">Cargando...</h5>
									</div>:
								estado === 2 ? <div className="card-body d-flex justify-content-center">
										<h5 className="text-uppercase">Sin noticias</h5>
									</div> :
								publicaciones.map((publicacion, index) => {
									return (
    	                                <div className="card" key={index}>
    	                                    <div className="blog-box blog-list row">
    	                                        <div className="col-sm-6 col-lg-4">
    	                                            <img className="img-fluid p-0" src={`/z_publicaciones?img=${publicacion.ID+publicacion.IMAGEN}`} alt="" />
    	                                        </div>
    	                                        <div className="col-sm-6 col-lg-8">
    	                                            <div className="blog-details p-3">
    	                                                <p className="digits m-0">{publicacion.FECHA_ALTA}</p>
    	                                                <h4 className="my-3"><Link to={'/publicacion/'+publicacion.ID}>{publicacion.TITULO}</Link></h4>
    	                                                <div>
    	                                                    <ul>
    	                                                        <li><i className="fa fa-user mr-1"></i>Autor: {publicacion.NOMBRE+' '+publicacion.APELLIDO_PATERNO+' '+publicacion.APELLIDO_MATERNO}</li>
    	                                                    </ul>
															<Link to={'/publicacion/'+publicacion.ID}>
																<button className="btn btn-light btn-sm mt-3">Leer nota</button>
															</Link>
    	                                                </div>
    	                                            </div>
    	                                        </div>
    	                                    </div>
    	                                </div>
									)
								})
								}
								</div>
							</div>
						</div>
							
						<div className="card-body">
						{estado ? paginator(): ''}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default PInicio;