import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class UsuariosAgregar extends React.Component{
	// RESCATAR ESTADO Y SELECCIONAR EL SELECT DE ESTADOS
	constructor(props){
		super(props)
		this.state = {
			filtros: false,
			ready: false,
			usuario: {},
			estados: []
		}
	}

	saveButton(){
		let contrasena1 = document.getElementById('form_contrasena1').value,
		contrasena2 = document.getElementById('form_contrasena2').value;
		if(contrasena1 !== contrasena2 && (contrasena1 !== "" || contrasena2 !== ""))
				toast.error("Las contraseñas no coinciden", { position: toast.POSITION.BOTTOM_RIGHT })
		else{
			let form_data = new FormData(document.getElementById('form_usuarios'))
			form_data.append('id', localStorage.getItem('id_usr'))
			fetch('/z_perfil_contra', {
				method: 'PUT',
				body: form_data
			})
			.then(res => res.json())
			.then(res => {
				console.log(res);
				if(res.status === 'OK'){
					document.getElementById('form_contrasena1').value = ''
					document.getElementById('form_contrasena2').value = ''
					toast.success("Actualizado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
				}
				else{
					toast.warn("No se pudo actualizar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
					console.log('No se pudo agregar');
				}
			})
			.catch(err => {toast.error("Ha ocurrido un error, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })})
		}
	}

	backButton(){
		this.props.history.push('/usuarios')
	}

	render(){
		return( 
			<Fragment>
			<Breadcrumb parent="Perfil" title="Editar" />
			<div className="container-fluid">
				<div className="edit-profile">
					<div className="row">
						<div className="col-12">
							<form id="form_usuarios" className="card">
								<div className="card-header">
									<h4 className="card-title mb-0">Cambiar Contraseña</h4>
									<div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
								</div>
								<div className="card-body">
									<div className="row">
										<Fragment>
											<div className="col-12">
												<label className="form-label"><strong>Si desea cambiar contraseña, llene los campos.</strong></label>
											</div>
											<div className="col-sm-12 col-md-6">
												<div className="form-group">
													<label className="form-label">Contraseña</label>
													<input id="form_contrasena1" name="contrasena1" className="form-control" type="password"/>
												</div>
											</div>
											<div className="col-sm-12 col-md-6">
												<div className="form-group">
													<label className="form-label">Confirmar contraseña</label>
													<input id="form_contrasena2" name="contrasena2" className="form-control" type="password"/>
												</div>
											</div>
										</Fragment>
									</div>
								</div>
								<div className="card-footer text-right">
									<div className="col-sm-12">
										<button onClick={this.saveButton.bind(this)} className="btn btn-primary mr-1" type="button">Guardar</button>
										<button onClick={this.backButton.bind(this)} className="btn btn-light">Atrás</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
		)
	}
}