import React, { Fragment, useState, useEffect } from 'react';
import teran_logo from '../../../assets/images/logo/logo-compact.png';

import UserPanel from './userPanel';
import { MENUITEMS } from '../../../constant/menu';
import { Link } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import CustomIcon from '../customIcon';
import active from '../../../auth/active';

const Sidebar = (props) => {
    const [margin, setMargin] = useState(0);
    const [width, setWidth] = useState(0);
    const [hideRightArrow, setHideRightArrow] = useState(false);
    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const [mainmenu, setMainMenu] = useState(() => {

        return localStorage.getItem('tipo') === 'admin' ?
        [	
            {
                title: 'Comunicados', custom_icon: 'fa fa-bullhorn', type: 'sub', active: false, children: [
                    { path: '/comunicados/inicio', title: 'Página principal', type: 'link' },
                    { path: '/comunicados/publicados', title: 'Publicados', type: 'link' }
                ]
            },
            {
                title: 'Empresas', custom_icon: 'fa fa-building', type: 'link', path: '/empresas', active: false
            },
            {
                title: 'Divisiones', custom_icon: 'fa fa-sitemap', type: 'link', path: '/divisiones', active: false
            },
            {
                title: 'Departamentos', custom_icon: 'fa fa-home', type: 'link', path: '/departamentos', active: false
            },
            {
                title: 'Puestos', custom_icon: 'fa fa-group', type: 'link', path: '/puestos', active: false
            },
            {
                title: 'Usuarios', custom_icon: 'fa fa-user', type: 'link', path: '/usuarios', active: false
            }
        ] : 
        [	
            {
                title: 'Comunicados', custom_icon: 'fa fa-bullhorn', type: 'sub', active: false, children: [
                    { path: '/comunicados/inicio', title: 'Página principal', type: 'link' },
                    { path: '/comunicados/recibidos', title: 'Bandeja de entrada', type: 'link' }
                ]
            }
        ] 
    });



    useEffect(() => {

        active.activeSend()

        window.addEventListener('resize', handleResize)
        handleResize();

        var currentUrl = window.location.pathname;

        mainmenu.filter(items => {
            if (items.path === currentUrl)
                setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
                if (subItems.path === currentUrl)
                    setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                    if (subSubItems.path === currentUrl)
                        setNavActive(subSubItems)
                })
            })
        })

        return () => {
            window.addEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, []);

    const handleResize = () => {
        setWidth(window.innerWidth - 310);
    }

    const setNavActive = (item) => {
        MENUITEMS.filter(menuItem => {
            if (menuItem !== item)
                menuItem.active = false
            if (menuItem.children && menuItem.children.includes(item))
                menuItem.active = true
            if (menuItem.children) {
                menuItem.children.filter(submenuItems => {
                    if (submenuItems.children && submenuItems.children.includes(item)) {
                        menuItem.active = true
                        submenuItems.active = true
                    }
                })
            }
        })
        item.active = !item.active
        setMainMenu({ mainmenu: MENUITEMS })

    }

    // Click Toggle menu
    const toggletNavActive = (item) => {
        if (!item.active) {
            MENUITEMS.forEach(a => {
                if (MENUITEMS.includes(item))
                    a.active = false
                if (!a.children) return false
                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                        b.active = false
                    }
                    if (!b.children) return false
                    b.children.forEach(c => {
                        if (b.children.includes(item)) {
                            c.active = false
                        }
                    })
                })
            });
        }
        item.active = !item.active
        setMainMenu({ mainmenu: MENUITEMS })
    }

    const scrollToRight = () => {

        const elmnt = document.getElementById("myDIV");
        const menuWidth = elmnt.offsetWidth;
        const temp = menuWidth + margin
        // Checking condition for remaing margin 
        if (temp < menuWidth) {
            setMargin(-temp);
            setHideRightArrow(true);
        }
        else {
            setMargin(margin => margin += -width);
            setHideLeftArrow(false);
        }

    }

    const scrollToLeft = () => {
        // If Margin is reach between screen resolution
        if (margin >= -width) {
            setMargin(0)
            setHideLeftArrow(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrow(false);
        }
    }

    return (
        <Fragment>
            <div className="page-sidebar">
                <div className="main-header-left d-none d-lg-block">
                    <div className="logo-wrapper compactLogo">
                        <Link to="/comunicados/inicio">
                            <img className="blur-up lazyloaded img-responsive" src={teran_logo} alt="" />
                            <img className="blur-up lazyloaded img-responsive" src={teran_logo} alt="" />
                        </Link>
                    </div>
                </div>
                <div className="sidebar custom-scrollbar">
                    <UserPanel />
                    <ul
                        className="sidebar-menu"
                        id="myDIV"
                        style={{ 'marginLeft': margin + 'px' }}
                    >
                        <li className={`left-arrow ${hideLeftArrow ? 'd-none' : 'hideLeftArrow'}`} onClick={scrollToLeft}><i className="fa fa-angle-left"></i></li>
                        {
                            MENUITEMS.map((menuItem, i) =>
                                <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                                    {(menuItem.sidebartitle) ?
                                        <div className="sidebar-title">{menuItem.sidebartitle}</div>
                                        : ''}
                                    {(menuItem.type === 'separator') ?
                                        <hr style={{backgroundColor: 'whitesmoke'}}></hr>
                                        :''}
                                    {(menuItem.type === 'redirect') ?
                                        <a className="sidebar-header" href={menuItem.path} onClick={() => toggletNavActive(menuItem)}>
                                            {menuItem.custom_icon ? 
                                            <CustomIcon classSrc={menuItem.custom_icon}/>
                                            :
                                            <menuItem.icon />
                                            }
                                            <span>{props.t(menuItem.title)}</span>
                                        </a>
                                        : ''}
                                    {(menuItem.type === 'sub') ?
                                        <a className="sidebar-header" href="#" onClick={() => toggletNavActive(menuItem)}>
                                            {menuItem.custom_icon ? 
                                            <CustomIcon classSrc={menuItem.custom_icon}/>
                                            :
                                            <menuItem.icon />
                                            }
                                            <span>{props.t(menuItem.title)}</span>
                                            <i className="fa fa-angle-right pull-right"></i>
                                        </a>
                                        : ''}
                                    {(menuItem.type === 'link') ?
                                        <Link
                                            to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                                            className={`sidebar-header ${menuItem.active ? 'active' : ''}`}

                                            onClick={() => toggletNavActive(menuItem)}
                                        >   {menuItem.custom_icon ? 
                                            <CustomIcon classSrc={menuItem.custom_icon}/>
                                            :
                                            <menuItem.icon />
                                            }
                                            <span>{props.t(menuItem.title)}</span>
                                            {menuItem.children ?
                                                <i className="fa fa-angle-right pull-right"></i> : ''}
                                        </Link>
                                        : ''}
                                    {menuItem.children ?
                                        <ul
                                            className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                                            style={menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
                                        >
                                            {menuItem.children.map((childrenItem, index) =>
                                                <li key={index} className={childrenItem.children ? childrenItem.active ? 'active' : '' : ''}>
                                                    {(childrenItem.type === 'sub') ?
                                                        <a href="" onClick={() => toggletNavActive(childrenItem)} >
                                                            <i className="fa fa-circle"></i>{props.t(childrenItem.title)} <i className="fa fa-angle-right pull-right"></i></a>
                                                        : ''}

                                                    {(childrenItem.type === 'link') ?
                                                        <Link
                                                            to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                                            className={childrenItem.active ? 'active' : ''}
                                                            onClick={() => toggletNavActive(childrenItem)}
                                                        >
                                                            <i className={childrenItem.custom_icon}></i> {props.t(childrenItem.title)} </Link>
                                                        : ''}
                                                    {childrenItem.children ?
                                                        <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'}`}>
                                                            {childrenItem.children.map((childrenSubItem, key) =>
                                                                <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                                                    {(childrenSubItem.type === 'link') ?
                                                                        <Link
                                                                            to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                                            className={childrenSubItem.active ? 'active' : ''}
                                                                            onClick={() => toggletNavActive(childrenSubItem)}
                                                                        >
                                                                            <i className="fa fa-circle"></i>{props.t(childrenSubItem.title)}</Link>
                                                                        : ''}
                                                                </li>
                                                            )}
                                                        </ul>
                                                        : ''}
                                                </li>
                                            )}
                                        </ul>
                                        : ''}
                                </li>
                            )
                        }
                        <li className={`right-arrow ${hideRightArrow ? 'd-none' : 'hideRightArrow'}`} onClick={scrollToRight}><i className="fa fa-angle-right"></i></li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default translate(Sidebar);

