import React from "react";

export default function SVGFile() {
  return (
    <svg
        version="1.1"
        id="cloudFogFill"
        className="climacon climacon_cloudFogFill"
        viewBox="15 15 70 70">
        <g className="climacon_iconWrap climacon_iconWrap-cloudFog">
            <g className="climacon_wrapperComponent climacon_wrapperComponent-Fog">
                <path
                    className="gray-fill climacon_component climacon_component-stroke climacon_component-stroke_fogLine climacon_component-stroke_fogLine-top"
                    d="M69.998,57.641H30.003c-1.104,0-2-0.895-2-2c0-1.104,0.896-2,2-2h39.995c1.104,0,2,0.896,2,2C71.998,56.746,71.104,57.641,69.998,57.641z" />
                <path
                    className="gray-fill climacon_component climacon_component-stroke climacon_component-stroke_fogLine climacon_component-stroke_fogLine-middle"
                    d="M69.998,65.641H30.003c-1.104,0-2-0.896-2-2s0.896-2,2-2h39.995c1.104,0,2,0.896,2,2C71.998,64.744,71.104,65.641,69.998,65.641z" />
                <path
                    className="gray-fill climacon_component climacon_component-stroke climacon_component-stroke_fogLine climacon_component-stroke_fogLine-bottom"
                    d="M30.003,69.639h39.995c1.104,0,2,0.896,2,2c0,1.105-0.896,2-2,2H30.003c-1.104,0-2-0.895-2-2C28.003,70.535,28.898,69.639,30.003,69.639z" />
            </g>
            <g className="climacon_wrapperComponent climacon_wrapperComponent-cloud">
                <path
                    className="gray-fill climacon_component climacon_component-stroke climacon_component-stroke_cloud"
                    d="M59.999,45.643c-1.601,0-3.083,0.48-4.333,1.291c-1.232-5.317-5.974-9.291-11.665-9.291c-6.626,0-11.998,5.373-11.998,12h-4c0-8.835,7.163-15.999,15.998-15.999c6.004,0,11.229,3.312,13.965,8.204c0.664-0.113,1.337-0.205,2.033-0.205c5.222,0,9.651,3.342,11.301,8h-4.381C65.535,47.253,62.958,45.643,59.999,45.643z" />
                <path
                    className="gray-fill climacon_component climacon_component-stroke climacon_component-stroke_cloud"
                    d="M71.287,49.355c-1.659-4.632-6.08-7.954-11.289-7.954c-0.695,0-1.369,0.091-2.033,0.205C55.229,36.72,50.004,33.413,44,33.413c-8.824,0-15.977,7.134-15.996,15.942H71.287z" />
                <path
                    fill="#FFFFFF"
                    className="gray-fill climacon_component climacon_component-fill climacon_component-fill_cloud"
                    d="M66.897,49.376c-1.389-2.369-3.955-3.965-6.899-3.965c-1.602,0-3.082,0.48-4.334,1.291c-1.23-5.316-5.973-9.291-11.664-9.291c-6.615,0-11.977,5.354-11.996,11.965H66.897z" />
            </g>
        </g>
    </svg>

);
}