import React, { Fragment, useState } from 'react';
import { Collapse } from 'reactstrap';
import Icofont from 'react-icofont';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import 'react-light-accordion/demo/css/index.css';

//CUSTOM COMPONENTS
import Descripcion from './descripcion';
import Galería from './galeria';
import Video from './video';

const options=[
    {key:0,title:'Descripción',ico:'icofont icofont-justify-left',Component:Descripcion},
    {key:1,title:'Galería',ico:'icofont icofont-ui-image',Component:Galería},
    {key:2,title:'Video',ico:'icofont icofont-ui-video-play',Component:Video}
]

const Editor = (props) => {
    const set = props.set;
    const elemts= props.noticia;

    const addItem =(option)=>{
        const e = {
            order:elemts.length,
            key:option.key,
            data:{},
            title:option.title,
        };
        set( 'elemts', [...elemts,e] );
    }

    return (
        <Fragment>
            
            {/* ELEMENTOS */}
            {elemts.map(e=>{return(<Elemt key={e.order} elmt={e} elemts={elemts} set={set}  editable={props.editable}/>)})}
            
            {props.editable?
                <div className="mb-3 d-flex justify-content-between">
                    <DropdownButton variant="success" title="Agregar">
                        {options.map((e)=>(e.title!=='Video' && e.title!=='Descripción') &&
                            (
                                <Dropdown.Item key={e.key} onClick={()=>addItem(e)}>
                                    <i className={e.ico}> </i> {e.title}
                                </Dropdown.Item>
                            )                            
                        )}
                    </DropdownButton>{' '}
                    <button
					className="btn btn-success"
                  	onClick={props.guardar}
                    >Guardar</button>
                </div>
            :''}

        </Fragment>
    );
};

const Elemt = (props) =>{
    const [open,close] = useState(true);
    const {key,title,order} = props.elmt;
    const {set} = props;
    let {elemts} = props;
    const Component = options[key].Component;

    const eliminar = () =>{
        elemts.splice(order,1);
        elemts.map((e,i)=>elemts[i].order=i);
        set('elemts',elemts);
    } 

    return(
        <Fragment>
            <div className="card">
                <div className="ml-4 mt-3 mb-2 w-100">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="f-w-600">{title}</h6>
                        </div>
                        <div className="col-6">
                            <div className="pull-right mr-5">
                                <Icofont 
                                    className="text-primary"
                                    icon={open?'minus':'plus'} 
                                    size="1"
                                    style={{cursor:'pointer'}}
                                    onClick={()=>close(!open)}
                                />
                                { (elemts.length > 1) && props.editable && title!=='Descripcion' ?
                                    <Icofont 
                                        className="text-danger ml-2" 
                                        icon="trash" 
                                        size="1"
                                        style={{cursor:'pointer'}}
                                        onClick={eliminar}
                                    />
                                :
                                    ''
                                }
                            </div>
                        </div>
                    </div>

                </div>

                <Collapse isOpen={open}>
                    {/* ELEMENTO */}
                    <Component elmt={props.elmt} elemts={props.elemts} set={props.set}/>
                </Collapse>

            </div>
        </Fragment>
    )
}

export default Editor;