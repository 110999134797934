import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from "../common/breadcrumb";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import CKEditors from "react-ckeditor-component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap-button-loader';
import Tablereact from '../common/tabulator/tablereact'
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import Elemts from '../common/elemts/elemts3';

const ComunicadosEditar = (props) => {

	const [id_comunicado, setId_comunicado] = useState(props.match.params.id);
	const [ready, setReady] = useState(false);
	const [comunicado, setComunicado] = useState({});
	const [comunicados, setComunicados] = useState([]);
	const [archivos_subidos, setArchivos_subidos] = useState([]);
	const [img_dropzone, setImg_dropzone] = useState(true);
	const [look, setLook] = useState(false);
	const [portada, setPortada] = useState([]);
	const [toggle, setToggle] = useState(false);
	const [nombre_portada, setNombre_portada] = useState('');
	const [archivos_adjuntos, setArchivos_adjuntos] = useState([]);
	const [elemts, setElemts] = useState([]);

	useEffect(() => {
		fetch('/z_capital_humanos', {
			method: 'POST',
			body: JSON.stringify({id_comunicado}),
			headers: {'Content-Type': 'application/json'}
		})
		.then( res => res.json() )
		.then((res) => {
			console.log(res);
				if(res.status == 1){
					var subidas=[]
					for(var i=0;i<res.result[1].length;i++){
						subidas.push({...res.result[1][i],subir:true})
					}

					var vid=[]
        			for (var x=0;x<res.result[2].length;x++) {
            			vid.push({data:{ key:0, title:res.result[2][x].TIPO_VIDEO, link:res.result[2][x].URL, code:res.result[2][x].CODE }, key:0, order:x, title:"Video"})
					}

					setReady(true)
					setComunicado(res.result[0][0])
					setArchivos_subidos(subidas)
					setToggle(!!res.result[0][0].PORTADA)
					setNombre_portada(res.result[0][0].PORTADA)
					setElemts(vid)
				}else{console.log('ERROR')}
		})
		.catch((err) => {console.log('ERROR: '+err)})

		fetch('/z_capital_humanos_detalles',{
			method: 'POST',
			body: JSON.stringify({id_comunicado}),
			headers: {'Content-Type': 'application/json'}
		})
		.then( res => res.json() )
		.then((res) => {
			console.log(res);
			if(res.status == 1){
				setComunicados(res.result)
			}else{console.log('ERROR')}
		})
		.catch((err) => {console.log('ERROR: '+err)})
	}, []);

	const set = (name, value) => name == 'elemts' && setElemts([...value])

	const saveButton=()=>{
		setLook(true)

		var datosVideo=[]
		for(var i=0;i<elemts.length;i++){
			if(elemts[i].title="Video" && elemts[i].data.link ){
				var texto_link=elemts[i].data.link
				if(texto_link.trim()!=''){
					let title=elemts[i].data.title
					let link=elemts[i].data.link
					let code=elemts[i].data.code
					datosVideo.push({title,link,code})
				}
				
			}
		}

		let form_data = new FormData(document.getElementById('form_comunicados'))
		form_data.append('DESCRIPCION', comunicado.DESCRIPCION)
		form_data.append('ID', id_comunicado)
		form_data.append('archivos_subidos',JSON.stringify(archivos_subidos))
		form_data.append('portada', portada[0])
		form_data.append('toggle', JSON.stringify(toggle))
		form_data.append('datosVideo', JSON.stringify(datosVideo))
		form_data.append('nombre_portada', JSON.stringify(nombre_portada))

		archivos_adjuntos.forEach((file, index) => { // Se adjuntan al form los archivos que se hayan cargado en el dropzone
			form_data.append('archivo_'+index, file)
		})

		fetch('/p_capital_humanos', {
			method: 'PUT',
			body: form_data
		})
		.then(res => res.json())
		.then(res => {
			console.log(res);
			setLook(false)
			if(res.status == 'OK' && res.rows[0] > 0){
				setArchivos_adjuntos([])
				toast.success("Actualizado", { position: toast.POSITION.BOTTOM_RIGHT })
				setImg_dropzone(false)
				var subidas=[]
				for(var i=0;i<res.result[1].length;i++){
					subidas.push({...res.result[1][i],subir:true})
				}
				setComunicado(res.result[0][0])
				setArchivos_subidos(subidas)
				setTimeout(() => {setImg_dropzone(true)}, 100)
			}
			else{
				console.log('No se pudo actualizar');
			}
		})
		.catch(err => {setLook(false);console.log('Error: '+err);})
	}

	const backButton = ()=> {
		props.history.push('/capitalhumano/publicados')
	}

	const editorData=(event)=>{
		setComunicado({...comunicado,DESCRIPCION:event.editor.getData()})
	}

	const enviar=()=>{
		if(id_comunicado){
			fetch('/z_enviar_capital_humano', {
				method: 'POST',
				body: JSON.stringify({id:id_comunicado}), 
				headers: {'Content-Type': 'application/json'}
			})
			.then(res => res.json())
			.then(res => {
				if(res.status == 1 && res.rows[0] > 0){
					toast.success("Enviado", { position: toast.POSITION.BOTTOM_RIGHT })
				}
			})
			.catch(err => {console.log('Error: '+err);})
		}		
	}

	const borrar_archivo=(id)=>{
		var datos=archivos_subidos
		var index = datos.findIndex(obj => obj.ID==id);
		datos[index].subir=!datos[index].subir
		setArchivos_subidos(datos)
	}

	const changeDropzone=({ meta, file }, status)=>{
		if(status === 'done'){
			var archivos_adjuntos_temp=archivos_adjuntos
			archivos_adjuntos_temp.push(file)
			setArchivos_adjuntos(archivos_adjuntos_temp)
		}
		if(status === 'removed'){
			var archivos_adjuntos_temp=archivos_adjuntos
			let index = archivos_adjuntos_temp.findIndex(item => item === file)
			archivos_adjuntos_temp.splice(index , 1)
			setArchivos_adjuntos(archivos_adjuntos_temp)
		}
		console.log(archivos_adjuntos);
	}

	const changeDropzonePortada=({ meta, file }, status)=>{
		if(status === 'done'){
			console.log('done')
			setPortada([file])
			/* var reader = new FileReader();
        	reader.onloadend = function () {
				var b64 = reader.result;
				setPortada([{name:file.name,base:b64}])
        	}
        	reader.readAsDataURL(file); */
		}
		if(status === 'removed'){
			console.log('removed')
			setPortada([])
		}
	}

	const cabecera = [
        { Header: <b>Nombre</b>, accessor: 'NOMBRE', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
        { Header: <b>Estatus</b>, accessor: 'NOTIFICACION', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
        { Header: <b>Descarga</b>, accessor: 'DESCARGA', style: { textAlign: 'center'}, width:'auto', editable:true , show:true}
    ];

	return (
		<Fragment>
			<Breadcrumb title="Editar comunicado" parent="Comunicados" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form id="form_comunicados" className="form theme-form">
							<div className="card">
								<div className="card-header">
									<h5>Información del Capital Humano</h5>
								</div>
								<div className="card-body add-post">
									{ready === false ? <h5>Cargando...</h5>:
										<div className="col-sm-12">
											<div className="email-wrapper">
											<div className="form-group">
												<label className="form-label">Portada</label>
												{
													toggle ? 
													<div className="py-5" style={{background:'#DAE0FC', borderStyle:'dashed', borderColor:'#4466F2'}}>
								                    	<p className="ml-3 h6">{comunicado.PORTADA} <i className="fa fa-times text-right" style={{cursor:'pointer', color:'#444548'}} onClick={()=>setToggle(!toggle)}></i></p>
								                	</div>
													:
													<Dropzone inputContent="Toque para insertar imagen" maxFiles={1} inputContent="Arrastre o toque para insertar imagen" inputWithFilesContent="Agregar imagen"  onChangeStatus={changeDropzonePortada} accept="image/*" />
												}
												
											</div>
                                        <p><strong>Fecha: </strong>{comunicado.FECHA_PUBLICACION}</p>
                                        <p><strong>De: </strong>{comunicado.REMITENTE}</p>
                                        <p><strong>Para: </strong>{comunicado.DESTINATARIO}</p>
                                        <p><strong>Asunto: </strong>{comunicado.ASUNTO}</p>
										<div className="form-group">
											<label htmlFor="form_titulo"><strong>Título:</strong></label>
											<input className="form-control" id="form_titulo" name="TITULO" type="text" placeholder="Título" defaultValue={comunicado.TITULO} required="" />
										</div>
										<div className="form-group">
											<label className="form-label">Archivos adjuntos</label>
											{img_dropzone && <Dropzone inputContent="Toque para insertar archivos" inputWithFilesContent="Agregar archivos" onChangeStatus={changeDropzone} submitButtonContent="" accept="*" />}
										</div>
										<div className="form-group">
											<label className="form-label">Archivos subidos: {!archivos_subidos.length && 'No hay archivos subidos'}</label>
											<div className="row">
												{
													archivos_subidos.map(
														x=><div key={x.ID} className="col-4 mb-2 border-left border-right border-primary d-flex justify-content-between">
															<div>{x.ARCHIVO_RUTA}</div>
															{
																x.subir ?
																<i className="icofont icofont-trash" style={{color:'red', marginTop:'5px', cursor:'pointer'}} onClick={()=>borrar_archivo(x.ID)}></i>
																:
																<i className="icofont icofont-ui-reply" style={{color:'green', marginTop:'5px', cursor:'pointer'}} onClick={()=>borrar_archivo(x.ID)}></i>
															}
														</div>
													)
												}
												
											</div>
										</div>
										<div className="form-group">
											<label className="form-label"><strong>Mensaje:</strong></label>
											<CKEditors
												config={{versionCheck: false}}
												content={comunicado.DESCRIPCION}
												activeclassName="p10"
												events={{
													"change": editorData
												}}
												heigth='600px'
											/>
										</div>
										<Elemts noticia={elemts} set={set} editable={true}/>
                                    </div>
										</div>
									}
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-6 col-sm-6 col-lg-6">
											<button onClick={backButton} className="btn btn-light">Atrás</button>
										</div>
										<div className="col-6 col-sm-6 col-lg-6 text-right">
											<button onClick={enviar} className="btn btn-info mr-2" type="button">Enviar</button>
											<Button onClick={saveButton} className='btn btn-primary mr-1' type="button" loading={look}>Guardar</Button>
										</div>
									</div>
								</div>
							</div>
						</form>
						<div className="card">
							<div className="card-header">
								<h5>Interacción de los usuarios:</h5>
							</div>
							<div className="card-body">
							{
								comunicados.length === 0 ?
								<div className="d-flex justify-content-center align-items-center">
									<div className="loader-box mr-4">
										<div className="loader">
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
										</div>
									</div>
									<h5 className="text-uppercase">Cargando...</h5>
								</div>
								:
								<Tablereact
                        		    myData={comunicados}
                        		    cabecera={cabecera}
                        		    pageSize={10}
                        		    paginacion={true}
                        		    buscador='Buscar'
                        		/>
							}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default ComunicadosEditar;