import React, { Fragment } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Icofont from 'react-icofont';

const options =[
  {
    key:0,
    title:'YouTube',
    link:'https://www.youtube.com/embed/',
    ico:'youtube-play',
    cm:'ml-2 text-danger',
    fuentes:[
      'www.youtube.com/embed/',
      'www.youtube.com/watch?v=',
      'youtu.be/'
    ]
  },
  /* {
    key:1,
    title:'Facebook',
    link2:'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebookapp%2Fvideos%2F',
    link:'https://www.facebook.com/video/embed?video_id=',
    ico:'facebook',
    cm:'text-primary',
    fuentes:[
      'www.facebook.com/watch/?v=',
      'www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebookapp%2Fvideos%2F',
      'www.facebook.com/video/embed?video_id='
    ]
  } */
]

const Video = props =>{
  const {elmt,set} = props;
  let {elemts} = props;
  
  var video ={key:0,title:'YouTube',link:'',code:''};
  
  if(elmt.data.key !== undefined)
    video=elemts[elmt.order].data;

  const setOption=e=>{
    elemts[elmt.order].data={key:e.key,title:e.title,link:'',code:''};
    set('elemts',elemts);
  }

  const setLink=e=>{
    var link=e.target.value;
    elemts[elmt.order].data={key:video.key,title:video.title,link:link,code:getCode(link)};
    set('elemts',elemts);
  }

  const getCode = l =>{
    l=l.replace('https://', '').replace('http://', '');
    let code = '';
    options[video.key].fuentes.map(e=>{
      if(code===''&&l.includes(e)){
        code=options[video.key].link+l.replace(e,'');
        if(video.key===0)
          code=code+'?rel=0';
      }
      else{
        if(video.key===1 && l.includes('www.facebook.com/') && l.includes('/videos/'))
          code=options[video.key].link+l.split('/videos/')[1];
      }
      return code
    });
    return code;
  }

  return (
    <Fragment>
      
      <div className="card-block row">
          <div className="col-sm-12 col-lg-12 col-xl-12">
            
            <div className="table-responsive mb-3">
              <table className="table table-striped">
                <tbody>
                  
                  <tr>
                    <td>
                      <div className="col-sm-12">
                        <div className="row">
                          
                          <div className="col-md-4">
                            {/* PREVIEW VIDEO*/}
                            <label>Video</label>
                            <div className="embed-responsive embed-responsive-16by9 bg-dark text-white">
                              <iframe title="video" className="embed-responsive-item" src={video.code}></iframe>
                            </div>
                          </div>
                          
                          <div className="col-md-8">
                            
                            <div className="form-row">
                              {/* VIDEO OPTION */}
                              <div className="col-md-12 mb-3">
                                <label>Fuente</label>
                                <div className="row"> 
                                  
                                  {/* VIDEO OPTION BUTTONS */}
                                  {options.map(e=>
                                    (
                                      <Fragment key={e.key}>
                                        <div 
                                          className={'col-6 col-md-3'+ (video.key===e.key?' border-bottom border-primary':'')}
                                          style={{cursor:'pointer'}}
                                          onClick={()=>{setOption(e)}}
                                        >
                                          <label style={{cursor:'pointer'}}>{e.title}</label>
                                          <Icofont 
                                            className={'align-middle '+e.cm}
                                            icon={e.ico} 
                                            size="2"
                                          />
                                        </div>
                                      </Fragment>
                                    )
                                  )}

                                </div>
                              </div>

                            </div>

                            <div className="form-row" >
                              {/* INPUT LINK */}
                              <div className="col-md-12 mb-3">
                                <label>Enlace del video</label>
                                <input 
                                  className="form-control" 
                                  name="link" 
                                  type="text" 
                                  autoComplete="off" 
                                  placeholder="Video..."
                                  value={video.link}
                                  onChange={setLink}
                                />
                              </div>
                            </div>

                          </div>
                        
                        </div>
                      </div>
                    </td>
                  </tr>
                
                </tbody>
              </table>
            </div>
          
          </div>
      </div>
    
    </Fragment>
  );

}

  export default Video;