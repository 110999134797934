import React, {Fragment,useState,useEffect} from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const ComunicadosR = (props) => {

	const [estado, setEstado] = useState(0);
	const [comunicados, setComunicados] = useState([]);
	const [pagina, setPagina] = useState(1);
	const [orden, setOrden] = useState(1);
	const [cantidad] = useState(10);
	const [total,setTotal] = useState(0);
	const [paginas, setPaginas] = useState([]);

	useEffect(() => {
		setEstado(0)

		fetch('/z_comunicados_usuarios',{
			method: 'PUT',
			body: JSON.stringify({
				orden: orden,
				indice: (pagina - 1) * cantidad,
				cantidad: cantidad
			}),
			headers: {'Content-Type': 'application/json'}
		})
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
			window.scrollTo(0,1000)
			if(res.status === 'OK'){
					console.log('Total',res.result);
					let i, fin, total_tmp=Math.ceil(res.result[1][0].TOTAL/cantidad)
					let paginas_tmp=[]
					
					if(pagina - 2 < 1){
						i = 1
						fin = 5
					}else{
						i = pagina - 2
						fin = pagina + 2
					}

					if(pagina + 2 > total_tmp){
						fin = total_tmp
					}
					
					for(i; i <= fin; i++){
						paginas_tmp.push(i)
					}
					setPaginas(paginas_tmp)
					setTotal(total_tmp)
					setComunicados(res.result[0])
					setEstado(1)
					
			}else{
				console.log('ERROR')
			}
		})
		.catch((err) => { console.log('ERROR: '+err); })
		// eslint-disable-next-line
	}, [pagina]);

	const loadPage=(page)=>{
		setPagina(page)
	}

	const sortBy=(sortBy)=>{
		if (sortBy === 'Reciente') {
			setOrden(1)
		} else if (sortBy === 'Antiguo') {
			setOrden(2)
		} else if (sortBy === 'AZ') {
			setOrden(3)
		} else {
			setOrden(4)
		}
		loadPage(0);
		setTimeout(function(){loadPage(1)},50);
	}

	const like=async (index, likes, id)=>{
		let like = document.getElementById('like'+index)
		if(like.className === 'icon fa fa-thumbs-o-up'){
			await setEstadoComunicado(1, true, id)
			like.className = 'icon fa fa-thumbs-up'
			document.getElementById('span_like'+index).innerText = (likes+1)+' Me gusta'
		}else{
			await setEstadoComunicado(1, false, id)
			like.className = 'icon fa fa-thumbs-o-up'
			document.getElementById('span_like'+index).innerText = likes+' Me gusta'
		}
	}

	const favorite=async (index, id)=>{
		let fav = document.getElementById('favorite'+index)
		if(fav.className === 'icon fa fa-star-o'){
			await setEstadoComunicado(2, true, id)
			fav.className = 'icon fa fa-star'
		}else{
			await setEstadoComunicado(2, false, id)
			fav.className = 'icon fa fa-star-o'
		}
	}

	const setEstadoComunicado=async (type, value, id)=>{
		await fetch('/z_comunicado_estado', {
			method: 'POST',
			body: JSON.stringify({ID: id, TIPO: type, ESTADO: value}),
			headers: {'Content-Type': 'application/json'}
		})
		.then(res => res.json())
		.then(res => {console.log(res);})
		.catch(err => {console.log(err);})
	}

	const paginator=()=>{
		return (
			<Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-warning pagination-lg">
				{
					pagina !== 1 ?
					<Fragment>
						<PaginationItem>
							<PaginationLink first onClick={() => {loadPage(1)}} />
						</PaginationItem>
						<PaginationItem>
							<PaginationLink previous onClick={() => {loadPage(pagina - 1)}} />
						</PaginationItem>
					</Fragment>
					:
					<Fragment>
						<PaginationItem disabled>
							<PaginationLink first/>
						</PaginationItem>
						<PaginationItem disabled>
							<PaginationLink previous/>
						</PaginationItem>
					</Fragment>
				}
				{
					paginas.map((page) => 
					{
						return(
						pagina === page ? 
						<PaginationItem key={page} active>
							<PaginationLink>{page}</PaginationLink>
						</PaginationItem>:
						<PaginationItem key={page}>
							<PaginationLink onClick={() => {loadPage(page)}}>{page}</PaginationLink>
						</PaginationItem>)
					})
				}
				{
					total !== pagina ? 
					<Fragment>
						<PaginationItem>
							<PaginationLink next onClick={() => {loadPage(pagina + 1)}} />
						</PaginationItem>
						<PaginationItem>
							<PaginationLink last onClick={() => {loadPage(total)}} />
						</PaginationItem>
					</Fragment>:
					<Fragment>
						<PaginationItem disabled>
							<PaginationLink next/>
						</PaginationItem>
						<PaginationItem disabled>
							<PaginationLink last/>
						</PaginationItem>
					</Fragment>
				}
			</Pagination>
		)
	}

	return (
		<Fragment>
		<Breadcrumb title="Bandeja de entrada" parent="Comunicados" />
		<div className="container-fluid">
			<div className="row timeline-custom">
				<div className="col-12">
					<div className="card">
						<div className="card-header">
							<div className="row">
								<div className="col-md-6">
									<h5>Comunicados recibidos.</h5>
								</div>
								<div className="col-md-6 text-right">
									<div className="select2-drpdwn-product select-options d-inline-block">
										<select className="form-control btn-square" onChange={(e) => sortBy(e.target.value)}>
											<option value="">Ordernar por</option>
											<option value="Reciente">Más reciente</option>
											<option value="Antiguo">Más antiguo</option>
											<option value="AZ">Ascendente (A - Z)</option>
											<option value="ZA">Descendente (Z - A)</option>
											<option value="NoLeido">No leído</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="card-body">
							<div className="timeline-small">
							{estado === 0 ?<div className="card-body d-flex justify-content-center align-items-center">
									<div className="loader-box mr-4">
										<div className="loader">
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
										</div>
									</div>
									<h5 className="text-uppercase">Cargando...</h5>
								</div>:
							estado === 2 ? <div className="card-body d-flex justify-content-center">
									<h5 className="text-uppercase">Sin comunicados</h5>
								</div> :
							comunicados.map((comunicado, index) => {
								return (
								
								<div className="media">
									{
									comunicado.NOTIFICACION ?
									<div className="timeline-round m-r-30 timeline-line-1 bg-light text-dark">
										<p className="m-0">{comunicado.MES}</p>
										<h3 className="m-0">{comunicado.DIA}</h3>
										<p className="m-0">{comunicado.ANIO}</p>
									</div>
									:
									<div className="timeline-round m-r-30 timeline-line-1 bg-primary">
										<p className="m-0">{comunicado.MES}</p>
										<h3 className="m-0">{comunicado.DIA}</h3>
										<p className="m-0">{comunicado.ANIO}</p>
									</div>
									}
									
									<div className="media-body">
										<span className="pull-right f-14">Visto por {comunicado.VISTOS}</span>
										<h6>
										<a className="media" key={index} href="" onClick={()=>{props.history.push('/comunicados/comunicado/'+comunicado.ID)}}>{comunicado.TITULO}</a>
										</h6>
										{/* <p>{!!comunicado.ASUNTO.length > 200 ? comunicado.ASUNTO.substring(0, 200)+'...' : comunicado.ASUNTO}</p> */}
										<div>
											{comunicado.ME_GUSTA ? 
											<a href="#" onClick={() => { like(index, comunicado.ME_GUSTAN - 1, comunicado.ID_CU) }} className="txt-primary like-btn">
												<i id={'like'+index} className='icon fa fa-thumbs-up'></i> <span id={'span_like'+index}>{(comunicado.ME_GUSTAN)+' Me gusta'}</span>
											</a>:
											<a href="#" onClick={() => { like(index, comunicado.ME_GUSTAN, comunicado.ID_CU) }} className="txt-primary like-btn">
												<i id={'like'+index} className='icon fa fa-thumbs-o-up'></i> <span id={'span_like'+index}>{comunicado.ME_GUSTAN+' Me gusta'}</span>
											</a>
											}
											<a href="#" onClick={() => { favorite(index, comunicado.ID_CU) }} className="pull-right f-14 favorite-btn">
												<i id={'favorite'+index} className={comunicado.FAVORITO ? 'icon fa fa-star':'icon fa fa-star-o'}></i>
											</a>
										</div>
									</div>
								</div>
								)
							})
							}
							</div>
						</div>
					</div>

					
					<div className="card-body">
					{!!estado && paginator()}
					</div>
				</div>
			</div>
		</div>
	</Fragment>
	);
}
 
export default ComunicadosR;