import React, { Fragment  } from 'react';
import { MENUITEMS } from '../../../constant/menu';
import { Search } from 'react-feather';

export default class SearchHeader extends React.Component{
    constructor(props){
        super(props)
        this.menuitems = MENUITEMS
        this.searchOpen = false
    }

    toggleBtn(){
        if(this.searchOpen){
            this.searchOpen =  true;
            document.querySelector('.searchIcon').classList.add('open');
        }else{
            this.searchOpen =  false;
            document.querySelector('.searchIcon').classList.remove('open');
        }
    }
    
    render(){
        const {buscador} = this.context
        return (
            <Fragment>
                {
                    buscador && 

                    <div>
                    <form id="form_search" className="form-inline search-form row" action={'#'}>
                        <div className="col-12">
                        <div className="form-group addSearchIcon">
                            <input
                                id="tab_search"
                                className="form-control-plaintext searchIcon"
                                type="text"
                                placeholder="Buscar"
                                defaultValue={''}
                                onKeyDown={(e)=>{e.keyCode===13 && e.preventDefault()}}
                            />
                            <span className="d-sm-none mobile-search" onClick={this.toggleBtn}>
                                <Search />
                            </span>
                        </div>
                        </div>
                    </form>
                </div>
                }
            </Fragment>
        );
    }
};

