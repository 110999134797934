import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DirectorioEditar = (props) => {
	const [id_contacto] = useState(props.match.params.id);
	const [ready, setReady] = useState(false);
	const [contacto, setContacto] = useState({});

	useEffect(() => {
		fetch('/z_directorio', {
			method: 'POST',
			body: JSON.stringify({ID: id_contacto}),
			headers: {'Content-Type': 'application/json'}
		})
		.then( res => res.json() )
		.then((res) => {
			if(res.status === 'OK'){
				setContacto(res.result[0])
				setReady(true)
			}else{
				console.log('ERROR')
			}
		})
		.catch((err) => {
			console.log('ERROR: '+err)
		})
		// eslint-disable-next-line
	}, []);

	const saveButton=()=>{
		let form_data = new FormData(document.getElementById('form_directorio'))
		form_data.append('ID', id_contacto)
		form_data.append('FOTOGRAFIA', contacto.FOTOGRAFIA)

		fetch('/p_directorio', { method: 'PUT',	body: form_data})
		.then(res => res.json())
		.then(res => {
			if(res.status === 'OK'){
				toast.success("Actualizado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
				if(res.image)
					document.getElementById('img_usr').src = '/i_directorio?img='+res.image
			}
			else{
				toast.warn("No se pudo actualizar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
			}
		})
		.catch(err => {
			toast.warn("Ha ocurrido un error, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
		})
	}

	const backButton=()=>props.history.push('/directorio/inicio')

	return (
		<Fragment>
			<Breadcrumb title="Detalle" parent="Directorio" />
			<div className="container-fluid">
				<div className="row">
				<div className="col-sm-12">
					<div className="card">
						<div className="card-header">
							<h5>Datos del contacto</h5>
						</div>
					{ 
						ready ? 
						<form id="form_directorio" className="form theme-form">
						<div className="card-body">
							<div className="row">
								<div className="col-12 mb-4">
									<div className="avatar text-center">
										{/* <img id="img_usr" className="pro img-responsive" alt="" src={'/i_directorio?img='+this.contacto.ID+this.contacto.FOTOGRAFIA} data-intro="This is Profile image" style={{width: 150}} /> */}
										<img id="img_usr" className="pro img-responsive rounded-circle img-thumbnail" alt="" src={`/i_directorio?img=${contacto.FOTOGRAFIA!=null?(contacto.ID+contacto.FOTOGRAFIA):'avatar.png'}`} data-intro="This is Profile image" style={{width: 100}} />
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label className="form-label">Nombre: </label>
										<input defaultValue={contacto.NOMBRE}  name="NOMBRE" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Empresa: </label>
										<input defaultValue={contacto.EMPRESA}  name="EMPRESA" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Teléfono: </label>
										<input defaultValue={contacto.TELEFONO}  name="TELEFONO" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Celular: </label>
										<input defaultValue={contacto.CELULAR}  name="CELULAR" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Correo: </label>
										<input defaultValue={contacto.CORREO}  name="CORREO" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Redes sociales </label>
										<input defaultValue={contacto.REDES_SOCIALES}  name="REDES_SOCIALES" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Fotografía: </label>
										<input id="form_fotografia" name="fotografia" className="form-control" type="file" accept=".png,.jpg" />
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label className="form-label">Dirección del contacto</label>
										<input defaultValue={contacto.DIRECCION}  name="DIRECCION" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label className="form-label">Información del contacto: </label>
										<textarea defaultValue={contacto.INFORMACION}  name="INFORMACION" className="form-control" required />
									</div>
								</div>
							</div>
						</div>
						<div className="card-footer">
							<div className="row">
								<div className="col-12">
									<div className="btn-showcase">
										<button onClick={saveButton} className="btn btn-primary mr-1" type="button">Guardar</button>
										<button onClick={backButton} className="btn btn-light txt-dark">Atrás</button>
									</div>
								</div>
							</div>
						</div>
					</form>
					:
					<div className="card-body d-flex justify-content-center align-items-center">
						<div className="loader-box mr-4">
							<div className="loader">
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
							</div>
						</div>
						<h5 className="text-uppercase">Cargando...</h5>
					</div>
					}
					</div>
				</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default DirectorioEditar;