import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import SelectArray from '../common/select_array'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class UsuariosAgregar extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			filtros: false
		}
		this.empresas = []
		this.divisiones = []
		this.departamentos = []
		this.puestos = []
		this.estados = []
		this.tipos = [
			{ID: 3, NOMBRE: 'Administrador'},
			{ID: 4, NOMBRE: 'Usuario general'}
		]
		fetch('/p_comunicados')
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
				if(res.status === 1){
					this.empresas = res.result[0]
					this.divisiones = res.result[1]
					this.departamentos = res.result[2]
					this.puestos = res.result[3]
					this.estados = res.result[4]
					this.setState((s) => ({ filtros: true }))
				}else{
					console.log('No se pudo obtener la información');
				}
		})
		.catch((err) => {
			
			console.log('ERROR: '+err)
		})
	}

	saveButton(){
		let form_data = new FormData(document.getElementById('form_usuarios'))

		fetch('/p_usuarios', {
			method: 'POST',
			body: form_data
		})
		.then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status === 1 && res.rows[0] > 0){
				document.getElementById('form_usuarios').reset()
				toast.success("Agregado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
				console.log('Agregado');
			}
			else{
				toast.warn("No se pudo actualizar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
				
			}
		})
		.catch(err => {toast.error("Ha ocurrido un error, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT }); console.log('Error: '+err);}) 
	}

	backButton(){
		this.props.history.push('/usuarios')
	}

	render(){
		return( 
			<Fragment>
			<Breadcrumb parent="Usuarios" title="Agregar" />
			<div className="container-fluid">
				<div className="edit-profile">
					<div className="row">
						<div className="col-12">
							<form id="form_usuarios" className="card">
								<div className="card-header">
									<h4 className="card-title mb-0">Datos del usuario</h4>
									<div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
								</div>
								<div className="card-body">
									<div className="row">
										{this.state.filtros ?
										<Fragment>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Nombre(s)</label>
												<input id="form_nombre" name="nombre" className="form-control" type="text" />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Apellido paterno</label>
												<input id="form_apellido_paterno" name="apellido_paterno" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Apellido materno</label>
												<input id="form_apellido_materno" name="apellido_materno" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Fecha de nacimiento</label>
												<input id="form_fecha_nacimiento" name="fecha_nacimiento" className="form-control" type="date"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">CURP</label>
												<input id="form_curp" name="curp" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">RFC</label>
												<input id="form_rfc" name="rfc" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Calle</label>
												<input id="form_calle" name="calle" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Número</label>
												<input id="form_numero" name="numero" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Colonia</label>
												<input id="form_colonia" name="colonia" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Codigo postal</label>
												<input id="form_codigo_postal" name="codigo_postal" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												options={this.estados}
												element_id='form_estado'
												element_name='estado'
												label_text='Estado'
											/>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Fotografía</label>
												<input id="form_fotografia" name="fotografia" className="form-control" type="file" accept=".png,.jpg"/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Teléfono particular</label>
												<input id="form_telefono_particular" name="telefono_particular" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Teléfono oficina</label>
												<input id="form_telefono_oficina" name="telefono_oficina" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Extensión oficina</label>
												<input id="form_extension_oficina" name="extension_oficina" className="form-control" type="text"  />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Correo particular</label>
												<input id="form_correo_particular" name="correo_particular" className="form-control" type="email" />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Correo empresarial</label>
												<input id="form_correo_empresarial" name="correo_empresarial" className="form-control" type="email" />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<label className="form-label">Teléfono celular</label>
												<input id="form_telefono_celular" name="telefono_celular" className="form-control" type="text" />
											</div>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												options={this.empresas}
												element_id='form_empresa'
												element_name='empresa'
												label_text='Empresa'
											/>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												options={this.divisiones}
												element_id='form_division'
												element_name='division'
												label_text='División'
											/>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												options={this.departamentos}
												element_id='form_departamento'
												element_name='departamento'
												label_text='Departamento'
											/>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												options={this.puestos}
												element_id='form_puesto'
												element_name='puesto'
												label_text='Puesto'
											/>
										</div>
										<div className="col-sm-12 col-md-6">
											<SelectArray
												options={this.tipos}
												element_id='form_tipo'
												element_name='tipo'
												label_text='Tipo de usuario'
											/>
										</div>
										</Fragment>
										:
										<label>Cargando...</label>
										}
									</div>
								</div>
								<div className="card-footer text-right">
									<div className="col-sm-12">
										<button onClick={this.saveButton.bind(this)} className="btn btn-primary mr-1" type="button">Guardar</button>
										<button onClick={this.backButton.bind(this)} className="btn btn-light">Atrás</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
		)
	}
}