import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slick.css'
import { Fragment } from 'react';

export default class Tabulator extends React.Component {
	constructor(props) {
		super(props)
		this.user_data = this.props.user_data
		this.Clima = this.props.Clima
	}

	render() {
		const capitalizar = (x) => {
			return x.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
		}

		const settings = {
			className: "center",
			autoplay: false,
			centerMode: true,
			speed: 500,
			autoplaySpeed: 5000,
			arrows: true,
			centerPadding: "0px",
			slidesToShow: 1,
			slidesToScroll: 1
		};
		return (

			<Slider {...settings}>
				{/* 

					<div className="weather-widget-two mt-2">
						<div className="card-body">
							<div className="top-bg-whether">
								<div className="whether-content">
									<span>Tampico, Tam.</span>
									<h6 className="num mb-0">{this.user_data.TAMPICO_HOY}°C</h6>
								</div>
							</div>
							<div className="media">
								{this.Clima(this.user_data.TAMPICO_DES)}
								<div className="media-body align-self-center">
									<h6 className="num mb-0">{this.user_data.TAMPICO_HOY_MAX}°C - {this.user_data.TAMPICO_HOY_MIN}°C</h6>
									<p className="m-0 f-14">Hoy</p>
								</div>
							</div>
							<div className="media">
							{this.Clima(this.user_data.TAMPICO_DES_MAN)}
								<div className="media-body align-self-center">
									<h6 className="m-0 f-w-600 num">{this.user_data.TAMPICO_MAN_MAX}°C - {this.user_data.TAMPICO_MAN_MIN}°C</h6>
									<p className="m-0 f-14">Mañana</p>
								</div>
							</div>
						</div>
					</div>
					*/}
				<div className="weather-widget-two mt-2">
					<div className="card-body bg-color">
						<div className="top-bg-whether">
							<div className="whether-content">
								<h6 className="num m-20">Hoy</h6>
							</div>
						</div>
						<div className="d-flex">
							{this.Clima(this.user_data.TAMPICO_DES)}
							<div className="whether-content align-self-center">
								<h6 className="num mb-0" style={{ color: "#c1452c" }}>{this.user_data.TAMPICO_HOY}°C</h6>
								<span>Tampico, Tam.</span>

							</div>
						</div>
						<div className="d-flex">
							{this.Clima(this.user_data.MTY_DES)}
							<div className="whether-content align-self-center">
								<h6 className="num mb-0" style={{ color: "#c1452c" }}>{this.user_data.MTY_HOY}°C</h6>
								<span>Monterrey, NL.</span>

							</div>
						</div>
						<div className="d-flex">
							{this.Clima(this.user_data.CDMX_DES)}
							<div className="whether-content align-self-center">
								<h6 className="num mb-0" style={{ color: "#c1452c" }}>{this.user_data.CDMX_HOY}°C</h6>
								<span>Ciudad de México, DF.</span>

							</div>
						</div>
					</div>

				</div>

				<div className="weather-widget-two mt-2">
					<div className="card-body bg-color">
						<div className="top-bg-whether">
							<div className="whether-content">
								<h6 className="num m-20">Mañana</h6>
							</div>
						</div>
						<div className="d-flex">
							{this.Clima(this.user_data.TAMPICO_DES_MAN)}
							<div className="whether-content align-self-center">
							<h6 className="m-0 f-w-600 num" style={{ color: "#c1452c" }}>{this.user_data.TAMPICO_MAN_MAX}°C - {this.user_data.TAMPICO_MAN_MIN}°C</h6>
								<span>Tampico, Tam.</span>

							</div>
						</div>
						<div className="d-flex">
							{this.Clima(this.user_data.MTY_DES_MAN)}
							<div className="whether-content align-self-center">
							<h6 className="m-0 f-w-600 num" style={{ color: "#c1452c" }}>{this.user_data.MTY_MAN_MAX}°C - {this.user_data.MTY_MAN_MIN}°C</h6>
								<span>Monterrey, NL.</span>

							</div>
						</div>
						<div className="d-flex">
							{this.Clima(this.user_data.CDMX_DES_MAN)}
							<div className="whether-content align-self-center">
							<h6 className="m-0 f-w-600 num" style={{ color: "#c1452c" }}>{this.user_data.CDMX_MAN_MAX}°C - {this.user_data.CDMX_MAN_MIN}°C</h6>
								<span>Ciudad de México, DF.</span>

							</div>
						</div>
					</div>

				</div>


				{/* <div className="weather-widget-two mt-2">
					<div className="card-body">
						<div className="top-bg-whether">
							<div className="whether-content">
								<span>Monterrey, NL.</span>
								<h6 className="num mb-0">{this.user_data.MTY_HOY}°C</h6>
							</div>
						</div>
						<div className="media">
							{this.Clima(this.user_data.MTY_DES)}
							<div className="media-body align-self-center">
								<h6 className="num mb-0">{this.user_data.MTY_HOY_MAX}°C - {this.user_data.MTY_HOY_MIN}°C</h6>
								<p className="m-0 f-14">Hoy</p>
							</div>
						</div>
						<div className="media">
							{this.Clima(this.user_data.MTY_DES_MAN)}
							<div className="media-body align-self-center">
								<h6 className="m-0 f-w-600 num">{this.user_data.MTY_MAN_MAX}°C - {this.user_data.MTY_MAN_MIN}°C</h6>
								<p className="m-0 f-14">Mañana</p>
							</div>
						</div>
					</div>
				</div>

				<div className="weather-widget-two mt-2">
					<div className="card-body">
						<div className="top-bg-whether">
							<div className="whether-content">
								<span>Ciudad de México, D.F.</span>
								<h6 className="num mb-0">{this.user_data.CDMX_HOY}°C</h6>
							</div>
						</div>
						<div className="media">
							{this.Clima(this.user_data.CDMX_DES)}
							<div className="media-body align-self-center">
								<h6 className="num mb-0">{this.user_data.CDMX_HOY_MAX}°C - {this.user_data.CDMX_HOY_MIN}°C</h6>
								<p className="m-0 f-14">Hoy</p>
							</div>
						</div>
						<div className="media">
							{this.Clima(this.user_data.CDMX_DES_MAN)}
							<div className="media-body align-self-center">
								<h6 className="m-0 f-w-600 num">{this.user_data.CDMX_MAN_MAX}°C - {this.user_data.CDMX_MAN_MIN}°C</h6>
								<p className="m-0 f-14">Mañana</p>
							</div>
						</div>
					</div>
				</div>
 */}
			</Slider >
		);
	}

}