import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';

const UserPanel = props => {
	const [url, setUrl] = useState();
	const [usuario, setUsuario] = useState(false);
	useEffect(() =>{
		if(!localStorage.getItem('id_usr')){
			window.location = '/login'
		}
		fetch('/usuario',{
			method: 'POST',
			body: JSON.stringify({ID: localStorage.getItem('id_usr')}),
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(res => res.json())
		.then(res => {
			//console.log(res);
			setUsuario(res.result[0])
		})
		.catch(err => {
			console.log('Error sidebar/userPanel:', err);
			setUsuario(false)
		})
	}, [])

	const readUrl = (event) => {
		if (event.target.files.length === 0)
			return;
		//Image upload validation
		var mimeType = event.target.files[0].type;

		if (mimeType.match(/image\/*/) == null) {
			return;
		}
		// Image upload
		var reader = new FileReader();

		reader.readAsDataURL(event.target.files[0]);
		reader.onload = (_event) => {
			setUrl(reader.result)
		}
	}

	return (
		<Fragment>
			<div className="sidebar-user text-center">
				{
				usuario ?
				<Fragment>
				<div>
					<img className="img-100 rounded-circle lazyloaded blur-up" src={`/image?img=${usuario.FOTOGRAFIA!=null?(usuario.ID+usuario.FOTOGRAFIA):'avatar.png'}`} alt="Imagen de usuario"/>
					<div className="profile-edit">
						<Link to="/perfil">
							<Edit />
						</Link>
					</div>
				</div>
				<h6 className="mt-3 f-14">{usuario.NOMBRE+' '+usuario.APELLIDO_PATERNO+' '+usuario.APELLIDO_MATERNO}</h6>
				<p>{usuario.PUESTO}</p>
				</Fragment>
				:
				<h5>Cargando...</h5>
				}
			</div>
		</Fragment>
	);
};

export default UserPanel;