import React, { Fragment, useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

const Dropimage = props =>{
    const [modal,setModal]=useState(false);
    const [f,setF]=useState(false);
    const {imagen,onUpload}=props;
   
    if(!imagen.file){
        if(imagen.base){
            fetch(imagen.base).then(res => {
                res.arrayBuffer().then(buf => {
                  const file = new File([buf], imagen.name , { type: 'image/jpeg' })
                  onUpload({name:imagen.name,base:imagen.base,file:file})
                })
            })
        }
    }

    const handleChangeStatus = ({ meta, file }, status) => {
        if(status==='done')
            getImage(file);
        if(status==='removed'){
            onUpload({name:'',base:''});
        }
    }

    const getImage = file =>{
        var reader = new FileReader();
        reader.onloadend = function () {
            var b64 = reader.result;
            onUpload({name:file.name,base:b64,file:file})
        };
        reader.readAsDataURL(file);
    }
    
    return(
        <Fragment>
            {modal?
            <div>
                <div className="__react_modal_image__modal_container">
                    <div className="__react_modal_image__modal_content"
                         onClick={(e)=>{
                             if(e.target.className==='__react_modal_image__modal_content')
                                setModal(false)
                         }}
                    >
                        <div>
                            <img id="react-modal-image-img" 
                                 className="__react_modal_image__medium_img" 
                                 src={imagen.base}
                                 style={{transform: "translate3d(-50%, -50%, 0px) rotate(0deg)"}}
                                 alt=""/>
                        </div>
                    </div>
                    <div className="__react_modal_image__header">
                        <span className="__react_modal_image__icon_menu">
                            <a href={imagen.base} download={imagen.name}>
                                <svg fill="#ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </a>
                            <a onClick={()=>setModal(false)}>
                                <svg fill="#ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </a>
                        </span>
                        <span className="__react_modal_image__caption">
                            {imagen.name}
                        </span>
                    </div>
                </div>
            </div>
            :''}
            <Dropzone
                id={imagen.name}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                initialFiles={(imagen.file?[imagen.file]:[])}
                accept="image/*"
                inputContent="Imagen"
                maxSizeBytes={props.maxSize || 1843200}
                ref={(e)=>{
                    try{
                        e.dropzone.current.childNodes[0].childNodes[0].onclick=(()=>{
                            setModal(true)
                        })
                    }
                    catch{}
                    
                    if(e){
                        if(e.files.length>0){
                            if(imagen.name!==''){
                                if(e.files[0].file.name!==imagen.name){
                                    e.files=[];
                                    setF(!f)
                                }
                            }
                            else{
                                if(imagen.name===''){
                                    e.files=[];
                                    setF(!f)
                                }
                            }
                        }
                    }
                }}
                
            />
        </Fragment>
    )
}

export default Dropimage;