import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import SelectFetch from '../common/select_fetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmpresasEditar = (props) => {

	const [empresa, setEmpresa] = useState([]);
	const [ready, setReady] = useState(false);

	useEffect(() => {
		fetch('/z_empresas', {
			method: 'POST',
			body: JSON.stringify({id: props.match.params.id}),
			headers: {'Content-Type': 'application/json'}
		})
		.then( res => res.json() )
		.then((res) => {
			console.log(res);
			if(res.status === 'OK'){
				setEmpresa(res.result[0])
				setReady(true)
			}else{
				console.log('ERROR')
			}
		})
		.catch((err) => {
			console.log('ERROR: '+err)
		})
		// eslint-disable-next-line
	}, []);

	const saveButton=()=>{
		let form_data = new FormData(document.getElementById('form_empresas'))

		fetch('/p_empresas', {
			method: 'PUT',
			body: JSON.stringify({
				id: props.match.params.id,
				nombre_interno: form_data.get('nombre_interno'),
				razon_social: form_data.get('razon_social'),
				calle: form_data.get('calle'),
				numero: form_data.get('numero'),
				colonia: form_data.get('colonia'),
				ciudad: form_data.get('ciudad'),
				codigo_postal: form_data.get('codigo_postal'),
				estado: form_data.get('estado')
			}),
			headers: {'Content-Type': 'application/json'}
		})
		.then(res => res.json())
		.then(res => {
			if(res.status === 'OK'){
				console.log('Actualizado');
				toast.success("Actualizado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else{
				toast.warn("No se pudo actualizar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
			}
		})
		.catch(err => {
			console.log('Error: '+err);
			toast.warn("Ha ocurrido un error, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
		})
	}

	const backButton=()=>props.history.push('/empresas')

	return (
		<Fragment>
			<Breadcrumb title="Editar" parent="Empresas" />
			<div className="container-fluid">
				<div className="row">
				<div className="col-sm-12">
					<div className="card">
						<div className="card-header">
							<h5>Datos de la empresa</h5>
						</div>
						<form id="form_empresas" className="form theme-form">
						<div className="card-body">
						{ready === false ? <h5>Cargando...</h5>:
							<div className="row">
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Nombre interno: </label>
										<input id="form_nombre_interno" name="nombre_interno" className="form-control" type="text" required  defaultValue={empresa.NOMBRE_INTERNO}/>
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Razón social: </label>
										<input id="form_razon_social" name="razon_social" className="form-control" type="text" required  defaultValue={empresa.RAZON_SOCIAL}/>
									</div>
								</div>
								<div className="col-12 col-md-8">
									<div className="form-group">
										<label className="form-label">Calle: </label>
										<input id="form_calle" name="calle" className="form-control" type="text" required  defaultValue={empresa.CALLE}/>
									</div>
								</div>
								<div className="col-12 col-md-4">
									<div className="form-group">
										<label className="form-label">Número: </label>
										<input id="form_numero" name="numero" className="form-control digits" type="number" required  defaultValue={empresa.NUMERO}/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label className="form-label">Colonia: </label>
										<input id="form_colonia" name="colonia" className="form-control" type="text" required  defaultValue={empresa.COLONIA}/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label className="form-label">Código postal: </label>
										<input id="form_codigo_postal" name="codigo_postal" className="form-control digitsrequired " type="number"  defaultValue={empresa.CODIGO_POSTAL}/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label className="form-label">Ciudad: </label>
										<input id="form_ciudad" name="ciudad" className="form-control" type="text" required  defaultValue={empresa.CIUDAD}/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<SelectFetch 
										element_id='form_estado'
										element_name='estado'
										label_text='Estados'
										default_selected={empresa.ESTADO}
										fetch_route='/estadoss'
									/>
								</div>
							</div>
						}
						</div>
						<div className="card-footer">
							<div className="row">
								<div className="col-12">
									<div className="btn-showcase">
										<button onClick={saveButton} className="btn btn-primary mr-1" type="button">Guardar</button>
										<button onClick={backButton} className="btn btn-light txt-dark" type="button">Atrás</button>
									</div>
								</div>
							</div>
						</div>
					</form>
					</div>
				</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default EmpresasEditar;