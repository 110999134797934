import React, { Fragment } from 'react';
import SunEditor from 'suneditor-react';
import lang from 'suneditor/src/lang'
import CodeMirror from 'codemirror'
import 'suneditor/dist/css/suneditor.min.css';
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/lib/codemirror.css'
import 'react-dropzone-uploader/dist/styles.css';

const options = {
  lang:lang.es,
  height: 200,
  mode: "classic",
  codeMirror:CodeMirror,
    katex: "window.katex",
    videoFileInput: false,
    tabDisable: false,
    buttonList: [
      ["fullScreen"],
      ["undo","redo"],
      ["font",/* "fontSize", */"formatBlock"],
      ["bold","italic","underline","strike","fontColor","hiliteColor","textStyle","removeFormat"],
      ["paragraphStyle","blockquote","outdent","indent","align","list","lineHeight","horizontalRule"],
      ["table","link","image"],
      ["preview","showBlocks","codeView"]
  ]
};

let elemts=[]

const Seccion = props =>{
  const {elmt,set} = props;
  elemts = props.elemts;
 
  let handleChange = (content) =>{
    elemts[elmt.order].data.code=content;
    set('elemts',elemts);
  }

  return(
    <Fragment>
      <SunEditor 
        setOptions={options}
        setContents={elmt.data.code?elmt.data.code:''}
        onChange={handleChange}
      />
      <br/>
    </Fragment>
  )
}

export default  Seccion;