import React,{Fragment} from 'react';
import {Link} from 'react-router-dom'
import { AlertCircle } from 'react-feather';

export default class Notificacion extends React.Component{
	constructor(props){
		super(props)
		console.log(this.props.history);
		this.state = {
			notificaciones: [],
			ready: false
		}
		this.refreshNotifications()
	}

	refreshNotifications(){
		fetch('/comunicados_usuarios',{
			method: 'POST',
			body: JSON.stringify({id: localStorage.getItem('id_usr')}),
			headers: { 'Content-Type': 'application/json' }
		})
		.then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status === 'OK'){
				this.setState(s => ({ready: true, notificaciones: res.result}))
			}else{
				this.setState(s => ({ready: true, notificaciones: []}))
			}
		})
	}

	render(){
		return (
			<Fragment>
				<div>
					{
					this.state.notificaciones.length > 0 ?
					<div className="media align-items-center">
						<div className="dotted-animation">
							<span className="animate-circle"></span>
							<span className="main-circle"></span>
						</div>
					</div>
					: ''
					}
					<ul className="notification-dropdown onhover-show-div p-0">
						{
						<li>Notificaciones <span className="badge badge-pill badge-primary pull-right">{this.state.ready ===  true ? this.state.notificaciones.length : 0}</span></li>
						}
						{this.state.ready === true ?
						<Fragment>
						{
						this.state.notificaciones.map(elem => 
						<li key={elem.ID}>
							<div className="media">
								<div className="media-body">
									<h6 className="mt-0 txt-danger"><span><AlertCircle /></span>
									<Link onClick={() => {
										this.setState(s => ({ready: false}))
										this.refreshNotifications()
										}} to={'/comunicados/comunicado/'+elem.ID}>
									{elem.TITULO}
									</Link></h6>
									<p className="mb-0">{elem.FECHA_PUBLICACION}</p>
								</div>
							</div>
						</li>
						)
						}
						</Fragment>
						:
						''}
					</ul>
				</div>
			</Fragment>
		);
	}
}
/* const Notification = props => {
};

export default Notification; */