import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';

const Comunicado = (props) => {

    const [comunicado, setComunicado] = useState(0);
    const [archivos_adjuntos, setArchivos_adjuntos] = useState([]);
    const [id_comunicado] = useState(props.match.params.id);

    useEffect(() => {
        fetch('/p_comunicados_usuarios?id_comunicado=' + id_comunicado + '&id_usuario=' + localStorage.getItem('id_usr'))
            .then((res) => res.json())
            .then((res) => {
                if (res.status === 1) {
                    setComunicado(res.result[0][0])
                    setArchivos_adjuntos(res.result[1])
                } else { console.log('ERROR') }
            })
            .catch((err) => { console.log('ERROR: ' + err) })
            // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Breadcrumb title="Detalles de comunicado" parent="Comunicados" />
            <div className="container-fluid">
                <div className="row timeline-custom">
                    <div className="col-12">
                        {comunicado === 0 ? <h1>Cargando...</h1> : (
                            <div className="card">
                                <div className="card-header">
                                    <h5>{comunicado.TITULO}</h5>
                                </div>
                                <div className="card-body">

                                    <div className="email-wrapper">
                                        {
                                            !!comunicado.PORTADA &&
                                            <div className="text-center">
                                                <img className="img-fluid mx-auto mb-3" src={`/z_comunicado_portada?ID=${comunicado.ID}&PORTADA=${comunicado.PORTADA}`} />
                                            </div>
                                        }

                                        <p><strong>Fecha: </strong>{comunicado.FECHA_PUBLICACION}</p>
                                        <p><strong>De: </strong>{comunicado.REMITENTE}</p>
                                        <p><strong>Para: </strong>{comunicado.DESTINATARIO}</p>
                                        <p><strong>Asunto: </strong>{comunicado.ASUNTO}</p>
                                        <p className="m-b-20"><strong>Mensaje: </strong></p>
                                        <div className="m-4" dangerouslySetInnerHTML={{ __html: comunicado.DESCRIPCION }} />
                                        <hr />
                                        {archivos_adjuntos.length < 1 ? <h6>Sin archivos ajuntos</h6> :
                                            <div>
                                                <div className="d-inline-block">
                                                    <h6 className="text-muted">
                                                        <i className="icofont icofont-clip"></i>Archivos adjuntos
                                                </h6>
                                                    <div className="d-flex">
                                                        <a className="text-muted text-right right-download" href={"/z_comunicados/archivos?id=" + id_comunicado + "&usr=" + localStorage.getItem('id_usr')} target="_blank">
                                                            <i className="icon fa fa-long-arrow-down mr-2"></i>
                                                        Descargar todos
                                                    </a>
                                                        <div className="attachment">
                                                            <ul className="list-inline">
                                                                {archivos_adjuntos.map((archivo, key) => {
                                                                    console.log(archivo);
                                                                    return (
                                                                        <li key={key} className="list-inline-item">
                                                                            <a className="text-muted text-right right-download" href={"/z_comunicados/archivo?id=" + archivo.ID_COMUNICADO + "&archivo=" + archivo.ID} target="_blank"><i className="icon fa fa-long-arrow-down mr-2"></i>{archivo.ARCHIVO_RUTA}</a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Comunicado;
