import React, { Fragment } from 'react';

export default class SelectArray extends React.Component{
	constructor(props){
		super(props)
		this.id = this.props.element_id
		this.name = this.props.element_name
		this.default = this.props.default_selected
		this.options = this.props.options
		this.label = this.props.label_text
		console.log(this.props.function_change);
	}

	render(){
		return (
		<Fragment>
			<div className="form-group">
				<label className="form-label">{this.label}</label>
				<select id={this.id} name={this.name} defaultValue={this.default} className="form-control btn-square" onChange={this.props.function_change}>
					{/* <option value="0">Seleccione</option> */}
					{
						this.options.map((elem) => <option value={elem.ID} key={elem.ID}>{elem.NOMBRE}</option>)
					}
				</select>
			</div>
		</Fragment>
		)
	}
}