import React from "react";

export default function SVGFile() {
  return (
    <svg
        version="1.1"
        id="cloudHailAltFill"
        className="climacon climacon_cloudHailAltFill"
        viewBox="15 15 70 70">
        <g className="climacon_iconWrap climacon_iconWrap-cloudHailAltFill">
            <g className="climacon_wrapperComponent climacon_wrapperComponent-hailAlt">
                <g className="light-blue-fill climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-left">
                    <circle cx="42" cy="65.498" r="2" />
                </g>
                <g className="light-blue-fill climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-middle">
                    <circle cx="49.999" cy="65.498" r="2" />
                </g>
                <g className="light-blue-fill climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-right">
                    <circle cx="57.998" cy="65.498" r="2" />
                </g>
                <g className="light-blue-fill climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-left">
                    <circle cx="42" cy="65.498" r="2" />
                </g>
                <g className="light-blue-fill climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-middle">
                    <circle cx="49.999" cy="65.498" r="2" />
                </g>
                <g className="light-blue-fill climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-right">
                    <circle cx="57.998" cy="65.498" r="2" />
                </g>
            </g>
            <g className="climacon_componentWrap climacon_componentWrap_cloud">
                <path
                    className="blue-fill climacon_component climacon_component-stroke climacon_component-stroke_cloud"
                    d="M43.945,65.639c-8.835,0-15.998-7.162-15.998-15.998c0-8.836,7.163-15.998,15.998-15.998c6.004,0,11.229,3.312,13.965,8.203c0.664-0.113,1.338-0.205,2.033-0.205c6.627,0,11.998,5.373,11.998,12c0,6.625-5.371,11.998-11.998,11.998C57.168,65.639,47.143,65.639,43.945,65.639z" />
                <path
                    className="blue-fill climacon_component climacon_component-fill climacon_component-fill_cloud"
                    fill="#FFFFFF"
                    d="M59.943,61.639c4.418,0,8-3.582,8-7.998c0-4.417-3.582-8-8-8c-1.601,0-3.082,0.481-4.334,1.291c-1.23-5.316-5.973-9.29-11.665-9.29c-6.626,0-11.998,5.372-11.998,11.999c0,6.626,5.372,11.998,11.998,11.998C47.562,61.639,56.924,61.639,59.943,61.639z" />
            </g>
        </g>
    </svg>

);
}