import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Masonry from 'react-masonry-css';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const MesonryDesc = () => {

	const [datos, setDatos] = useState([]);
	const [activate, setactivate] = useState(false);
	const [pagina, setPagina] = useState(1);
	const [paginas, setPaginas] = useState([]);
	const [cantidad] = useState(9);
	const [total, setTotal] = useState(0);


	useEffect(() => {
		setactivate(false)
		const obtenerListaCotidiana=()=>{
			fetch('/z_lista_notas_cotidianas', {
				method: 'POST',
				body: JSON.stringify({ID: localStorage.getItem('id_usr'),indice:((pagina - 1) * cantidad)}),
				headers: {'Content-Type': 'application/json'}
			})
			.then(res => res.json())
			.then(res => {
				window.scrollTo(0,1000)
				let i,fin, total_tmp=Math.ceil(res.result[1][0].TOTAL/cantidad)
				
				let paginas_tmp=[]

				if(pagina - 2 < 1){
					i = 1
					fin = 5
				}else{
					i = pagina - 2
					fin = pagina + 2
				}

				if(pagina + 2 > total_tmp){
					fin = total_tmp
				}
				
				for(i; i <= fin; i++){
					paginas_tmp.push(i)
				}

				setTotal(total_tmp)
				setPaginas(paginas_tmp)
				setDatos(res.result[0])
				setactivate(true)
			})
			.catch(err => {
				console.log('Error: '+err);
				setactivate(true)
			})
		}
		obtenerListaCotidiana()
		// eslint-disable-next-line
	}, [pagina]);

	const boton_like=(id_nota)=>{
		var datos_temp=datos
		datos_temp[datos.findIndex(obj => obj.ID===id_nota)]['LIKE']=datos_temp[datos.findIndex(obj => obj.ID===id_nota)]['LIKE']?0:1
		datos_temp[datos.findIndex(obj => obj.ID===id_nota)]['ME_GUSTA']=datos_temp[datos.findIndex(obj => obj.ID===id_nota)]['LIKE']?(datos_temp[datos.findIndex(obj => obj.ID===id_nota)]['ME_GUSTA']+1):(datos_temp[datos.findIndex(obj => obj.ID===id_nota)]['ME_GUSTA']-1)
		setDatos([...datos_temp])
		fetch('/z_lista_notas_cotidianas', {
			method: 'PUT',
			body: JSON.stringify({
				ID: localStorage.getItem('id_usr'),
				ID_NOTA: id_nota,
				MEGUSTA:datos_temp[datos.findIndex(obj => obj.ID===id_nota)]['LIKE'],
			}),
			headers: {'Content-Type': 'application/json'}
		})
	}

    const breakpointColumnsObj = {
        default: 3,
        1350: 2,
        720: 1,
        500: 1
	};

	const loadPage=page=>{
		console.log(page);
		setPagina(page)
	}
	
	const paginator=()=>{
		return (
			<Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-warning pagination-lg">
				{
					pagina !== 1 ?
					<Fragment>
						<PaginationItem>
							<PaginationLink first onClick={() => {loadPage(1)}} />
						</PaginationItem>
						<PaginationItem>
							<PaginationLink previous onClick={() => {loadPage(pagina - 1)}} />
						</PaginationItem>
					</Fragment>
					:
					<Fragment>
						<PaginationItem disabled>
							<PaginationLink first/>
						</PaginationItem>
						<PaginationItem disabled>
							<PaginationLink previous/>
						</PaginationItem>
					</Fragment>
				}
				{
					paginas.map((page) => 
					{
						return(
						pagina === page ? 
						<PaginationItem key={page} active>
							<PaginationLink>{page}</PaginationLink>
						</PaginationItem>:
						<PaginationItem key={page}>
							<PaginationLink onClick={() => {loadPage(page)}}>{page}</PaginationLink>
						</PaginationItem>)
					}	
					)
				}
				{
					total !== pagina ? 
					<Fragment>
						<PaginationItem>
							<PaginationLink next onClick={() => {loadPage(pagina + 1)}} />
						</PaginationItem>
						<PaginationItem>
							<PaginationLink last onClick={() => {loadPage(total)}} />
						</PaginationItem>
					</Fragment>:
					<Fragment>
						<PaginationItem disabled>
							<PaginationLink next/>
						</PaginationItem>
						<PaginationItem disabled>
							<PaginationLink last/>
						</PaginationItem>
					</Fragment>
				}
			</Pagination>
		)
	}

    return (
        <Fragment>
            <Breadcrumb parent="Cotidiano" title="Inicio"/>
            <div className="container-fluid">
				{
					activate ? 
					<div className="row">
                	    <div className="col-sm-12">
							<Masonry
								breakpointCols={breakpointColumnsObj}
								className="my-masonry-grid masonry-with-dec"
								columnClassName="my-masonry-grid_column"
							>
								{datos.map((x, i) =>
									<div key={x.ID} className="card">
										<li>
											<a href={`/notas_cotidianas/${x.ID}`}>
												<div className="card-image">
													<img src={x.PORTADA} style={{ width: '100%' }} alt="" />
													<div className="card-tags">
														<ul>
															{x.ETIQUETAS.split(',').map((elem, index) => {
																return index<3 && <li key={index}>#{elem}</li>
															})}
														</ul>
													</div>
												</div>
												<div className="caption">
													<h5 className="title-widget f-w-600 mb-3">{x.TITULO}</h5>
													<p>{x.CONTENIDO.substring(0, 165)+( x.CONTENIDO.length>165 ? '...' : '')}</p>
												</div>
											</a>
											<ul className="social-likes">
												<li><button type="button" onClick={()=>boton_like(x.ID)}><i className={x['LIKE']?'fa fa-thumbs-up':'fa fa-thumbs-o-up'}></i></button>{x.ME_GUSTA} Me gusta</li>
												<li className="px-3">{x.FECHA}</li>
											</ul>
										</li>
									</div>
								)}
							</Masonry>
                	    </div>
						<div className="card-body">
							{paginator()}
						</div>
                	</div>
					:
					<div className="d-flex justify-content-center align-items-center">
						<div className="loader-box mr-4">
							<div className="loader">
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
							</div>
						</div>
						<h5 className="text-uppercase">Cargando...</h5>
					</div>

				}
                
            </div>
        </Fragment>
    );
};

export default MesonryDesc;