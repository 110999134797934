import React, { Component } from 'react';
import {
	Carousel,
	CarouselItem,
	CarouselControl,
	CarouselIndicators,
	CarouselCaption
} from 'reactstrap';

/* const items = [
	{
		src: require('../../../assets/images/c1.jpg'),
		altText: 'Slide 1',
		caption: 'Slide 1'
	},
	{
		src: require('../../../assets/images/c2.jpg'),
		altText: 'Slide 2',
		caption: 'Slide 2'
	},
	{
		src: require('../../../assets/images/c3.jpg'),
		altText: 'Slide 3',
		caption: 'Slide 3'
	}
]; */

class CarouselExample extends Component {
	constructor(props) {
		super(props);
		this.items = this.props.items
		this.state = { activeIndex: 0 };
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.goToIndex = this.goToIndex.bind(this);
		this.onExiting = this.onExiting.bind(this);
		this.onExited = this.onExited.bind(this);
		console.log(this.items);
	}

	onExiting() {
		this.animating = true;
	}

	onExited() {
		this.animating = false;
	}

	next() {
		if (this.animating) return;
		const nextIndex = this.state.activeIndex === this.items.length - 1 ? 0 : this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex });
	}

	previous() {
		if (this.animating) return;
		const nextIndex = this.state.activeIndex === 0 ? this.items.length - 1 : this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex });
	}

	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex });
	}

	render() {
		const { activeIndex } = this.state;

		const slides = this.items.map((item) => {
			return (
				<CarouselItem
					onExiting={this.onExiting}
					onExited={this.onExited}
					key={item.src}
				>
					<img onClick={item.redirect} className="img-fluid w-100" src={item.src} alt={item.altText} />
					<a href="#" onClick={item.redirect}>
						<CarouselCaption captionText={item.captionText} captionHeader={item.captionHeader} />
					</a>
				</CarouselItem>
			);
		});

		return (
			<Carousel
				activeIndex={activeIndex}
				next={this.next}
				previous={this.previous}
			>
				<CarouselIndicators items={this.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
				{slides}
				<CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
				<CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
			</Carousel>
		);
	}
}


export default CarouselExample;