import React, { Fragment } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Icono from '../../assets/images/ilustraciones/calendario/calendario.png';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import mujer from '../../assets/images/ilustraciones/calendario/calendario-mujer.png'
import brazoMujer from '../../assets/images/ilustraciones/calendario/calendario-brazo-mujer.png'
import burbuja from '../../assets/images/ilustraciones/calendario/calendario-burbuja.png'
import hombre from '../../assets/images/ilustraciones/calendario/calendario-hombre.png'
import hombreCentro from '../../assets/images/ilustraciones/calendario/calendario-hombre-centro.png'
import hombreIzquierda from '../../assets/images/ilustraciones/calendario/calendario-hombre-izquierda.png'
import planta from '../../assets/images/ilustraciones/calendario/calendario-planta.png'
import mesa from '../../assets/images/ilustraciones/calendario/calendario-mesa.png'
import board from '../../assets/images/ilustraciones/calendario/calendario-board.png'
import sombra from '../../assets/images/ilustraciones/calendario/calendario-sombra.png'
import bgShape from '../../assets/images/ilustraciones/noticias/noticias-bg.png'

export default class Comunicado extends React.Component{

	redirectPublicaciones(){
		this.props.history.push('/calendario/lista')
	}

	render(){
		return (
		<Fragment>
			<div className="authentication-main p-0 container-fluid">
				<div className="row">
					<div className="col-sm-12 col-md-6 col-lg-8 bg-ilustracion">
						<div className="animation-wrapper">
							<div className="center z2 text-center">
								<Bounce duration={1400} right>
									<img className="img-fluid" src={mujer} alt=""/>
								</Bounce>
							</div>
                            <div className="center z4 text-center">
								<Bounce duration={1400} right>
									<img className="img-fluid" src={brazoMujer} alt=""/>
								</Bounce>
							</div>
							<div className="center z3 text-center">
								<Bounce duration={1400}>
									<img className="img-fluid" src={burbuja} alt=""/>
								</Bounce>
							</div>
							<div className="center z2 text-center">
								<Bounce duration={1400}>
									<img className="img-fluid" src={hombre} alt=""/>
								</Bounce>
							</div>
							<div className="center z4 text-center">
								<Bounce duration={1400} bottom>
									<img className="img-fluid" src={hombreCentro} alt=""/>
								</Bounce>
							</div>
                            <div className="center z3 text-center">
								<Bounce duration={1400} left>
									<img className="img-fluid" src={hombreIzquierda} alt=""/>
								</Bounce>
							</div>
                            <div className="center z3 text-center">
								<Bounce duration={1400}>
									<img className="img-fluid" src={mesa} alt=""/>
								</Bounce>
							</div>
                            <div className="center z1 text-center">
								<Bounce duration={1600} top>
									<img className="img-fluid" src={planta} alt=""/>
								</Bounce>
							</div>
                            <div className="center z1 text-center">
								<Bounce duration={1400} bottom>
									<img className="img-fluid" src={sombra} alt=""/>
								</Bounce>
							</div>
                            <div className="center z1 text-center">
								<Bounce duration={500} top>
									<img className="img-fluid" src={board} alt=""/>
								</Bounce>
							</div>
							<div className="center-animation bg-shape">
								<Fade>
									<img className="img-fluid" src={bgShape} alt=""/>
								</Fade>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-6 col-lg-4">
						<div className="auth-innerright">
							<div className="authentication-box p-4">
								<div className="row">
									<div className="col-6 col-md-6">
										<img className="img-fluid" src={Icono} alt=""></img>
										<h3 className="text-uppercase f-w-600 txt-primary">Calendario de Eventos</h3>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-12 col-lg-7">
										<p className="text-dark">
										Consulta el calendario, en el encontrarás las fechas de los eventos internos de la empresa, reuniones, juntas, cumpleaños, etc.
										</p>
										<button className="btn btn-pill btn-warning btn-sm mx-auto" onClick={this.redirectPublicaciones.bind(this)}>Ver más</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)};
}