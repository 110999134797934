import React, { Fragment } from 'react';

//CUSTOM COMPONENTS
import Dropimage from '../../common/dropimage';

let lock = true;

const Galeria = props =>{
  const {elmt,set} = props;
  let {elemts} = props;

  if(!elmt.data.list){
    elemts[elmt.order].data={list:[{order:0,active:false,image:{name:'',base:''},des:''}]};
    set('elemts',elemts);
  }

  var list=elmt.data.list;

  const add = () =>{
    list.push({order:list.length,active:false,image:{name:'',base:''},des:''});
    elemts[elmt.order].data.list=list;
    set('elemts',elemts);
  }

  const eliminar = item =>{
    list.splice(item.order,1);
    list.map((e,i)=>list[i].order=i);
    elemts[elmt.order].data.list=list;
    set('elemts',elemts);
  }

  const subir = item =>{
    if(lock){
      lock=false;
      let a = list[item.order-1];
      list[item.order-1]=list[item.order];
      list[item.order]=a;
      list.map((e,i)=>list[i].order=i);
      elemts[elmt.order].data.list=list;
      set('elemts',elemts);
      setTimeout( ()=> lock=true,500);
    }
  }

  const bajar = item =>{
    if(lock){
      lock=false;
      let a = list[item.order+1];
      list[item.order+1]=list[item.order];
      list[item.order]=a;
      list.map((e,i)=>list[i].order=i);
      elemts[elmt.order].data.list=list;
      set('elemts',elemts);
      setTimeout( ()=> lock=true,500);
    }
  }

  const setItem = item =>{
    list[item.order]=item;
    elemts[elmt.order].data.list=list;
    set('elemts',elemts);
  }

  return (
    <Fragment>
      
      <div className="card-block row">
          <div className="col-sm-12 col-lg-12 col-xl-12">
            
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  
                {list.map((e,i)=>{
                  return(<Item key={e.order} subir={subir} bajar={bajar} top={i>0} end={i<(list.length-1)} item={e} eliminar={eliminar} set={setItem} eliminable={list.length>1?true:false}/>)})}
                </tbody>
              </table>
            </div>
          
          </div>
      </div>
      <div className="bg-success text-center" onClick={add} style={{cursor:'pointer', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', height:'45px'}}>
        <h3 className="mt-2 py-2 h5"><strong>Agregar</strong> <small><i className="fa fa-plus"></i></small></h3>
      </div>
    
    </Fragment>
  );
}

const Item = props =>{
  const {item,eliminar,set,eliminable} = props;
  return(
    <Fragment>
      <tr>
        <td>
          <div className="col-sm-12">
            <div className="row">
              
              <div className="col-md-4">
                {/* INPUT IMAGEN*/}
                <label>Imagen</label>
                <Dropimage
                  id={(item.image.name+'').replace('.png')}
                  imagen={item.image}
                  onUpload={val=>{
                    item.image=val;
                    item.active=true;
                    if(val.base==='')
                      item.active=false;
                    set(item);
                  }}
                />
              </div>

              <div className="col-md-8">
                <div className="form-row" >
                  {/* INPUT DESCRIPCION */}
                  <div className="col-md-12 mb-3">
                    <label>Descripción</label>
                    <input 
                      className="form-control" 
                      name="descripcion" 
                      type="text" 
                      autoComplete="off" 
                      placeholder="Descripción..."
                      disabled={!item.active}
                      value={item.des}
                      onChange={e=>{
                        item.des=e.target.value;
                        set(item);
                      }}
                    />
                  </div>
                </div>
                <div className="form-row" >
                  <div className="col-md-12 mb-3">
                    <div className="blockquote text-right mb-0">
                        {props.top?
                        <button 
                          className="btn btn-success text-right mr-2"
                          onClick={()=>props.subir(item)}
                          disabled={!props.top}
                        >
                          &#8896;
                        </button>:''}
                        {props.end?
                        <button 
                          className="btn btn-success text-right mr-2"
                          onClick={()=>props.bajar(item)}
                          disabled={!props.end}
                        >
                          &#8897;
                        </button>:''}
                        <button 
                          className="btn btn-danger text-right"
                          onClick={()=>eliminar(item)}
                          disabled={!eliminable}
                        >
                          Remover
                        </button>
                    </div>
                  </div>
                </div>
              </div>
                          
            </div>
          </div>
        </td>
      </tr>
    </Fragment>
  )
}

export default Galeria;