import React from "react";
import ProInternet from "./icono";

const Footer = props => {
    return (
    <footer className="footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 footer-copyright">
                    <p className="mb-0">Copyright 2020 | Todos los derechos reservados.</p>
                </div>
                <div className="col-md-6">
                    <p className="pull-right mb-0 logo-footer">Desarrollado por
                        <ProInternet/>
                    </p>
                </div>
            </div>
        </div>
    </footer>
)};

export default Footer;