import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DirectorioAgregar = (props) => {

	const saveButton=()=>{
		let form_data = new FormData(document.getElementById('form_directorio'))
		fetch('/p_directorio', {
			method: 'POST',
			body: form_data
		})
		.then(res => res.json())
		.then(res => {
			if(res.status === 'OK' && res.rows[0] > 0){
				document.getElementById('form_directorio').reset()
				toast.success("Agregado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else{
				toast.warn("No se pudo agregar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
			}
		})
		.catch(err => {
			console.log('Error: '+err);
			toast.warn("Ha ocurrido un error, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
		})
	}


	const backButton=()=>props.history.push('/directorio/inicio')

	return (
		<Fragment>
			<Breadcrumb title="Agregar" parent="Directorio" />
			<div className="container-fluid">
				<div className="row">
				<div className="col-sm-12">
					<div className="card">
						<div className="card-header">
							<h5>Datos del contacto</h5>
						</div>
						<form id="form_directorio" className="form theme-form">
						<div className="card-body">
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label className="form-label">Nombre: </label>
										<input id="form_nombre" name="NOMBRE" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Empresa: </label>
										<input id="form_nombre" name="EMPRESA" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Teléfono: </label>
										<input id="form_nombre" name="TELEFONO" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Celular: </label>
										<input id="form_nombre" name="CELULAR" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Correo: </label>
										<input id="form_nombre" name="CORREO" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Redes sociales: </label>
										<input id="form_nombre" name="REDES_SOCIALES" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Fotografía: </label>
										<input id="form_fotografia" name="fotografia" className="form-control" type="file" accept=".png,.jpg" />
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label className="form-label">Dirección del contacto</label>
										<input id="form_nombre" name="DIRECCION" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label className="form-label">Información del contacto: </label>
										<textarea id="form_nombre" name="INFORMACION" className="form-control" required />
									</div>
								</div>
							</div>
						</div>
						<div className="card-footer">
							<div className="row">
								<div className="col-12">
									<div className="btn-showcase">
										<button onClick={saveButton} className="btn btn-primary mr-1" type="button">Guardar</button>
										<button onClick={backButton} className="btn btn-light txt-dark">Atrás</button>
									</div>
								</div>
							</div>
						</div>
					</form>
					</div>
				</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default DirectorioAgregar;