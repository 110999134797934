import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tablereact from '../common/tabulator/tablereact'
import Contacto from './Contacto'

const Directorio = () => {
	const [directorio, setDirectorio] = useState(false);

	useEffect(() => {
		fetch('/z_directorio').then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status === 'OK'){
				setDirectorio(res.result[0].concat(res.result[1]))
			}else{
				console.log('Error');
			}
		})
		.catch(err => {console.log('Error: '+err);})
	}, []);

	return (
		<Fragment>
			<Breadcrumb parent="Directorio"/>
			<div className="card">
				<div className="container-fluid">
					{
						!directorio ?
						<div className="d-flex justify-content-center align-items-center">
							<div className="loader-box mr-4">
								<div className="loader">
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
								</div>
							</div>
							<h5 className="text-uppercase">Cargando...</h5>
						</div>
						:
						<div className="row">
							<div className="col-sm-12 mt-3">
								<Tablereact
    	                    		myData={directorio}
    	                    		pageSize={10}
    	                    		paginacion={true}
    	                    		buscador='Buscar'
									componente={{Component:Contacto,nombre:'Directorio'}}
    	                    	/>
							</div>
						</div>
					}
				</div>
			</div>
		</Fragment>
	);
}
 
export default Directorio;