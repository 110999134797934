import React , {useEffect, useState}from "react";
import './styles.css'

const Pagination = (props) => {

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };
  
  const getVisiblePages = (page, total) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };
  
  const changePage = (page) => {
    const activePage = props.page + 1;
    
    if (page === activePage) {
      return;
    }
    
    const visiblePages = getVisiblePages(page, props.pages);
    setVisiblePages(filterPages(visiblePages, props.pages))
    props.onPageChange(page - 1);
  }
  
const activePage = props.page + 1;
const [visiblePages, setVisiblePages] = useState(getVisiblePages(null, props.pages))
// console.log(props, props.pages)
useEffect(() => {
  // if (props.pages !== nextProps.pages) {
  //   // setVisiblePages(getVisiblePages(null, nextProps.pages))
  // }
  // changePage(nextProps.page + 1);
 
}, [props.count])
  // const { PageButtonComponent = defaultButton } = this.props;

  return (
    <div className="Table__pagination">
      <div className="Table__prevPageWrapper" style={{marginRight: 10}}>
        <select className="form-control digits" id="pages" multiple="" defaultValue="1" 
          onChange={(v) => {
            // props.pageSize = v.currentTarget.value
            // props.onPageChange(v.currentTarget.value)
            props.onPageSizeChange(v.currentTarget.value)
            setVisiblePages(getVisiblePages(props.page, props.pages))
            // changePage(activePage)
            console.log(getVisiblePages(props.page, props.pages), props)
        }}

        onClick={(v) => {
            // props.pageSize = v.currentTarget.value
            // props.onPageChange(v.currentTarget.value)
            props.onPageSizeChange(v.currentTarget.value)
            setVisiblePages(getVisiblePages(props.page, props.pages))
            // changePage(activePage)
            console.log(getVisiblePages(props.page, props.pages), props)
        }}
        >
          {props.pageSizeOptions.map((v) => {
            return <option key={v} value={v}>{v}</option>
          })}
        </select>
      </div>
      <div className="Table__prevPageWrapper">
        <button
          className="Table__pageButton btn btn-outline-dark"
          type='button'
          onClick={() => {
            if (activePage === 1) return;
            changePage(activePage - 1);
          }}
          disabled={activePage === 1}
        >
          Atras
        </button>
      </div>
      <div className="Table__visiblePagesWrapper">
        {visiblePages.map((page, index, array) => {
          return (
            <button
              key={page}
              type='button'
              className={
                activePage === page
                  ? "Table__pageButton Table__pageButton--active btn btn-outline-dark"
                  : "Table__pageButton btn btn-outline-dark"
              }
              onClick={(e) => changePage(page)}
            >
              {array[index - 1] + 2 < page ? `...${page}` : page}
            </button>
          );
        })}
      </div>
      <div className="Table__nextPageWrapper">
        <button
          className="Table__pageButton btn btn-outline-dark"
          type='button'
          onClick={() => {
            if (activePage === props.pages) return;
            changePage(activePage + 1);
          }}
          disabled={activePage === props.pages}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
  
}

export default Pagination