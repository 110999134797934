import React, {Fragment,useState,useEffect} from 'react';
import Breadcrumb from '../common/breadcrumb';
import CarouselTwo from '../common/carouselTwo';

const tags = {
	borderBottom: "1px solid #e5e5e5",
	borderTop: "1px solid #e5e5e5",
	padding: "20px 0"
}
const etiquetasA = {
	color: '#222',
	position: 'relative',
	padding: '5px 15px',
	display: 'block',
	fontWeight: '600',
}
const etiquetasLi = {
	display: 'inline-block',
	lineHeight: '33px',
	backgroundColor: '#f1f1f1',
	margin: '10px 5px'
}

const NotaCotidiana=(props)=>{
	const id = props.match.params.id;
	const [ready, setReady] = useState(false);
	const [active, setActive] = useState(false);
	const [datos, setDatos] = useState(false);
	const [nota, setNota] = useState({});

	//ELEMENTOS LISTA
	const [elemts, setElements] = useState(
		[{ "order": 0, "key": 0, "data": {}, "title": "Descripcion" }]
	);
	
	console.log(elemts)

	useEffect(() => {
		fetch('/z_mostrar_nota_cotidiana', {
			method: 'POST',
			body: JSON.stringify({ID: id}),
			headers: {'Content-Type': 'application/json'}
		})
		.then(res => res.json())
		.then(res => {
			console.log(res);
			setDatos(res.data.length ? true : false)
			setNota(res.data[0])
			setReady(true)

			const element_temp=[]
			let galerias = {};
			res.elemts.map(e=>{
				if(e.TIPO==="Galería"){
					let imagen ={
						src:e.IMAGEN,
    					altText: e.DESCRIPCION,
    					header: e.DESCRIPCION,
					}
					galerias['galeria_'+e.POSICION]={
						order:e.POSICION,
						key:1,
						data:{
						  list:galerias['galeria_'+e.POSICION]?
							galerias['galeria_'+e.POSICION].data.list=[...galerias['galeria_'+e.POSICION].data.list,imagen]
						  :
							[imagen]
						},
						title:"Galería"
					}
				}
				return e
			})
			Object.values(galerias).map(e=>element_temp.push(e));

			res.elemts.map(e=>{
				if(e.TIPO==="Video")
					element_temp.push({order:e.POSICION,key:2,data:{key:(e.TIPO_VIDEO==="YouTube"?0:1),title:e.TIPO_VIDEO,link:e.URL,code:e.CODE},title:"Video"})
				return e
			})
			setElements([...element_temp.sort((a, b)=>{
				if (a.order > b.order) {
				  return 1;
				}
				if (a.order < b.order) {
				  return -1;
				}
				return 0;
			})]);
			setActive(true)
		})
		.catch(err => {
			console.log('Error: '+err);
		})
		// eslint-disable-next-line
	}, []);

    return(
		<Fragment>
			<Breadcrumb title="Nota" parent="Cotidiano" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
								{ ready ? 
								datos ?
								<Fragment>
									<div className="card-header text-center">
										<h2 className="f-w-600 m-0">{nota.TITULO}</h2>
									</div>
									<div className="card-body">
										<div className="blog-single container">
											<div className="blog-box blog-details">
												<img className="img-fluid w-100" src={nota.PORTADA} alt="blog-main" />
												<div className="blog-details">
													<ul className="blog-social">
														<li className="digits"><i className="fa fa-clock-o"></i>{nota.FECHA_ALTA}</li>
														<li><i className="icofont icofont-user"></i>{nota.AUTOR}</li>
													</ul>
													<div className="mt-3" dangerouslySetInnerHTML={{__html: nota.CONTENIDO}} />
													<div className="row justify-content-center">
													{
														active &&
														elemts.map((x,i)=>{
															return x.title==="Galería" ?
															<div className="mt-4 col-11">
																<CarouselTwo galeria={x.data.list}/>
															</div>
															:
															<div className="mt-4 col-11">
																<div className="embed-responsive embed-responsive-16by9 text-center">
															  		<iframe title={i} className="embed-responsive-item text-center" src={x.data.code}></iframe>
																</div>
															</div>
														})
													}
													</div>
													<div>
														<p>
															Fuente de información: <a><em>{nota.FUENTE}</em></a>
														</p>
														<div className="tags" style={tags}>
															<h6 style={{display: 'inline-block',
																		marginRight: '20px'}}>Etiquetas:</h6>
															<ul style={{display: 'inline-block'}}>
																{
																	nota.ETIQUETAS.split(',').map((elem, index) => <li key={index} style={etiquetasLi}><a style={etiquetasA} href="#">{elem}</a></li>)
																}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Fragment>
								:
								<div className="card-body d-flex justify-content-center">
									<h5 className="text-uppercase">Sin contenido</h5>
								</div>
								:
								<div className="card-body d-flex justify-content-center align-items-center">
									<div className="loader-box mr-4">
										<div className="loader">
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
										</div>
									</div>
									<h5 className="text-uppercase">Cargando...</h5>
								</div>
								}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default NotaCotidiana;