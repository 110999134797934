import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tablereact from '../common/tabulator/tablereact'

const Usuarios = (props) => {

	const [usuarios, setUsuarios] = useState(false);

	useEffect(() => {
		fetch('/p_usuarios').then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status === 1){
				setUsuarios(res.result)
			}else{
				console.log('Error');
			}
		})
		.catch(err => {console.log('Error: '+err);})
	}, []);

	const cabecera = [
        { Header: <b>Id</b>, accessor: 'ID', style: { textAlign: 'center'}, width:'80', show:false },
        { Header: <b>Titulo</b>, accessor: 'NOMBRE', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
		{ Header: <b>Empresa</b>, accessor: 'EMPRESA', style: { textAlign: 'center'}, width:'150', editable:true , show:true},
		{ Header: <b>División</b>, accessor: 'DEPARTAMENTO', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
		{ Header: <b>Departamento</b>, accessor: 'DIVISION', style: { textAlign: 'center'}, width:'200', editable:true , show:true},
		{ Header: <b>Puesto</b>, accessor: 'PUESTO', style: { textAlign: 'center'}, width:'auto', editable:true , show:true}
	];

	const actualizar=(dato)=>props.history.push('/usuarios/editar/'+dato.ID)
	const agregar=()=>props.history.push('/usuarios/agregar')
	const eliminar=(dato)=>{
		fetch('/p_usuarios',{ method: 'DELETE', body: JSON.stringify({id: dato.ID}), headers: { 'Content-Type': 'application/json' }})
		.then( res => res.json() )
		.then( res => {
			if(res.status === 'OK'){
				setUsuarios(usuarios.filter(x=>x.ID!==dato.ID))
				toast.success("Se eliminó el registro", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else
				toast.warn("No se pudo borrar el registro", { position: toast.POSITION.BOTTOM_RIGHT })
		})
		.catch( (err) => {
			toast.error("Ha ocurrido un error", { position: toast.POSITION.BOTTOM_RIGHT }) 
		})
	}

	return (
	<Fragment>
		<Breadcrumb parent="Usuarios"/>
		<div className="card">
			<div className="container-fluid">
				{
					!usuarios ?
					<div className="d-flex justify-content-center align-items-center">
						<div className="loader-box mr-4">
							<div className="loader">
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
							</div>
						</div>
						<h5 className="text-uppercase">Cargando...</h5>
					</div>
					:
					<div className="row">
						<div className="col-sm-12 mt-3">
							<Tablereact
    	                    	myData={usuarios}
    	                    	cabecera={cabecera}
    	                    	pageSize={10}
    	                    	paginacion={true}
    	                    	buscador='Buscar'
    	                    	eliminar_actualizar={{eliminar,actualizar}}
    	                    	boton={{text:'Agregar',function:agregar}}
    	                    />
						</div>
					</div>
				}
			</div>
		</div>
	</Fragment>
	);
}
 
export default Usuarios;