import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CategoriasAgregar = ({view, setView}) => {

	const [nombre, setNombre] = useState('');

	useEffect(() => {
		if(view){
			fetch('/p_categorias', { method: 'PATCH', body: JSON.stringify({ id: view }), headers: { 'Content-Type': 'application/json' }})
				.then(res => res.json())
				.then(res => {
					setNombre(res.result.NOMBRE)
				})
				.catch(err => {console.log('Error: '+err);})
		}
		// eslint-disable-next-line
	}, []);

	const saveButton=(e)=>{
		e.preventDefault()

		if(nombre.trim()!==''){
			if(!view){
				fetch('/p_categorias', { method: 'POST', body: JSON.stringify({ nombre }), headers: { 'Content-Type': 'application/json' }})
				.then(res => res.json())
				.then(res => {
					if(res.status === 'OK' && res.rows[0] > 0){
						toast.success("Agregado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
						setView(-1)
					}
					else{
						toast.warn("No se pudo agregar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
					}
				})
				.catch(err => {console.log('Error: '+err);})
			}
			else{
				fetch('/p_categorias', { method: 'PUT', body: JSON.stringify({id: view, nombre}), headers: { 'Content-Type': 'application/json' }
				})
				.then( res => res.json() )
				.then( res => {
					if(res.status === 'OK'){
						toast.success("Se actualizó el registro", { position: toast.POSITION.BOTTOM_RIGHT })
						setView(-1)
					}
					else
						toast.warn("No se pudo borrar el registro", { position: toast.POSITION.BOTTOM_RIGHT })
				})
				.catch( (err) => {
					console.log(err);
					toast.error("Ha ocurrido un error", { position: toast.POSITION.BOTTOM_RIGHT })
				})
			}
		}
		else{
			toast.warn("Tienes campos vacíos", { position: toast.POSITION.BOTTOM_RIGHT })
		}
	}

	return (
		<Fragment>
			<Breadcrumb title={view ? "Actualizar" : "Agregar"} parent="Categorías" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<h5>Datos de categoría</h5>
							</div>
							<form className="form theme-form" onSubmit={saveButton}>
								<div className="card-body">
									<div className="row">
										<div className="col">
											<div className="form-group">
												<label>Nombre: </label>
												<div>
													<input className="form-control" type="text" value={nombre} onChange={e=>setNombre(e.target.value)} />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-6 col-sm-6 col-lg-6">
											<button onClick={()=>{setView(-1)}} className="btn btn-light" type="button">Atrás</button>
										</div>
										<div className="col-6 col-sm-6 col-lg-6 text-right">
											<button className="btn btn-primary mr-1" type="submit">Guardar</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default CategoriasAgregar;