import React from 'react';

export const AppContext = React.createContext({
    buscador: false,
    toggleBuscador: () => {},
});

export class AppContextProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            buscador: false,
        };

        this.toggleBuscador = this.toggleBuscador.bind(this);
    }

    toggleBuscador(x) {
        this.setState({ buscador: x});
    }

    render() {
        const {
            children,
        } = this.props;
        const {
            buscador,
        } = this.state;

        return (
            <AppContext.Provider
                value={{
                    buscador,
                    toggleBuscador: this.toggleBuscador,
                }}
            >
                {children}
            </AppContext.Provider>
        );
    }
}

export const AppContextConsumer = AppContext.Consumer;