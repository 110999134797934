import React, { Fragment } from 'react';
import Breadcrumb from "../common/breadcrumb";
import 'react-dropzone-uploader/dist/styles.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Comunicado extends React.Component{

	saveButton(){
		let form_data = new FormData(document.getElementById('form_festividades'))

		fetch('/p_festividades', {
			method: 'POST',
			body: form_data
		})
		.then(res => res.json())
		.then(res => {
			if(res.status === 'OK' && res.rows[0] > 0){
				document.getElementById('form_festividades').reset()
				toast.success("Agregado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else{
				console.log('No se pudo agregar');
			}
		})
		.catch(err => {console.log('Error: '+err);})
	}

	backButton = ()=> {
		this.props.history.push('/eventos')
	}

	mostrarFiltros(){
		console.log(this.filtro_empresas);
	}

	showFiles(){
		console.log(document.getElementById('form_archivos').files)
	}

	editorData(event){
		this.contenido_html = event.editor.getData()
	}

	render(){
		return (
		<Fragment>
			<Breadcrumb title="Agregar" parent="Eventos" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form id="form_festividades" className="form theme-form">
							<div className="card">
								<div className="card-header">
									<h5>Información del evento</h5>
								</div>
								<div className="card-body add-post">
										<div className="col-sm-12">
											<div className="form-group">
												<label className="form-label">Título:</label>
												<input className="form-control" name="TITULO" type="text" required="" />
											</div>
											<div className="form-group">
												<label className="form-label">Fecha:</label>
												<input className="form-control" name="FECHA" type="date" required="" />
											</div>
											<div className="form-group">
												<label className="form-label">Descripción: </label>
												<textarea name="DESCRIPCION" className="form-control" rows="5" />
											</div>
										</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-12">
											<div className="btn-showcase">
												<button onClick={this.saveButton.bind(this)} className="btn btn-primary mr-1" type="button">Guardar</button>
												<button onClick={this.backButton} className="btn btn-light txt-dark" type="button">Atrás</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	)};
}