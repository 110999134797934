import React, { Fragment, useEffect } from 'react';

const CerrarSesion = () => {

    useEffect(() => {
        setTimeout(() => {
            window.location.replace('/cerrar_sesion')
        }, 1500)
    }, []);
    
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="error-wrapper maintenance-bg">
                    <div className="container">
                        <div className="maintenance-heading">
                            <h2 className="headline" style={{fontSize: 42}}>Cerrando<br />sesión</h2>
                        </div>
                        <div className="mt-4">
                            <div className="loader">
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default CerrarSesion;