import React, {Fragment, useState, useEffect} from 'react';

const Contacto = (props) => {
	const [contacto, setContacto] = useState(props.dato);
	
	useEffect(() => {
		setContacto(props.dato)
	}, [props]);

	return (
		<div className="container-fluid mt-1">
			<div className="card mb-0 shadow-lg">
				<div className="card-body">
					<div className="job-search">
						{
							contacto.CONTACTO ? 
								<Fragment>
									<div className="media">
										{
											contacto.FOTOGRAFIA ? 
											<img className="img-40 rounded-circle img-fluid m-r-20" src={'/i_directorio?img='+contacto.ID+contacto.FOTOGRAFIA} alt="" />
											:
											<img className="img-40 rounded-circle img-fluid m-r-20" src={'/i_directorio?img=default.png'} alt="" />
										
										}
										<div className="media-body">
										<h6 className="f-w-600">{contacto.NOMBRE}</h6>
											<p>
												{contacto.TELEFONO ? contacto.TELEFONO+' | ':''}
												{contacto.CELULAR ? contacto.CELULAR+' | ':''}
												{contacto.CORREO ? contacto.CORREO:'Sin correo'}
											</p>
										</div>
									</div>
									<p>
										{contacto.EMPRESA && 'Empresa: '+contacto.EMPRESA}<br />
										{contacto.DIRECCION ? 'Dirección: '+contacto.DIRECCION:''}<br />
										{contacto.INFORMACION ? 'Información: '+contacto.INFORMACION:''}<br />
										{contacto.REDES_SOCIALES ? 'Redes sociales: '+contacto.REDES_SOCIALES:''}
									</p>
								</Fragment>
							:
								<Fragment>
									<div className="media">
										<img className="img-40 rounded-circle img-fluid m-r-20" src={`${contacto.FOTOGRAFIA!=null?('/image?img='+contacto.ID+contacto.FOTOGRAFIA):'/i_directorio?img=default.png'}`} alt="" />
										{/* <img className="img-40 img-fluid m-r-20" src="/image?img=1.png" alt="" /> */}
										<div className="media-body">
										<h6 className="f-w-600">{contacto.NOMBRE}</h6>
											<p>
												{contacto.TELEFONO_OFICINA ? '('+contacto.EXTENSION_OFICINA +') '+contacto.TELEFONO_OFICINA+' | ':''}
												{contacto.TELEFONO_CELULAR ? contacto.TELEFONO_CELULAR+' | ':''}
												{contacto.CORREO_EMPRESARIAL ? contacto.CORREO_EMPRESARIAL:'Sin correo'}
											</p>
										</div>
									</div>
									{
										localStorage.getItem('tipo')==='admin' &&
										<p>
											{contacto.CORREO_PARTICULAR ? 'Correo personal: '+contacto.CORREO_PARTICULAR:''}<br />
											{contacto.TELEFONO_PARTICULAR ? 'Teléfono: '+contacto.TELEFONO_PARTICULAR:''}<br />
											{contacto.DIRECCION ? 'Dirección: '+contacto.DIRECCION:''}<br />
										</p>
									}
								</Fragment>
						}
					</div>
				</div>
			</div>
		</div>
	);
}
 
export default Contacto;

/*export default class Contacto extends React.Component{
	constructor(props){
		super(props)
		this.contacto = this.props.dato
	}

	render(){
		if(this.contacto.CONTACTO === 1)
		return(
			<div className="container-fluid mt-1">
				<div className="card mb-0 shadow-lg">
					<div className="card-body">
						<div className="job-search">
							<div className="media">
								{
									this.contacto.FOTOGRAFIA ? 
									<img className="img-40 rounded-circle img-fluid m-r-20" src={'/i_directorio?img='+this.contacto.ID+this.contacto.FOTOGRAFIA} alt="" />
									:
									<img className="img-40 rounded-circle img-fluid m-r-20" src={'/i_directorio?img=default.png'} alt="" />

								}
								<div className="media-body">
								<h6 className="f-w-600">{this.contacto.NOMBRE}</h6>
									<p>
										{this.contacto.TELEFONO ? this.contacto.TELEFONO+' | ':''}
										{this.contacto.CELULAR ? this.contacto.CELULAR+' | ':''}
										{this.contacto.CORREO ? this.contacto.CORREO:'Sin correo'}
									</p>
								</div>
							</div>
							<p>
								{this.contacto.EMPRESA && 'Empresa: '+this.contacto.EMPRESA}<br />
								{this.contacto.DIRECCION ? 'Dirección: '+this.contacto.DIRECCION:''}<br />
								{this.contacto.INFORMACION ? 'Información: '+this.contacto.INFORMACION:''}<br />
								{this.contacto.REDES_SOCIALES ? 'Redes sociales: '+this.contacto.REDES_SOCIALES:''}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
		else{
			return(
				<div className="container-fluid mt-1">
					<div className="card mb-0 shadow-lg">
						<div className="card-body">
							<div className="job-search">
								<div className="media">
									<img className="img-40 rounded-circle img-fluid m-r-20" src={`${this.contacto.FOTOGRAFIA!=null?('/image?img='+this.contacto.ID+this.contacto.FOTOGRAFIA):'/i_directorio?img=default.png'}`} alt="" />
									{/* <img className="img-40 img-fluid m-r-20" src="/image?img=1.png" alt="" /> }
									<div className="media-body">
									<h6 className="f-w-600">{this.contacto.NOMBRE}</h6>
										<p>
											{this.contacto.TELEFONO_OFICINA ? '('+this.contacto.EXTENSION_OFICINA +') '+this.contacto.TELEFONO_OFICINA+' | ':''}
											{this.contacto.TELEFONO_CELULAR ? this.contacto.TELEFONO_CELULAR+' | ':''}
											{this.contacto.CORREO_EMPRESARIAL ? this.contacto.CORREO_EMPRESARIAL:'Sin correo'}
										</p>
									</div>
								</div>
								{
									localStorage.getItem('tipo')==='admin' &&
									<p>
										{this.contacto.CORREO_PARTICULAR ? 'Correo personal: '+this.contacto.CORREO_PARTICULAR:''}<br />
										{this.contacto.TELEFONO_PARTICULAR ? 'Teléfono: '+this.contacto.TELEFONO_PARTICULAR:''}<br />
										{this.contacto.DIRECCION ? 'Dirección: '+this.contacto.DIRECCION:''}<br />
									</p>
								}
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}*/