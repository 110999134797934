import React, {Fragment} from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import RightSidebar from './common/right-sidebar';
import Footer from './common/footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './common/loader';
import active from '../auth/active';
import {AppContextProvider, AppContext} from '../context/BuscadorContext'

active.checkSessionTime();

const AppLayout = ({children}) => {
        
        const activechk = () =>{
            active.activeSend()
        }

        return (
            <Fragment>
                <AppContextProvider>
                    <Loader />
                    <div className="page-wrapper" onClick={activechk}>
                        <div className="page-body-wrapper">
                            <Header />
                            <Sidebar />
                            <RightSidebar />
                            <div className="page-body">
                                {children}
                            </div>
                            <Footer />
                            {/* <ThemeCustomizer /> */}
                        </div>
                    </div>
                    <ToastContainer />
                </AppContextProvider>
            </Fragment>
        );
}

Header.contextType=AppContext;

export default AppLayout;