import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Search } from 'react-feather'
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from "./paginador";
import './cabecera.css'

const Tablereact = (props) => {
    const { pageSize, myClass } = props;
    const [myData, setMyData] = useState(props.myData);
    const [dataTmp, setdataTmp] = useState(props.myData);
    const [row, setRow] = useState({});
    const [accion, setAccion] = useState('');
    const [modal, setModal] = useState(false);
    var columns = [];
    const [cabecera, setCabecera] = useState(columns);

    useEffect(() => {
        setMyData(props.myData) 
        setdataTmp(props.myData)
        updateHeader(props.myData)
        // eslint-disable-next-line
    }, [props.myData]);

    useEffect(() => {
        updateHeader(myData)
        // eslint-disable-next-line
    }, [myData]);

    const updateHeader=(dato)=>{
        columns=[]

        props.cabecera &&
        props.cabecera.map(col=>columns.push({
            Header: col.Header,
            accessor: col.accessor,
            style: col.style || {},
            width: col.width || 'auto',
            editable: col.editable || false,
            Cell: col.cell,
            show: col.show === true ?  true: false
        }))
    
        props.eliminar_actualizar && //Pregunta si existe el props eliminar_actualizar
        columns.push({
            Header: <b>Accion</b>,
            id: 'delete', style: { textAlign: 'center' }, sortable: false, width:120, editable:false,
            Cell: (row) => {
                return (
                <div>
                    {props.eliminar_actualizar.actualizar? <span onClick={()=>{props.eliminar_actualizar.actualizar(dato[row.index])}}><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: '#28a745' }}></i></span>: ''}
                    {props.eliminar_actualizar.eliminar? <span onClick={()=>{setAccion('eliminar'); setRow(dato[row.index]); toggle();}}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span>: ''}
                </div>
            )},
            show: true
        })
    
        props.detalle &&
        columns.push({
            Header: <b>Detalle</b>, id: 'detalle', style: { textAlign: 'center' }, sortable: false, width:100, editable:false,
            Cell: (row) => (
                <div><span onClick={()=>props.detalle(dato[row.index])}><i className="fa fa-arrow-right" style={{ width: 35, fontSize: 16, padding: 11, color: 'blue' }}></i></span></div>
            ),
        })

        props.componente &&
        columns.push({
            Header: <b>{props.componente.nombre ? props.componente.nombre : ''}</b>, id: 'Componente', sortable: false, editable:false,
            Cell: (row) => (
                <props.componente.Component dato={row.original}/>
                //<div><span onClick={()=>console.log(row.original)}><i className="fa fa-arrow-right" style={{ width: 35, fontSize: 16, padding: 11, color: 'blue' }}></i></span></div>
            ),
        })

        setCabecera(columns)
    }

    const toggle = () => setModal(!modal)
    const actualizarFormulario = e =>setRow({...row,[e.target.name]:e.target.value})

    const eliminarDato = () => {
        toggle()
        props.eliminar_actualizar.eliminar(row)
    }

    const actualizarDato = () => {
       
    }

    const buscador = x => {        
        if(x.keyCode === 8){
            if(x.target.value !== '' && x.target.value !== null)
                setMyData(dataTmp.filter((v) => JSON.stringify(v).toUpperCase().includes(x.target.value.toUpperCase())))
            else
                setMyData(dataTmp)
        }else{
            if(x.target.value !== '' && x.target.value !== null)
                setMyData(myData.filter((v) => JSON.stringify(v).toUpperCase().includes(x.target.value.toUpperCase())))
            else
                setMyData(dataTmp)
        }
    }
    
    return ( 
        <Fragment>
            <div className="search-form row mb-3">
                {
                    props.buscador &&
                    <div className="col-md-3">
                        <div className="search-wrapper">
                            <Search style={{position:'absolute',top: '10px', left: '40px'}}/>
                            <input className="form-control" type="search" onKeyUp={buscador} placeholder={props.buscador} style={{ display: 'inline', marginRight: 40}} />
                        </div>
                    </div>
                }
                {
                    props.boton &&
                    <div className="col-md-9 text-right" >
                        <button className="btn btn-primary" type="button" onClick={props.boton.function} >{props.boton.text}</button>
                    </div>
                }
                {props.viewColumns? <div className="col-md-9 text-right ">
                    <div className="onhover-dropdown text-left" style={{ display: 'inline-block' }}>
                        <div className="media align-items-center" style={{fontSize: 30}}>
                            <i className= "icon-settings"></i>
                        </div>
                        <ul className="notification-dropdown onhover-show-div p-0" style={{width: 450, position: 'absolute', top: 35, left: -450 }}>
                            <li>Columnas mostradas</li>
                            <li style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                                {
                                cabecera.map((v, i) => {
                                    return (<span style={{ display: 'inline-block', margin: 5, marginRight: 10}} key={i}><input className="checkbox_animated" id="chk-ani"
                                    name="chk-ani" type="checkbox"  checked={v.show}
                                    onClick={() => { 
                                        let p = cabecera
                                        p[i].show = !p[i].show
                                        setCabecera([...p])
                                    } } onChange={() => {}} />{v.Header} </span>) })
                                }
                            </li>
                            <li onClick={() => { }}>Reiniciar Ajustes</li>
                        </ul>
                    </div>
                </div>: ''}
            </div>
    
            <ReactTable 
                className={myClass}
                data={myData} 
                columns={cabecera} 
                PaginationComponent={Pagination}
                defaultPageSize={pageSize} 
                showPagination={props.paginacion}
                showFilters={props.showFilters? true: false}
                filterable={props.showFilters? true: false}
                nextText='Siguiente'
                pageText='Página'
                pageJumpText='Saltar página'
                loadingText='Cargando...'
                previousText='Atrás'
                ofText='de'
                rowsText='Filas'
                noDataText='No hay registro'
                style={{fontFamily: 'Arial', fontSize: 15}}
                />
                
            <Modal isOpen={modal} toggle={toggle} className={`modal-body ${accion==='eliminar' && 'modal-lg'}`} centered={true}>
                <ModalHeader toggle={toggle}>{ accion==='editar' ? 'Editar elemento':'Borrar elemento'}</ModalHeader>
                <ModalBody>
                    {
                        accion==='editar' ?
                        <form>
                            {
                                columns.map((col,i)=>
                                    col.editable &&
                                        (
                                            <div key={i} className="col-sm-12 col-md-12 mb-3">
                                                <label className='form-label'>{col.Header.props.children}</label>
                                                <input className='form-control' type="text" name={col.accessor} value={row[col.accessor]} onChange={actualizarFormulario} />                                           
                                            </div>
                                        )
                                    
                                )
                            }
                        </form>
                        :
                        <Fragment>
                            <div className="mb-2 text-center"><strong style={{fontSize:'18px'}}>¿Estás seguro que deseas borrar este elemento?</strong></div>
                            <ReactTable data={[row]} columns={cabecera.filter(x=>( x.id!==('delete') && x.id!==('detalle') ))} defaultPageSize={1} className={myClass} showPagination={false} />
                        </Fragment>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={()=>{ accion === 'editar' ? actualizarDato() : eliminarDato() }}>{accion === 'editar' ? 'Actualizar':'Confirmar'}</Button>
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}
 
export default Tablereact;