import { toast } from 'react-toastify';

let timeInactive=0;
let toastID=null;

const TIME_NOTIFICATION = 12;
const TIME_OUT = 14;

function setInactive(){
    timeInactive=0;
    if(toastID!=null){
        toast.update(toastID,{ 
            render: "Retomando",
            closeButton: null,
            position: toast.POSITION.BOTTOM_RIGHT,
            type: toast.TYPE.SUCCESS,
            autoClose: 1000
        })
        toastID=null;
    }
}

function activeSend(){
    console.log('Click');
    fetch('/p_active', {
        method: 'POST',
        body: JSON.stringify({}),
        headers:{'Content-Type': 'application/json'}})
    .then(res=> res.json())
    .then(res=>{
        if(res.estado===1){
            // console.log(res);
            setInactive();
        }    
        else
            setTimeout(() => {
                window.location.href = '/login';
            }, 500)
    })
    .catch(err=>{
        console.log('active',err);
        setTimeout(() => {
            window.location.href = '/login';
        }, 500);
    })
}

function checkSessionTime(){

    if(timeInactive===TIME_NOTIFICATION){
        toastID = toast.warn('Su sesión se cerrara en '+(TIME_OUT-TIME_NOTIFICATION)+' minutos.',{
            position: toast.POSITION.BOTTOM_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: (1000*60*(TIME_OUT-TIME_NOTIFICATION)-100)
        });        
    }

    if(timeInactive===TIME_OUT){
        fetch('/cerrar_sesion', {
            /* method: 'POST',
            body: JSON.stringify({}), */
            headers:{'Content-Type': 'application/json'}})
        .then(res=> res.json())
        .then(res=>{
            setTimeout(() => {
                window.location.href = '/login';
            }, 500)
        })
        .catch(err=>{
            setTimeout(() => {
                window.location.href = '/login';
            }, 500);
        })
    }

    timeInactive++;
    setTimeout(checkSessionTime,60000);
}

export default {activeSend,checkSessionTime};
