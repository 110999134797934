import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from "../common/breadcrumb";
import Carousel from '../common/carousel'
import UserCard from '../common/user-card'
import ClimaCard from '../common/clima-card'
import UserCardProx from '../common/user-card-prox'
import { File } from 'react-feather';
/* TIPO DE CAMBIO */
import Buy from '../common/Buy'
import Sell from '../common/Sell'
import Dolar from "../../assets/images/dashboard/dolar.png";
import Climabg from "../../assets/images/clima-back.png";
import capitalhumanobg from "../../assets/images/capitalhumano.png";
/* TIPO DE CAMBIO */

/* ICONOS CLIMA */
import Lluvia from '../common/clima/lluvia'
import Soleado from '../common/clima/soleado'
import Nublado from '../common/clima/nublado'
import ParcialNublado from '../common/clima/parcial-nublado'
import ParcialNubladoLluvia from '../common/clima/parcial-nublado-lluvia'
import Granizo from '../common/clima/granizo'
import Viento from '../common/clima/viento'
import ParcialNubladoGranizo from '../common/clima/parcial-nublado-granizo'
import ParcialNubladoTormenta from '../common/clima/parcial-nublado-tormenta'
import Tormenta from '../common/clima/tormenta'
import Neblina from '../common/clima/neblina'
/* ICONOS CLIMA */

const ComunicadosInicio = (props) => {
	const [carousel_items, setCarousel_items] = useState([]);
	const [comunicados, setComunicados] = useState([]);
	const [festejados, setFestejados] = useState([]);
	const [festejados_prox, setFestejados_prox] = useState([]);
	const [clima, setClima] = useState({});
	const [sabias_que, setSabias_que] = useState({});
	const [nota, setNota] = useState([]);
	const [capital, setCapital] = useState([]);
	const [ready, setReady] = useState(false);

	useEffect(() => {
		fetch('/z_publicaciones', {
			method: 'PUT',
			body: JSON.stringify({ id: localStorage.getItem('id_usr') }),
			headers: { 'Content-Type': 'application/json' }
		})
			.then(res => res.json())
			.then(res => {
				console.log(res);
				if (res.status == 'OK') {
					var temp_carrousel = []
					res.result[0].map((elem) => {
						temp_carrousel.push({
							redirect: () => { props.history.push('/publicacion/' + elem.ID) },
							src: `/z_publicaciones?img=${elem.ID + elem.IMAGEN}`,
							altText: elem.TITULO,
							captionHeader: elem.TITULO,
							captionText: elem.FECHA
						})
					})
					setCarousel_items(temp_carrousel)
					setComunicados(res.result[1])
					setFestejados(res.result[2])
					setClima(res.result[3][0])
					setSabias_que(res.result[4][0])
					setNota(res.result[5])
					setFestejados_prox(res.result[6])
					setCapital(res.result[7])
					setReady(true)
				} else {
					console.log('Error interno');
				}
			})
			.catch(err => {
				console.log('Error:', err);
			})
	}, []);

	const Clima = (c) => {
		if (c == 'Clouds')
			return <ParcialNublado />
		else if (c == 'Clear')
			return <Soleado />
		else if (c == 'Rain')
			return <Lluvia />
		else if (c == 'Drizzle')
			return <Lluvia />
		else if (c == 'Snow')
			return <Granizo />
		else if (c == 'Thunderstorm')
			return <Tormenta />
		else if (c == 'Atmosphere')
			return <Neblina />
		else
			return <Soleado />
	}


	return (
		<Fragment>
			<Breadcrumb title="Página principal" parent="Comunicados" />
			<div className="container-fluid">
				{ready ?
					<div className="row">
						<div className="col-12 col-lg-8">
							<div className="row mb-4">
								<div className="col-12">
									<Carousel items={carousel_items} />
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="card comunicados">
										<div className="card-body">
											<h3 className="title-widget f-w-600 mb-0">Comunicados</h3>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12"> {/* Calendario */}
									<div className="card container">
										<div className="blog row">
											{!!comunicados.length ?
												comunicados.map(elem => {
													return <div key={elem.ID} className="blog-box blog-list col-12 col-lg-6">
														<a href={'/comunicados/comunicado/' + elem.ID}>
															<div className="blog-date digits">{elem.FECHA_PUBLICACION}</div>
															<h6><File className="mr-2" size={18} color="#c1452c" /> {elem.TITULO}</h6>
															<div className="blog-bottom-content">
																<ul className="blog-social">
																	<li>Por {elem.REMITENTE}</li>
																</ul>
																<p className="my-3">{elem.DESCRIPCION.substr(0, 100) + '...'}</p>
															</div>
														</a>
													</div>
												})
												:
												<h5 className="text-center p-3 mb-0">No hay comunicados recientes</h5>
											}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-4">
							<div className="row">
								{
									(!!festejados.length || !!festejados_prox.length) &&
									<div className="col-12 col-md-12 mt-2">{/* Cumpleaños */}
										<div>
											<div className="card custom-card birthday-card">
												{!!festejados.length && <UserCard user_data={festejados} />}
												{
													!!festejados_prox.length &&
													<Fragment>
														<div className="card-proximos" style={{marginTop: '40%'}}>
															{/* <h4 className="text-center font-weight-bold pb-xl-5 pt-2">Próximos cumpleaños</h4> */}
															<UserCardProx user_data={festejados_prox} />
														</div>
													</Fragment>
												}
											</div>
										</div>
									</div>
								}
								<div className="col-12">
									<div className="card" style={{ backgroundImage: "url(" + Climabg + ")", backgroundSize: 'contain', backgroundPosition: 'right', backgroundRepeat: 'no-repeat' }}>
										<ClimaCard user_data={clima} Clima={Clima} />
									</div>
								</div>

								<div className="col-12">
									<div className="card testimonial text-center cambio" style={{ cursor: 'pointer' }} onClick={() => { sabias_que?.ID && window.location.replace(`/sabias_que/${sabias_que.ID}`) }}>
										<div className="card-body">
											<h3 className="title-widget f-w-600">¿Sabías qué... ?</h3>
											<p className="f-16">{sabias_que?.TITULO || '-'}</p>
										</div>
									</div>
								</div>

								<div className="col-6 col-md-6 col-xl-6 set-col-6">
									<Buy />
								</div>
								<div className="col-6 col-md-6 col-xl-6 set-col-6">
									<Sell />
								</div>
							</div>
						</div>

						<div className="col-md-6 col-sm-12">
							<div className="row">
								<div className="col-12">
									<div className="card notas">
										<div className="card-body" onClick={() => { window.location.replace(`/notascotidianas`) }} style={{ cursor: 'pointer' }}>
											<h3 className="title-widget f-w-600 mb-0 text-md-center">Notas Cotidianas</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								{nota.map((x) => {
									return <div className="col-md-12 col-xl-12 set-col-12">
										<div className="card">
											<div className="blog-box blog-list row">
												<div className="col-sm-5">
													<img className="img-fluid sm-100-w" src={x.PORTADA} alt="" />
												</div>
												<div className="col-sm-7">
													<div className="blog-details">
														<div className="blog-date digits"><p className="font-weight-bold">{x.FECHA}</p></div>
														<div className="blog-bottom-content no-none">
															<hr />
															<h6 className="mt-0">{x.TITULO}</h6>
															<button className="btn btn-pill btn-sm btn-warning mb-4" type="button" onClick={() => { window.location.replace(`/notas_cotidianas/${x.ID}`) }}>Leer Nota</button>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* <div className="card">
											<div className="blog-box blog-grid text-center">
												<img className="img-fluid top-radius-blog" src={x.PORTADA} alt="" />
												<div className="blog-details-main">
													<ul className="blog-social">
														<li className="digits">{x.FECHA}</li>
													</ul>
													<hr />
													<h6 className="blog-bottom-details">{x.TITULO}</h6>
												</div>
											</div>
											<button className="btn btn-pill btn-sm btn-warning" type="button" onClick={() => { window.location.replace(`/notas_cotidianas/${x.ID}`) }}>Leer Nota</button>
										</div> */}
									</div>
								})}
							</div>
						</div>
						<div className="col-md-6 col-sm-12">
							<div className="row">
								<div className="col-12">
									<div className="card" style={{ backgroundImage: "url(" + capitalhumanobg + ")", backgroundSize: 'auto 100%', backgroundPosition: 'center right', backgroundRepeat: 'no-repeat' }}>
										<div className="card-body" /* onClick={() => { window.location.replace(`/notascotidianas`) }} style={{ cursor: 'pointer' }} */>
											<h3 className="title-widget f-w-600 mb-0 text-md-center">Capital Humano</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								{capital.map((x) => {
									return <div className="col-md-12 col-xl-12 set-col-12">
										<div className="card">
											<div className="blog-box blog-list row">
												<div className="col-sm-5">
													<img className="img-fluid sm-100-w" src={`/z_capital_humano_portada?ID=${x.ID}&PORTADA=${x.PORTADA!=''?x.PORTADA:'no-img'}`} alt="" />
												</div>
												<div className="col-sm-7">
													<div className="blog-details">
														<div className="blog-date digits"><p className="font-weight-bold">{x.FECHA}</p></div>
														<div className="blog-bottom-content no-none">
															<hr />
															<h6 className="mt-0">{x.TITULO}</h6>
															<button className="btn btn-pill btn-sm btn-warning mb-4" type="button" onClick={() => { window.location.replace(`/capitalhumano/nota/${x.ID}`) }}>Leer Nota</button>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* <div className="card">
											<div className="blog-box blog-grid text-center">
												<img className="img-fluid top-radius-blog" src={x.PORTADA} alt="" />
												<div className="blog-details-main">
													<ul className="blog-social">
														<li className="digits">{x.FECHA}</li>
													</ul>
													<hr />
													<h6 className="blog-bottom-details">{x.TITULO}</h6>
												</div>
											</div>
											<button className="btn btn-pill btn-sm btn-warning" type="button" onClick={() => { window.location.replace(`/notas_cotidianas/${x.ID}`) }}>Leer Nota</button>
										</div> */}
									</div>
								})}
							</div>
						</div>
					</div>
					:
					<div className="d-flex justify-content-center align-items-center">
						<div className="loader-box mr-4">
							<div className="loader">
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
								<div className="line bg-warning"></div>
							</div>
						</div>
						<h5 className="text-uppercase">Cargando...</h5>
					</div>
				}
			</div>
		</Fragment>
	);
}

export default ComunicadosInicio;