import React from 'react';
import IconoLogo from '../assets/images/logo/logo_200.png'
import notie from 'notie'

const Login = () => {

	const changePassword=()=>{
        document.getElementById('alert_contra_vacia').style['display'] = 'none'
        document.getElementById('alert_contra').style['display'] = 'none'
        if(document.getElementById('contra1').value === '' || document.getElementById('contra2').value === ''){
            document.getElementById('alert_contra_vacia').style['display'] = 'block'
        }
        else if(document.getElementById('contra1').value !== document.getElementById('contra2').value){
            document.getElementById('alert_contra').style['display'] = 'block'
        }else{
            document.getElementById('alert_contra').style['display'] = 'none'
            let form_data = new FormData(document.getElementById('form_login'));
            form_data.append('ID', localStorage.getItem('id_usr'))
            fetch('/z_usuarios',{
                method: 'PUT',
                body: form_data
            }).then(res => res.json())
            .then(res => {
                console.log(res);
                if(res.status === 'OK'){
                    if(res.result[0].RESULTADO === 1){
						if(res.result[0].TIPO === 3){
							localStorage.setItem('type_usr', 1)
							localStorage.setItem('tipo', 'admin')
						}else{
							localStorage.setItem('type_usr', 2)
							localStorage.setItem('tipo', 'usr')
						}
                        window.location.href = '/perfil'
                    }else{
                        notie.alert({ type: 2, text: 'Usuario o contraseña incorrectos', time: 3 })
                    }
                }else{
                    notie.alert({ type: 3, text: 'Ha ocurrido un error, inténtelo más tarde', time: 3 })
                }
            })
            .catch(err => {
                notie.alert({ type: 3, text: 'Ha ocurrido un error, inténtelo más tarde', time: 3 })
            })
        }
	}

	return (
		<div>
			<div className="page-wrapper">
				<div className="container-fluid p-0">
					{/* <!-- login page start--> */}
					<div className="authentication-main bg-color-shape">
						<div className="row">
							<div className="col-md-12">
								<div className="auth-innerright">
									<div className="authentication-box ">
										<div className="text-center"><img className="img-responsive" src={IconoLogo} alt="Buzón Público" /></div>
										<div className="card mt-4">
											<div className="card-body">
												<div className="text-center">
													<h4 className="txt-warning">CAMBIAR CONTRASEÑA</h4>
													<h6>Para continuar, por favor cambia tu contraseña</h6>
												</div>
												<form id="form_login" className="theme-form">
													<div className="form-group">
														<label className="col-form-label pt-0">Nueva contraseña</label>
														<input className="form-control" type="password" id="contra1" name="contra1" required="" />
													</div>
													<div className="form-group">
														<label className="col-form-label">Confirmar nueva contraseña</label>
														<input className="form-control" type="password" id="contra2" name="contra2"  required="" />
													</div>
													<div className="form-group form-row mt-3 mb-0">
														<button onClick={changePassword} className="btn btn-warning btn-block" type="button">Continuar</button>
													</div>
												</form>
                                                <div id="alert_contra" className="alert alert-danger mt-3 mb-0" style={{display: 'none'}}>Las contraseñas no coinciden, inténtalo de nuevo.</div>
                                                <div id="alert_contra_vacia" className="alert alert-danger mt-3 mb-0" style={{display: 'none'}}>Debes llenar los campos para continuar.</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
 
export default Login;