import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import 'react-toastify/dist/ReactToastify.css';
import Tablereact from '../common/tabulator/tablereact'
import Solicitud from './Solicitud'

const Solicitudes = (props) => {

	const [solicitudes, setSolicitudes] = useState(false);
	const [view, setView] = useState(0);

	useEffect(() => {
		fetch('/p_solicitudes').then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status === 'OK'){
				setSolicitudes(res.result)
			}else{
				console.log('Error');
			}
		})
		.catch(err => {console.log('Error: '+err);})
	}, []);

	const cabecera = [
        { Header: <b>Id</b>, accessor: 'ID', style: { textAlign: 'center'}, width:'80', show:false },
		{ Header: <b>Usuario</b>, accessor: 'NOMBRE', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
		{ Header: <b>Solicitud</b>, accessor: 'TIPO', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
        { Header: <b>Fecha de publicación</b>, accessor: 'FECHA', style: { textAlign: 'center'}, width:'200', editable:true , show:true}
	];

	const detalle_dato=(dato)=>setView(dato.ID)

	return (
		!view ?
		<Fragment>
			<Breadcrumb parent="Solicitudes"/>
			<div className="card">
				<div className="container-fluid">
					{
						!solicitudes ?
						<div className="d-flex justify-content-center align-items-center">
							<div className="loader-box mr-4">
								<div className="loader">
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
								</div>
							</div>
							<h5 className="text-uppercase">Cargando...</h5>
						</div>
						:
					<div className="row">
						<div className="col-sm-12 mt-3">
							<Tablereact
								myData={solicitudes}
								cabecera={cabecera}
								pageSize={10}
								paginacion={true}
								buscador='Buscar'
								detalle={detalle_dato}
							/>
						</div>
					</div>
					}
				</div>
			</div>
		</Fragment>
		:
		<Solicitud view={view} setView={setView} />
	);
}
 
export default Solicitudes;