import React, { Fragment } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import Icono from '../../assets/images/ilustraciones/noticias/noticias.png';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import mujer from '../../assets/images/ilustraciones/noticias/noticias-mujer.png'
import dialogoMujer from '../../assets/images/ilustraciones/noticias/noticias-dialogo-mujer.png'
import hombre from '../../assets/images/ilustraciones/noticias/noticias-hombre.png'
import dialogoHombre from '../../assets/images/ilustraciones/noticias/noticias-dialogo-hombre.png'
import base from '../../assets/images/ilustraciones/noticias/noticias-base.png'
import celular from '../../assets/images/ilustraciones/noticias/noticias-celular.png'
import celularIconos from '../../assets/images/ilustraciones/noticias/noticias-celular-iconos.png'
import bgShape from '../../assets/images/ilustraciones/noticias/noticias-bg.png'
import nubes from '../../assets/images/ilustraciones/nubes.png'

const Comunicado = (props) => {

	const redirectPublicaciones=()=>{
		props.history.push('/noticias/lista')
	}

	return (
		<Fragment>
			<div className="authentication-main p-0 container-fluid">
				<div className="row">
					<div className="col-sm-12 col-md-6 col-lg-8 bg-ilustracion">
						<div className="animation-wrapper">
							<div className="center z3 text-center">
								<Bounce duration={1400} right>
									<img className="img-fluid" src={hombre} alt=""/>
								</Bounce>
							</div>
							<div className="center z3 text-center">
								<Bounce delay={1000} duration={1400}>
									<img className="img-fluid" src={dialogoHombre} alt="" />
								</Bounce>
							</div>
							<div className="center z3 text-center">
								<Bounce delay={300} left>
									<img className="img-fluid" src={mujer} alt=""/>
								</Bounce>
							</div>
							<div className="center z3 text-center">
								<Bounce delay={1000} duration={1400}>
									<img className="img-fluid" src={dialogoMujer} alt=""/>
								</Bounce>
							</div>
							<div className="center z2 text-center">
								<Bounce duration={1000} bottom>
									<img className="img-fluid" src={base} alt=""/>
								</Bounce>
							</div>
							<div className="center z2 text-center">
								<Bounce duration={1200} top>
									<img className="img-fluid" src={celular} alt=""/>
								</Bounce>
							</div>
							<div className="center z2 text-center">
								<Bounce delay={1000} duration={1200}>
									<img className="img-fluid" src={celularIconos} alt=""/>
								</Bounce>
							</div>
							<div className="nubes top-10 left z1">
								<Fade duration={5000} left>
									<img className="img-fluid" src={nubes} alt=""/>
								</Fade>
							</div>
							<div className="center-animation bg-shape">
								<Fade>
									<img className="img-fluid" src={bgShape} alt=""/>
								</Fade>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-6 col-lg-4">
						<div className="auth-innerright">
							<div className="authentication-box p-4">
								<div className="row">
									<div className="col-6 col-md-6">
										<img className="img-fluid" src={Icono} alt=""></img>
										<h3 className="text-uppercase f-w-600 txt-primary">Noticias</h3>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-12 col-lg-7">
										<p className="text-dark">
										Consulta las noticias sobre los eventos y acontecimientos más sobresalientes, al instante.
										</p>
										<button className="btn btn-pill btn-warning btn-sm mx-auto" onClick={redirectPublicaciones}>Ver más</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default Comunicado;