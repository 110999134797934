import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tablereact from '../common/tabulator/tablereact'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Cotidiano = (props) => {

	const [publicaciones, setPublicaciones] = useState(false);

	useEffect(() => {
		fetch('/p_cotidiano')
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
				if(res.status === 'OK'){
					setPublicaciones(res.result)
				}else{
					console.log('ERROR')
				}
		})
		.catch((err) => {
			console.log('ERROR: '+err)
		})
	}, []);

	const cabecera = [
        { Header: <b>Id</b>, accessor: 'ID', style: { textAlign: 'center'}, width:'80', show:false },
        { Header: <b>Titulo</b>, accessor: 'TITULO', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
        { Header: <b>Fecha alta</b>, accessor: 'FECHA', style: { textAlign: 'center'}, width:'250', editable:true , show:true}
    ];

	const actualizar=(dato)=>props.history.push('/cotidiano/editar/'+dato.ID)
	const agregar=()=>props.history.push('/cotidiano/agregar')
	const eliminar=(dato)=>{
		fetch('/p_cotidiano',{ method: 'DELETE', body: JSON.stringify({ID: dato.ID}), headers: { 'Content-Type': 'application/json' }})
		.then( res => res.json() )
		.then( res => {
			if(res.status === 'OK'){
				setPublicaciones(publicaciones.filter(x=>x.ID!==dato.ID))
				toast.success("Se eliminó el registro", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else
				toast.warn("No se pudo borrar el registro", { position: toast.POSITION.BOTTOM_RIGHT })
		})
		.catch( (err) => {
			toast.error("Ha ocurrido un error", { position: toast.POSITION.BOTTOM_RIGHT })
		})
	}

	return (
		<Fragment>
			<Breadcrumb parent="Cotidiano" title="Inicio"/>
			<div className="card">
				<div className="container-fluid">
					{
						!publicaciones ?
						<div className="d-flex justify-content-center align-items-center">
							<div className="loader-box mr-4">
								<div className="loader">
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
								</div>
							</div>
							<h5 className="text-uppercase">Cargando...</h5>
						</div>
						:
						<div className="row">
							<div className="col-sm-12 mt-3">
								<Tablereact
                        		    myData={publicaciones}
                        		    cabecera={cabecera}
                        		    pageSize={10}
                        		    paginacion={true}
                        		    buscador='Buscar Cotidiano'
									eliminar_actualizar={{eliminar,actualizar}}
                        		    boton={{text:'Agregar Cotidiano',function:agregar}}
                        		/>
							</div>
						</div>
					}
				</div>
				
			</div>
		</Fragment>
	);
}
 
export default Cotidiano;