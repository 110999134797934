export const MENUITEMS = 
	localStorage.getItem('tipo') === 'admin' ?
	[	
		{
			title: 'Inicio', custom_icon: 'fa fa-home', type: 'link', path: '/comunicados/inicio', active: false
		},
		{
			title: 'Administrador', custom_icon: 'fa fa-gears', type: 'sub', active: false, children: [
				// { path: '/comunicados/inicio', title: 'Página principal', type: 'link', custom_icon: 'fa fa-pencil-square-o' },
				{ path: '/comunicados/publicados', title: 'Comunicados', type: 'link', custom_icon: 'fa fa-comments' },
				{
					title: 'Capital Humano', custom_icon: 'fa fa-users', type: 'link', path: '/capitalhumano/publicados', active: false
				},
				{
					title: 'Publicaciones', custom_icon: 'fa fa-pencil-square-o', type: 'link', path: '/publicaciones', active: false
				},
				{
					title: 'Cotidiano', custom_icon: 'fa fa-list', type: 'link', path: '/cotidiano', active: false
				},
				{
					title: '¿Sabías qué?', custom_icon: 'fa fa-question', type: 'link', path: '/sabias', active: false
				},
				{
					title: 'Categorías', custom_icon: 'fa fa-folder-o', type: 'link', path: '/categorias', active: false
				},
				{
					title: 'Buzon rojo', custom_icon: 'fa fa-phone', type: 'link', path: '/buzon_rojo', active: false
				},
				{
					title: 'Directorio', custom_icon: 'fa fa-book', type: 'link', path: '/directorio/inicio', active: false
				},
				{
					title: 'Eventos', custom_icon: 'fa fa-calendar', type: 'link', path: '/eventos', active: false
				},
				{
					title: 'Festividades', custom_icon: 'fa fa-calendar', type: 'link', path: '/festividades', active: false
				},
				{
					title: 'Solicitudes', custom_icon: 'fa fa-envelope-square', type: 'link', path: '/solicitudes', active: false
				},
				{
					type: 'separator'
				},
				{
					title: 'Empresas', custom_icon: 'fa fa-building', type: 'link', path: '/empresas', active: false
				},
				{
					title: 'Divisiones', custom_icon: 'fa fa-sitemap', type: 'link', path: '/divisiones', active: false
				},
				{
					title: 'Departamentos', custom_icon: 'fa fa-home', type: 'link', path: '/departamentos', active: false
				},
				{
					title: 'Puestos', custom_icon: 'fa fa-group', type: 'link', path: '/puestos', active: false
				},
				{
					title: 'Usuarios', custom_icon: 'fa fa-user', type: 'link', path: '/usuarios', active: false
				},
			]
		},
		{
			title: 'Usuario', custom_icon: 'fa fa-user', type: 'sub', active: false, children: [
				{
					title: 'Comunicados', custom_icon: 'fa fa-comments', type: 'link', path: '/comunicados', active: false
				},
				{
					title: 'Noticias', custom_icon: 'fa fa-exclamation-circle', type: 'link', path: '/noticias', active: false
				},
				{
					title: 'Notas Cotidianas', custom_icon: 'fa fa-list', type: 'link', path: '/notascotidianas', active: false
				},
				{
					title: 'Directorio', custom_icon: 'fa fa-book', type: 'link', path: '/directorio', active: false
				},
				{
					title: 'Solicitudes', custom_icon: 'fa fa-edit', type: 'link', path: '/solicitudes/agregar', active: false
				},
				{
					title: 'Calendario', custom_icon: 'fa fa-calendar', type: 'link', path: '/calendario', active: false
				},
				{
					title: 'Preguntas frecuentes', custom_icon: 'fa fa-question', type: 'link', path: '/preguntas_frecuentes', active: false
				},
				{
					title: 'Buzón rojo', custom_icon: 'fa fa-envelope', type: 'link', path: '/buzon_rojo/agregar', active: false
				},
			]
		},
		{
			title: 'Cerrar sesión', custom_icon: 'fa fa-sign-out', type: 'link', path: '/cerrar_sesion', active: false
		}
	] : 
	[	
		{
			title: 'Inicio', custom_icon: 'fa fa-home', type: 'link', path: '/comunicados/inicio', active: false
		},
		{
			title: 'Comunicados', custom_icon: 'fa fa-comments', type: 'link', path: '/comunicados', active: false
		},
		{
			title: 'Noticias', custom_icon: 'fa fa-exclamation-circle', type: 'link', path: '/noticias', active: false
		},
		{
			title: 'Notas Cotidianas', custom_icon: 'fa fa-list', type: 'link', path: '/notascotidianas', active: false
		},
		{
			title: 'Directorio', custom_icon: 'fa fa-book', type: 'link', path: '/directorio', active: false
		},
		{
			title: 'Solicitudes', custom_icon: 'fa fa-edit', type: 'link', path: '/solicitudes/agregar', active: false
		},
		{
			title: 'Calendario', custom_icon: 'fa fa-calendar', type: 'link', path: '/calendario', active: false
		},
		{
			title: 'Ayuda', custom_icon: 'fa fa-question', type: 'sub', active: false, children: [
				{ path: '/preguntas_frecuentes', title: 'Preguntas frecuentes', type: 'link' },
				{ path: '/buzon_rojo/agregar', title: 'Buzón rojo', type: 'link' }
			]
		},
		{
			title: 'Cerrar sesión', custom_icon: 'fa fa-sign-out', type: 'link', path: '/cerrar_sesion', active: false
		}
	]