import React, { Fragment } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import Icono from '../../assets/images/logo/icono-logo-2.png';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import buzonPublico from '../../assets/images/ilustraciones/buzon-rojo/buzon-rojo.png'
import usuario from '../../assets/images/ilustraciones/buzon-rojo/buzon-rojo-usuario.png'
import usuarioSombra from '../../assets/images/ilustraciones/buzon-rojo/buzon-rojo-sombra.png'
import dialogo from '../../assets/images/ilustraciones/buzon-rojo/buzon-rojo-dialogo.png'
import buzon from '../../assets/images/ilustraciones/buzon-rojo/buzon-rojo-buzon.png'
import buzonPlantas from '../../assets/images/ilustraciones/buzon-rojo/buzon-rojo-plantas.png'
import bgShape from '../../assets/images/ilustraciones/buzon-rojo/buzon-rojo-bg.png'
import nubes from '../../assets/images/ilustraciones/buzon-rojo/buzon-rojo-nubes.png'

const Comunicado = (props) => {

	const redirectCerrarSesion=()=>{
		fetch('/cerrar_sesion', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'}
		})
		.then(res => {
			props.history.push('/cerrar_sesion')
		})
		.catch(res => {
			props.history.push('/cerrar_sesion')
		})
	}

	return (
		<Fragment>
			<div className="dark-bg full-size">
				<div className="authentication-main p-0 container-fluid dark-bg">
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-8 bg-buzonrojo-ilustracion">
							<div className="animation-wrapper">
								<div className="buzon-publico">
									<Bounce duration={1400} top>
										<img src={buzonPublico} alt=""/>
									</Bounce>
								</div>
								<div className="usuario">
									<Bounce duration={1400} right>
										<img src={usuario} alt=""/>
									</Bounce>
								</div>
								<div className="usuario-sombra">
									<Bounce duration={1400} bottom>
										<img src={usuarioSombra} alt=""/>
									</Bounce>
								</div>
								<div className="dialogo">
									<Bounce delay={1400} duration={1200}>
										<img src={dialogo} alt=""/>
									</Bounce>
								</div>
								<div className="buzon">
									<Bounce delay={300}>
										<img src={buzon} alt="" />
									</Bounce>
								</div>
								<div className="buzon-plantas">
									<Bounce bottom delay={200}>
										<img src={buzonPlantas} alt=""/>
									</Bounce>
								</div>
								<div className="bg-shape">
									<Fade>
										<img src={bgShape} alt=""/>
									</Fade>
								</div>
								<div className="nubes">
									<Fade duration={5000} left>
										<img src={nubes} alt=""/>
									</Fade>
								</div>
							</div>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-4">
							<div className="auth-innerright">
								<div className="authentication-box p-4">
									<div className="row">
										<div className="col-6 col-md-6">
											<img className="img-fluid" src={Icono} alt=""></img>
										</div>
										<div className="col-12">
											<h3 className="text-uppercase f-w-600 text-white">¿Qué es el <br/><span className="txt-rojo-buzon">Buzón Rojo</span>?</h3>
											<p>
											Es un canal de denuncias  y clave para la prevención y detección de cualquier tipo de irregularidad en las empresas, evitándoles posibles responsabilidades penales, además de los perjuicios reputacionales, manifestando y evidenciando la cultura ética y de compliance de su organización.
											</p>
											<p>
											Cuando seleccione continuar se terminará su sesión y será redirigido al sitio donde podrá realizar su denuncia de manera anónima.
											</p>
											<button className="btn btn-pill btn-buzon-rojo txt-light btn-lg mx-auto" onClick={redirectCerrarSesion}>Continuar</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default Comunicado;