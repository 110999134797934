//Tiene buscador true
import React, { Fragment } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import Icono from '../../assets/images/ilustraciones/comunicados/comunicados.png';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import mujer from '../../assets/images/ilustraciones/comunicados/comunicados-mujer.png'
import burbuja from '../../assets/images/ilustraciones/comunicados/comunicados-burbuja.png'
import carpeta1 from '../../assets/images/ilustraciones/comunicados/comunicados-carpeta-1.png'
import carpeta2 from '../../assets/images/ilustraciones/comunicados/comunicados-carpeta-2.png'
import carpeta3 from '../../assets/images/ilustraciones/comunicados/comunicados-carpeta-3.png'
import carpeta4 from '../../assets/images/ilustraciones/comunicados/comunicados-carpeta-4.png'
import carpeta5 from '../../assets/images/ilustraciones/comunicados/comunicados-carpeta-5.png'
import carpeta6 from '../../assets/images/ilustraciones/comunicados/comunicados-carpeta-6.png'
import carpeta7 from '../../assets/images/ilustraciones/comunicados/comunicados-carpeta-7.png'
import carpeta8 from '../../assets/images/ilustraciones/comunicados/comunicados-carpeta-8.png'
import carpeta9 from '../../assets/images/ilustraciones/comunicados/comunicados-carpeta-9.png'
import carpeta10 from '../../assets/images/ilustraciones/comunicados/comunicados-carpeta-10.png'
import base from '../../assets/images/ilustraciones/comunicados/comunicados-base.png'
import bgShape from '../../assets/images/ilustraciones/noticias/noticias-bg.png'
import nubes from '../../assets/images/ilustraciones/comunicados/comunicados-nubes.png'

const Comunicado = (props) => {

	const redirectPublicaciones=()=>{
		props.history.push('/comunicados/recibidos')
	}

	return (
		<Fragment>
			<div className="authentication-main p-0 container-fluid">
				<div className="row">
					<div className="col-sm-12 col-md-6 col-lg-8 bg-ilustracion">
						<div className="animation-wrapper">
							<div className="center z2 text-center">
								<Bounce duration={1000} left>
									<img className="img-fluid" src={mujer} alt=""/>
								</Bounce>
							</div>
							<div className="center z3 text-center">
								<Bounce delay={500} duration={1000}>
									<img className="img-fluid" src={burbuja} alt=""/>
								</Bounce>
							</div>
							<div className="center z2 text-center">
								<Bounce delay={1500}>
									<img className="img-fluid" src={carpeta1} alt=""/>
								</Bounce>
							</div>
							<div className="center z3 text-center">
								<Bounce delay={1400}>
									<img className="img-fluid" src={carpeta2} alt=""/>
								</Bounce>
							</div>
                            <div className="center z3 text-center">
								<Bounce delay={1300}>
									<img className="img-fluid" src={carpeta3} alt=""/>
								</Bounce>
							</div>
                            <div className="center z3 text-center">
								<Bounce delay={1200}>
									<img className="img-fluid" src={carpeta4} alt=""/>
								</Bounce>
							</div>
                            <div className="center z3 text-center">
								<Bounce delay={1100}>
									<img className="img-fluid" src={carpeta5} alt=""/>
								</Bounce>
							</div>
                            <div className="center z3 text-center">
								<Bounce delay={1000}>
									<img className="img-fluid" src={carpeta6} alt=""/>
								</Bounce>
							</div>
                            <div className="center z3 text-center">
								<Bounce delay={900}>
									<img className="img-fluid" src={carpeta7} alt=""/>
								</Bounce>
							</div>
                            <div className="center z3 text-center">
								<Bounce delay={800}>
									<img className="img-fluid" src={carpeta8} alt=""/>
								</Bounce>
							</div>
                            <div className="center z3 text-center">
								<Bounce delay={700}>
									<img className="img-fluid" src={carpeta9} alt=""/>
								</Bounce>
							</div>
                            <div className="center z3 text-center">
								<Bounce delay={600}>
									<img className="img-fluid" src={carpeta10} alt=""/>
								</Bounce>
							</div>
							<div className="center z3 text-center">
								<Bounce duration={800} bottom>
									<img className="img-fluid" src={base} alt=""/>
								</Bounce>
							</div>
							<div className="center z1 text-center">
								<Fade duration={5000} left>
									<img className="img-fluid" src={nubes} alt=""/>
								</Fade>
							</div>
							<div className="center-animation bg-shape">
								<Fade>
									<img className="img-fluid" src={bgShape} alt=""/>
								</Fade>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-6 col-lg-4">
						<div className="auth-innerright">
							<div className="authentication-box p-4">
								<div className="row">
									<div className="col-6 col-md-6">
										<img className="img-fluid" src={Icono} alt=""></img>
										<h3 className="text-uppercase f-w-600 txt-primary">Comunicados</h3>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-12 col-lg-7">
										<p className="text-dark">
										Consulta los comunicados, mensajes, invitaciones y documentos de la empresa, al instante.
										</p>
										<button className="btn btn-pill btn-warning btn-sm mx-auto" onClick={redirectPublicaciones}>Ver más</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default Comunicado;