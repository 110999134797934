import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectFetch from '../common/select_fetch';

const EmpresasAgregar = (props) => {

	const saveButton=()=>{
		let form_data = new FormData(document.getElementById('form_empresas'))

		fetch('/p_empresas', {
			method: 'POST',
			body: JSON.stringify({
				nombre_interno: form_data.get('nombre_interno'),
				razon_social: form_data.get('razon_social'),
				calle: form_data.get('calle'),
				numero: form_data.get('numero'),
				colonia: form_data.get('colonia'),
				ciudad: form_data.get('ciudad'),
				codigo_postal: form_data.get('codigo_postal'),
				estado: form_data.get('estado')
			}),
			headers: {'Content-Type': 'application/json'}
		})
		.then(res => res.json())
		.then(res => {
			if(res.status === 'OK' && res.rows[0] > 0){
				document.getElementById('form_empresas').reset()
				toast.success("Agregado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else{
				toast.warn("No se pudo agregar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
			}
		})
		.catch(err => {
			toast.warn("Ha ocurrido un error, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
		})
	}

	const backButton = () => {
		props.history.push('/empresas')
	}

	return (
		<Fragment>
			<Breadcrumb title="Agregar" parent="Empresas" />
			<div className="container-fluid">
				<div className="row">
				<div className="col-sm-12">
					<div className="card">
						<div className="card-header">
							<h5>Datos de la empresa</h5>
						</div>
						<form id="form_empresas" className="form theme-form">
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Nombre interno: </label>
										<input id="form_nombre_interno" name="nombre_interno" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label className="form-label">Razón social: </label>
										<input id="form_razon_social" name="razon_social" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-md-8">
									<div className="form-group">
										<label className="form-label">Calle: </label>
										<input id="form_calle" name="calle" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-md-4">
									<div className="form-group">
										<label className="form-label">Número: </label>
										<input id="form_numero" name="numero" className="form-control digits" type="number" required />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label className="form-label">Colonia: </label>
										<input id="form_colonia" name="colonia" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label className="form-label">Código postal: </label>
										<input id="form_codigo_postal" name="codigo_postal" className="form-control digitsrequired " type="number" />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label className="form-label">Ciudad: </label>
										<input id="form_ciudad" name="ciudad" className="form-control" type="text" required />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<SelectFetch 
										element_id='form_estado'
										element_name='estado'
										label_text='Estados'
										// default_selected={this.empresa.ESTADO}
										fetch_route='/estadoss'
									/>
								</div>
							</div>
						</div>
						<div className="card-footer">
							<div className="row">
								<div className="col-12">
									<div className="btn-showcase">
										<button onClick={saveButton} className="btn btn-primary mr-1" type="button">Guardar</button>
										<button onClick={backButton} className="btn btn-light txt-dark" type="button">Atrás</button>
									</div>
								</div>
							</div>
						</div>
					</form>
					</div>
				</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default EmpresasAgregar;