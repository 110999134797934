import React, { Fragment } from 'react';
import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import 'react-toastify/dist/ReactToastify.css';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import buzonPublico from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo.png'
import usuario from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-usuario.png'
import usuarioSombra from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-sombra.png'
import dialogo from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-dialogo.png'
import buzon from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-buzon.png'
import buzonPlantas from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-plantas.png'
import bgShape1 from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-bg.png'
import nubes from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-nubes.png'
import tipoDenuncia from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-denuncia.png'
import detallesDenuncia from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-detalles.png'
import evidenciaDenuncia from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-evidencia.png'
import seguimientoDenuncia from '../assets/images/ilustraciones/buzon-rojo/buzon-rojo-seguimiento.png'
import bgShape from '../assets/images/ilustraciones/buzon-rojo/bg.png'
import anonimo from '../assets/images/anonimo.png'
import datosUsuario from '../assets/images/usuario.png'
import Select, { components } from 'react-select'
import { Mail } from 'react-feather';

const styleNoValidate = {
	display: 'none',
	padding: '.25rem .5rem',
	marginTop: '.1rem',
	fontSize: '.875rem',
	lineHeight: '1.5',
	color: '#fff',
	backgroundColor: 'rgba(255,83,112,.9)',
	borderRadius: '.25rem'
}

const options = [
	{
	  label: "Fraude económico",
	  subLabel: "(lavado de dinero, robo de mercancías bienes o valores, gastos sin autorización.)",
	  value: "1"
	},
	{
	  label: "Alteración de información contable operativa y financiera, documentos legales y elusión de controles internos de la empresa",
	  value: "2"
	},
	{
	  label: "Acoso, discriminación y malos tratos del personal",
	  subLabel: "(actos en detrimento del sexo, raza nacionalidad, etc.)",
	  value: "3"
	},
	{
	  label: "Mal desempeño de empleados, supervisores y/o gerentes",
	  subLabel: "(incluye abusos de poder, favoritismo, amenaza y mal comportamiento.)",
	  value: "4"
	},
	{
	  label: "Corrupción y acuerdo con proveedores o clientes",
	  value: "5"
	},
	{
	  label: "Descuido o utilización inapropiada de los bienes y/o servicios de la organizaciónpción y acuerdo con proveedores o clientes",
	  value: "6"
	},
	{
	  label: "Recibo o sustracción de información interna de la empresa",
	  value: "7"
	},
	{
	  label: "Mejora de procesos que requieren atención inmediata",
	  value: "8"
	}
  ];

let selectedValue
let reportType = 0 ,evidences = 0, upload = 0
let time
const archivos_adjuntos = []

const selectChange = (selectedOption) => {
	selectedValue = selectedOption
}

const Option = props => {
	return (
		<components.Option {...props}>
		<div>{props.data.label}</div>
		<div style={{ fontSize: 12 }}>{props.data.subLabel}</div>
		</components.Option>
	);
};

const MySelect = () => (
  <Select options={options} onChange={selectChange} components={{ Option }} placeholder={'Selecciona una opción de la lista...'} />
)



export default class Login extends React.Component{

	constructor(props){
		super(props)
		const {  meridiem, focused, showTimezone, timezone } = props;
		let hour = '';
		let minute = '';
		this.state = {
			display: 1,
			hour,
			minute,
			meridiem,
			focused,
			timezone,
			showTimezone,
		}
	
		this.onFocusChange = this.onFocusChange.bind(this);
		this.onTimeChange = this.onTimeChange.bind(this);
	}

	btnSiguiente(){
		if(this.state.display !== 4){
			let next = false
			switch (this.state.display){
				/*
				valid_report
				valid_polices
				valid_type
				valid_description
				valid_files
				*/
				case 1: 
					if(reportType === 0){
						next = false
						document.getElementById('valid_polices').style['display'] = 'none';
						document.getElementById('valid_report').style['display'] = 'block';
					}else{
						document.getElementById('valid_report').style['display'] = 'none';
						next = document.getElementById('solid1').checked;
						document.getElementById('valid_polices').style['display'] = 'block';
					}
					break;
				case 2: 
					next = selectedValue ? true : false;
					document.getElementById('valid_type').style['display'] = 'block';
					break;
				
				case 3: 
					next = document.getElementById('form_descripcion').value !== "" ? true : false;
					document.getElementById('valid_description').style['display'] = 'block';
					break;
				
				case 4:
					console.log('Evidencias:',evidences, upload);
					if(evidences === 1 && upload === 1)
						if(archivos_adjuntos.length > 0){
							next = true
						}else{
							document.getElementById('valid_files').style['display'] = 'block';
							next = false
						}
					else if(evidences === 1 && upload === 2)
						next = true
					else if(evidences === 2)
						next = true
					break;
				
				default: next = true; break;
			}
			console.log('Next:', next);
			if(next)
				this.setState((state) => {
					return ({display: state.display+1})
				})
		}else{
			let form_data = new FormData(document.getElementById('form_buzon'))
			form_data.append('denuncia', selectedValue.value)
			form_data.append('hora', ('00' + time.hour).slice(-2)+':'+time.minute+' '+time.meridiem)
			archivos_adjuntos.forEach((file, index) => {
				form_data.append('archivo_'+index, file)
				console.log('Archivo adjunto',index);
			})
			console.log(form_data);
			fetch('/p_buzon_rojo', {
				method: 'POST',
				body: form_data
			})
			.then(res => res.json())
			.then(res => {
				console.log(res);
				if(res.status === 'OK'){
					this.setState((state) => {
						return ({display: state.display+1})
					})
				}
			})
			.catch(err => {
				console.log(err);
			})
		}
	}

	btnAnterior(){
		document.getElementById('valid_polices').style['display'] = 'none';
		document.getElementById('valid_type').style['display'] = 'none';
		document.getElementById('valid_description').style['display'] = 'none';
		this.setState((state) => {
			return ({display: state.display-1})
		})
	}

	irABuzonPublico(){
		window.location.replace('/login')
	}

	submit(){
		let form_data = new FormData(document.getElementById('form_buzon'))
		form_data.append('tipo_denuncia', selectedValue)
		form_data.append('hora', time)
		archivos_adjuntos.forEach((file, index) => {
			form_data.append('archivo_'+index, file)
			console.log('Archivo adjunto',index);
		})
		console.log(form_data);
		fetch('/p_buzon_rojo', {
			method: 'POST',
			body: form_data
		})
		.then(res => res.json())
		.then(res => {
			console.log(res);
		})
		.catch(err => {
			console.log(err);
		})
	}
	
	onTimeChange(options) {
		time = options
		const {
			hour,
			minute,
			meridiem
		} = options;
		
		this.setState({ hour, minute, meridiem });
	}
	
	onFocusChange(focused) {
		this.setState({ focused });
	}

	render(){
		const {
			hour,
			minute,
			focused,
			meridiem,
			timezone,
		  } = this.state;

		return (
			<div>
				<div className="page-wrapper">
					<div className="container-fluid p-0">
						{/* <!-- login page start--> */}
						<div className="authentication-main p-0">
							<div className="row">
								{/* Ilustración */}
								<div className="col-sm-12 col-md-6 col-lg-6 bg-buzonrojo-ilustracion">
									<div className="animation-wrapper">
										<div style={this.state.display !== 1 ? {display: "none"}: {}}>
											<div className="buzon-publico">
												<Bounce duration={1400} top>
													<img src={buzonPublico} alt=""/>
												</Bounce>
											</div>
											<div className="usuario">
												<Bounce duration={1400} right>
													<img src={usuario} alt=""/>
												</Bounce>
											</div>
											<div className="usuario-sombra">
												<Bounce duration={1400} bottom>
													<img src={usuarioSombra} alt=""/>
												</Bounce>
											</div>
											<div className="dialogo">
												<Bounce delay={1400} duration={1200}>
													<img src={dialogo} alt=""/>
												</Bounce>
											</div>
											<div className="buzon">
												<Bounce delay={300}>
													<img src={buzon} alt=""/>
												</Bounce>
											</div>
											<div className="buzon-plantas">
												<Bounce bottom delay={200}>
													<img src={buzonPlantas} alt=""/>
												</Bounce>
											</div>
											<div className="bg-shape">
												<Fade>
													<img src={bgShape1} alt=""/>
												</Fade>
											</div>
											<div className="nubes">
												<Fade duration={5000} left>
													<img src={nubes} alt=""/>
												</Fade>
											</div>
										</div>
										<div style={this.state.display !== 2 ? {display: "none"}: {}}>
											<div className="center-animation text-center">
												<Bounce duration={1400} top>
													<img className="img-fluid" src={tipoDenuncia} alt=""/>
												</Bounce>
											</div>
											<div className="center-bg">
												<Bounce delay={300}>
													<img src={bgShape} alt=""/>
												</Bounce>
											</div>
										</div>

										<div style={this.state.display !== 3 ? {display: "none"}: {}}>
											<div className="center-animation text-center">
												<Bounce duration={1400} top>
													<img className="img-fluid" src={detallesDenuncia} alt=""/>
												</Bounce>
											</div>
											<div className="center-bg">
												<Bounce delay={300}>
													<img src={bgShape} alt=""/>
												</Bounce>
											</div>
										</div>
										<div style={this.state.display !== 4 ? {display: "none"}: {}}>
											<div className="center-animation text-center">
												<Bounce duration={1400} top>
													<img className="img-fluid" src={evidenciaDenuncia} alt=""/>
												</Bounce>
											</div>
											<div className="center-bg">
												<Bounce delay={300}>
													<img src={bgShape} alt=""/>
												</Bounce>
											</div>
										</div>
										<div style={this.state.display !== 5 ? {display: "none"}: {}}>
											<div className="center-animation text-center">
												<Bounce duration={1400} top>
													<img className="img-fluid" src={seguimientoDenuncia} alt=""/>
												</Bounce>
											</div>
											<div className="center-bg">
												<Bounce delay={300}>
													<img src={bgShape} alt=""/>
												</Bounce>
											</div>
										</div>
									</div>
								</div>
								{/* Ilustración */}
								
								{/* Formularios */}
								<div className="col-sm-12 col-md-6 col-lg-6 bg-login-form dark-bg">
									<div className="auth-innerright">
										<div className="authentication-box wrapper-buzon-rojo p-3">
											<div className="container-fluid my-4">
												<div className="row">
													<div className="col-12">
														<form id="form_buzon">
															{/* TIPO DE DENUNCIA */}
															<div className="row justify-content-center" style={this.state.display !== 1 ? {display: "none"}: {}}>
																<div className="col-12">
																	<h2 className="text-center f-w-600">¿Cómo quieres realizar tu denuncia?</h2>
																</div>
																<div className="col-12">
																	<div className="form-group m-t-15 custom-radio-ml">
																		<div className="row">
																			<OpcionDatos />
																		</div>

																		<div className="checkbox checkbox-solid-primary text-center mt-3">
																			<input id="solid1" name="politicas" type="checkbox" />
																			<label htmlFor="solid1">Acepto las <a href="#">Políticas de Privacidad.</a>
																			</label>
																			<br />
																			<span id="valid_polices" style={styleNoValidate}>
																				Debe aceptar las políticas de privacidad.
																			</span>
																		</div>
																	</div>
																</div>
															</div>
															{/* SELECT DE REPORTE */}
															<div className="row justify-content-center" style={this.state.display !== 2 ? {display: "none"}: {}}>
																<div className="col-12 mb-4">
																	<h2 className="text-center f-w-600">¿Qué sucedió?</h2>
																</div>
																<div className="col-12">
																	<h5 className="text-center">Elija el tipo de reporte:</h5>
																</div>
																<div className="col-12">
																	<div className="txt-dark form-group m-t-15 custom-radio-ml">
																		<MySelect />
																		<br />
																		<span id="valid_type" style={styleNoValidate}>
																			Sekeccione un tipo de reporte.
																		</span>
																	</div>
																</div>
															</div>
															{/* DETALLES DEL REPORTE */}
															<div className="row justify-content-center" style={this.state.display !== 3 ? {display: "none"}: {}}>
																<div className="col-12 mb-4">
																	<h2 className="text-center f-w-600">Detalla el incidente</h2>
																</div>
																<div className="col-12">
																	<h5>Hora y fecha aproximada del incidente:</h5>
																</div>
																<div className="col-md-12 col-lg-6 col-xl-6">
																	<div className="form-group m-t-15">
																		<label className="form-label">Hora</label>
																		<TimePicker
																			focused={focused}
																			meridiem={meridiem}
																			timezone={timezone}
																			timeMode="12"
																			onFocusChange={this.onFocusChange}
																			onTimeChange={this.onTimeChange}
																			time={hour && minute ? `${hour}:${minute}` : null}
																		/>
																	</div>
																</div>
																<div className="col-md-12 col-lg-6 col-xl-6">
																	<div className="form-group m-t-15">
																		<label className="form-label">Fecha</label>
																		<input className="form-control" name="fecha" type="date" />
																	</div>
																</div>
																<div className="col-12">
																	<h5>Información del incidente:</h5>
																</div>
																<div className="col-md-12">
																	<div className="form-group m-t-15">
																		<input className="form-control input-air-primary" name="involucrados" type="text" placeholder="Persona o departamento a denunciar"/>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="form-group m-t-15">
																		<input className="form-control input-air-primary" name="ciudad" type="text" placeholder="Ciudad"/>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="form-group m-t-15">
																		<input className="form-control input-air-primary" name="estado" type="text" placeholder="Estado"/>
																	</div>
																</div>
																<div className="col-md-12">
																	<div className="form-group m-t-15">
																		<input className="form-control input-air-primary" name="testigo1" type="text" placeholder="Testigo 1 (opcional)"/>
																	</div>
																</div>
																<div className="col-md-12">
																	<div className="form-group m-t-15">
																		<input className="form-control input-air-primary" name="testigo2" type="text" placeholder="Testigo 2 (opcional)"/>
																	</div>
																</div>
																<div className="col-md-12">
																	<div className="form-group m-t-15">
																		<input className="form-control input-air-primary" name="testigo3" type="text" placeholder="Testigo 3 (opcional)"/>
																	</div>
																</div>
																<div className="col-md-12">
																	<div className="form-group m-t-15">
																		<textarea className="form-control input-air-primary" name="descripcion" id="form_descripcion" rows="3" placeholder="Descripción de los hechos"/>
																		<br />
																		<span id="valid_description" style={styleNoValidate}>
																			Agregue una descripción.
																		</span>
																	</div>
																</div>
															</div>
															{/* EVIDENCIAS */}
															<div className="row justify-content-center" style={this.state.display !== 4 ? {display: "none"}: {}}>
																<div className="col-12">
																	<h2 className="text-center f-w-600">¿Cuentas con evidencias?</h2>
																</div>

																<div className="col-12">
																	<div className="form-group m-t-15 custom-radio-ml">
																		<div className="row">
																			<SiEvidencias />
																		</div>
																	</div>
																{/* <button type="button" onClick={this.submit}>Validar archivos</button> */}
																</div>
															</div>
															{/* REPORTE ENVIADO (SEGUIMIENTO) */}
															<div className="row justify-content-center" style={this.state.display !== 5 ? {display: "none"}: {}}>
																<div className="col-12 col-md-12">
																	<h3 className="text-white mb-4">Tu reporte ha sido enviado correctamente.</h3>
																	<p></p>
																</div>
																
																<div className="col-12 col-md-12">
																	<p className="text-white mb-0 f-18">Folio de Seguimiento</p>
																	<span className="txt-danger f-w-600 f-30">#13452</span>
																</div>

																<div className="col-md-12 mt-5">
																	<h5 className="text-white mb-3 f-w-600">¿Cómo puedo realizar el seguimiento de mi denuncia?</h5>
																	<p className="f-16">Ingresa tu correo electrónico para recibir alertas sobre el seguimiento de tu denuncia:</p>

																	<div className="form-group m-form__group">
																		<div className="input-group">
																			<div className="input-group-prepend">
																				<span className="input-group-text"><Mail /></span>
																			</div>
																			<input className="form-control" type="text" placeholder="Correo electrónico (opcional)" />
																		</div>
																	</div>
																</div>

																<div className="col-md-12 mt-3">
																	<p className="f-16">Si quieres hacer el seguimiento de forma <strong>ANÓNIMA</strong> puedes realizarlo accediendo al siguiente link:</p>

																	<p className="text-underline f-18 mb-2"><a href="#" className="txt-danger">www.buzonrojo.com/13452</a></p>
																	<p className="f-16">Contraseña: d452a1</p>
																</div>

																<div className="col-12 col-md-6 text-center">
																	<button className="btn btn-outline-primary txt-light m-2" type="button" onClick={this.irABuzonPublico}>Regresar al Buzón Público</button>
																</div>
															</div>
															
															<div className="row justify-content-center mt-4" style={this.state.display === 5 ? {display: "none"}:{}}>
																<div className="col-6 col-md-6 text-center">
																	<button className="btn btn-outline-light m-2" style={this.state.display === 1 ? {display: "none"}: {}} type="button" onClick={this.btnAnterior.bind(this)}>Anterior</button>
																</div>
																<div className="col-6 col-md-6 text-center">
																	<button className="btn btn-primary m-2" type="button" onClick={this.btnSiguiente.bind(this)}>{this.state.display === 4 ? "Finalizar": "Siguiente"}</button>
																</div>
															</div>
														
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* Formularios */}
							</div>
						</div>
						{/* <!-- login page end--> */}
					</div>
				</div>
			</div>
		);
	}
};

class OpcionDatos extends React.Component {
	constructor() {
		super();
	  
	this.state = { 
		checked: false
	};
	  this.handleChange = this.handleChange.bind(this);
	}
	
	handleChange() {
		console.log('change');
		if(document.getElementById('Usuario1').checked){
			reportType = 1
			this.setState((state) => ({
			  checked: false
			}))
		}else{
			reportType = 2
			this.setState((state) => ({
				checked: true
			}))
		}
	}
	
	render() {
	  /* const content = this.state.checked ? */
	  const content =
		 <div className="row px-3" style={{display: this.state.checked ? 'flex' : 'none'}}>
		  <div className="col-md-12">
			  <h5 className="txt-white f-w-600 mt-4">Datos Generales</h5>
		  </div>
		  <div className="col-md-6">
			  <div className="form-group m-t-15 custom-radio-ml">
				  <input className="form-control input-air-primary" name="datos_nombre" type="text" placeholder="Nombre(s)" />
			  </div>
		  </div>
		  <div className="col-md-6">
			  <div className="form-group m-t-15 custom-radio-ml">
				  <input className="form-control input-air-primary" name="datos_apellidos" type="text" placeholder="Apellidos" />
			  </div>
		  </div>
		  <div className="col-md-12">
			  <div className="form-group m-t-15 custom-radio-ml">
				  <input className="form-control input-air-primary" name="datos_puesto" type="text" placeholder="Puesto" />
			  </div>
		  </div>
		  <div className="col-md-6">
			  <div className="form-group m-t-15 custom-radio-ml">
				  <input className="form-control input-air-primary" name="datos_ciudad" type="text" placeholder="Ciudad" />
			  </div>
		  </div>
		  <div className="col-md-6">
			  <div className="form-group m-t-15 custom-radio-ml">
				  <input className="form-control input-air-primary" name="datos_estado" type="text" placeholder="Estado" />
			  </div>
		  </div>
		  <div className="col-md-6">
			  <div className="form-group m-t-15 custom-radio-ml">
				  <input className="form-control input-air-primary" name="datos_telefono" type="text" placeholder="Teléfono" />
			  </div>
		  </div>
		  <div className="col-md-6">
			  <div className="form-group m-t-15 custom-radio-ml">
				  <input className="form-control input-air-primary" name="datos_correo" type="text" placeholder="Correo electrónico" />
			  </div>
		  </div>
	  </div>
		/* : null; */
	  
	  return <Fragment>
			<div className="col-md-6">
				<input className="checkbox-tools" type="radio" name="tipo_denuncia" id="Usuario1" value="1" onChange={ this.handleChange } />
				<label htmlFor="Usuario1" className="for-checkbox-tools" >
					<img src={anonimo} width="130" alt="" />
					Denuncia Anónima
				</label>
			</div>
			<div className="col-md-6">
				<input className="checkbox-tools" type="radio" name="tipo_denuncia" id="Usuario2" value="2" onChange={ this.handleChange } />
				<label className="for-checkbox-tools" htmlFor="Usuario2">
					<img src={datosUsuario} width="130" alt=""/>
					Denuncia Abierta
				</label>
			</div>
			<br />
			<span id="valid_report" style={styleNoValidate}>
				Seleccione un tipo de reporte.
			</span>
			{ content }
		</Fragment>
	}
}
  
class SiEvidencias extends React.Component {
	constructor() {
		super();
		this.state = {
			checked: false,
			upload: false
		};
	  this.handleChange = this.handleChange.bind(this);
	  this.handleChangeUpload = this.handleChangeUpload.bind(this);
	}
	
	handleChangeUpload() {
		console.log('Subir:', document.getElementById('subirEvidencias').checked);
		if(document.getElementById('subirEvidencias').checked){
			upload = 1
			this.setState((state) => ({
				upload: true
			}))
		}else{
			upload = 2
			this.setState((state) => ({
				upload: false
			}))
		}
	}
	
	handleChange() {
		console.log('change');
		if(document.getElementById('siEvidencias').checked){
			evidences = 1
			this.setState((state) => ({
			  checked: true
			}))
		}else{
			evidences = 2
			this.setState((state) => ({
				checked: false
			}))
		}
	}
	
	render() {
	  const content = this.state.checked 
		  ? <div className="row px-3">
		<div className="col-12">
			<h2 className="text-center f-w-600 my-4">¿Quieres subir las evidencias?</h2>
		</div>

		<div className="col-md-6">
			<input className="checkbox-tools" type="radio" name="subir_evidencias" id="subirEvidencias" value="1" onChange={ this.handleChangeUpload } />
			<label className="for-checkbox-tools" htmlFor="subirEvidencias">
				Sí, quiero subir la evidencia
			</label>
		</div>
		
		<div className="col-md-6">
			<input className="checkbox-tools" type="radio" name="subir_evidencias" id="noSubirEvidencias" value="2" onChange={ this.handleChangeUpload } />
			<label className="for-checkbox-tools" htmlFor="noSubirEvidencias">
				No quiero subir la evidencia
			</label>
		</div>
	  </div>
		: null;
	  
	  return <Fragment>
		<div className="col-md-6">
			<input className="checkbox-tools" type="radio" name="evidencias" id="siEvidencias" value="1" onChange={ this.handleChange } />
			<label className="for-checkbox-tools" htmlFor="siEvidencias">
				Sí, cuento con evidencia
			</label>
		</div>
		<div className="col-md-6">
			<input className="checkbox-tools" type="radio" name="evidencias" id="noEvidencias" value="2" onChange={ this.handleChange } />
			<label className="for-checkbox-tools" htmlFor="noEvidencias">
				No cuento con evidencia
			</label>
		</div>
		{ content }
		
		{ this.state.upload ? 
			<EvidenciaDropzone />
			:
			null
		}
	</Fragment>
	}
}

const EvidenciaDropzone = () => {
    return (
		<div className="col-12 mt-3">
			<p className="text-center">Adjunta archivos como imágenes, documentos PDF, Word, Excel o Power Point.</p>
			<div className="dropzone dropzone-info text-center" id="fileTypeValidation">
				<div className="dz-message needsclick">
					<DropzoneComponent />
					<br />
					<span id="valid_files" style={styleNoValidate}>
						Por favor, adjunte archivos.
					</span>
				</div>
			</div>
		</div>
	);
};

const DropzoneComponent = () => {
	
	const handleChangeStatus = ({ meta, file }, status) => {
		if(status === 'done'){
			console.log('done')
			archivos_adjuntos.push(file)
		}
		if(status === 'removed'){
			console.log('removed')
			let index = archivos_adjuntos.findIndex(item => item === file)
			archivos_adjuntos.splice(index , 1)
		}
		console.log(archivos_adjuntos);
	}

	return (
	<Dropzone inputContent="Arrastra y suelta los archivos que quieras enviar" inputWithFilesContent="Agregar archivos" onChangeStatus={handleChangeStatus} submitButtonContent="" accept="image/*,audio/*,video/*" />
	);
};