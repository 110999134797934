import React, {Fragment} from 'react'
import CountUp from 'react-countup';

export default class Sell extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            ready: false
        }
        fetch('https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718,SF43787,SF43786/datos/oportuno?token=f2e9ffdc2502b4048708d0fbc266b8503323ff269f3b607c26e3b6072f33bb10')
        .then(res => res.json())
        .then(res => {
            console.log(res)
            let exchange = res.bmx.series
            this.sell = exchange.find(val => val.idSerie == "SF43786")
            this.setState(() => ({ready: true}))
        })
        .catch(err => {console.log(err)})
    }

    render(){
        return (
            
            this.state.ready ?

            <div className="card browser-widget">
                <div className="media card-body">
                    <div className="media-body align-self-center">
                        <div>
                            <h5 class="small-title">Venta Dolar </h5>
                            <h3 className="f-w-600"><span className="counter">
                            $ <CountUp className="counter" decimals={2} decimal="." end={this.sell.datos[0].dato} /></span></h3>
                            <small>MXN</small>
                        </div>
                    </div>
                </div>
            </div>

            // <div className="card browser-widget bg-color">
            //     <div className="media card-body">
            //         <div className="media border-after-xs mx-auto">
            //             <div className="align-self-center pr-3">
            //                 <h5 className="mb-0">Venta</h5>
            //             </div>
            //             <div className="media-body details pl-3 d-flex align-items-baseline">
            //                 <h3 className="f-w-600 mb-0 counter digits">
            //                 <CountUp className="counter" decimals={2} decimal="." end={this.sell.datos[0].dato} />
            //                 </h3>
            //                 <small className="ml-2">MXN</small>
            //             </div>
            //         </div>
            //     </div>
            // </div>
            :
            null
        )
    }
}