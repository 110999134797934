import React, { Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import FeatherComponent from '../common/featherComponent';
import { HelpCircle } from 'react-feather';
import { Collapse } from 'reactstrap';

const FaqComponent = () => {
    const [isCollaps, setIsCollaps] = useState(false);
    const [isCollaps1, setIsCollaps1] = useState(false);
    const [isCollaps2, setIsCollaps2] = useState(false);
    const [isCollaps3, setIsCollaps3] = useState(false);
    const [isCollaps4, setIsCollaps4] = useState(false);
    const [isCollaps5, setIsCollaps5] = useState(false);
    const [isCollaps6, setIsCollaps6] = useState(false);
    const [isCollaps7, setIsCollaps7] = useState(false);
    const [isCollaps8, setIsCollaps8] = useState(false);
    const [isCollaps9, setIsCollaps9] = useState(false);
    const [isCollaps10, setIsCollaps10] = useState(false);
    const [isCollaps11, setIsCollaps11] = useState(false);
    const [isCollaps12, setIsCollaps12] = useState(false);
    const [isCollaps13, setIsCollaps13] = useState(false);
    const [isCollaps14, setIsCollaps14] = useState(false);
    const [isCollaps15, setIsCollaps15] = useState(false);
    return (
        <Fragment>
            <Breadcrumb parent="Ayuda" title="Preguntas frecuentes" />
            <div className="container-fluid">
                <div className="faq-wrap">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-faq">
                                <h5 className="mb-0">Comunicados</h5>
                            </div>
                            <div className="row default-according style-1 faq-accordion" id="accordionoc">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps(!isCollaps)}
                                                    data-toggle="collapse" data-target="#collapseicon" aria-expanded={isCollaps} aria-controls="collapseicon">
                                                    <FeatherComponent dataFeather="help-circle" />
                                                    Integrating WordPress with Your Website?
                                                    </button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps1(!isCollaps1)}
                                                    data-toggle="collapse" data-target="#collapseicon2" aria-expanded={isCollaps1} aria-controls="collapseicon2">
                                                    <HelpCircle /> WordPress Site Maintenance ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps1}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps2(!isCollaps2)}
                                                    data-toggle="collapse" data-target="#collapseicon3" aria-expanded={isCollaps2} aria-controls="collapseicon2">
                                                    <HelpCircle />Meta Tags in WordPress ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps2}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps3(!isCollaps3)}
                                                    data-toggle="collapse" data-target="#collapseicon4" aria-expanded={isCollaps3} aria-controls="collapseicon2">
                                                    <HelpCircle />  WordPress in Your Language ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps3}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="faq-title">
                                        <h6>Publicaciones</h6>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps4(!isCollaps4)}
                                                    data-toggle="collapse" data-target="#collapseicon5" aria-expanded={isCollaps4}>
                                                    <HelpCircle /> WordPress Site Maintenance ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps4}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps5(!isCollaps5)}
                                                    data-toggle="collapse" data-target="#collapseicon7" aria-expanded={isCollaps5} aria-controls="collapseicon2">
                                                    <HelpCircle /> WordPress in Your Language ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps5}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps6(!isCollaps6)}
                                                    data-toggle="collapse" data-target="#collapseicon8" aria-expanded={isCollaps6} aria-controls="collapseicon2">
                                                    <FeatherComponent dataFeather="help-circle" /> Integrating WordPress with Your Website ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps6}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="faq-title">
                                        <h6>Mi cuenta</h6>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps7(!isCollaps7)}
                                                    data-toggle="collapse" data-target="#collapseicon9" aria-expanded={isCollaps7}>
                                                    <HelpCircle /> WordPress Site Maintenance ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps7}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps8(!isCollaps8)}
                                                    data-toggle="collapse" data-target="#collapseicon10" aria-expanded={isCollaps8} aria-controls="collapseicon2">
                                                    <HelpCircle />Meta Tags in WordPress ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps8}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps9(!isCollaps9)}
                                                    data-toggle="collapse" data-target="#collapseicon11" aria-expanded={isCollaps9} aria-controls="collapseicon2">
                                                    <HelpCircle />Validating a Website ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps9} >
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps10(!isCollaps10)}
                                                    data-toggle="collapse" data-target="#collapseicon12" aria-expanded={isCollaps10} aria-controls="collapseicon2">
                                                    <HelpCircle />Know Your Sources ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps10}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="faq-title">
                                        <h6>Teléfono rojo</h6>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps11(!isCollaps11)}
                                                    data-toggle="collapse" data-target="#collapseicon13" aria-expanded={isCollaps11}>
                                                    <FeatherComponent dataFeather="help-circle" />Integrating WordPress with Your Website ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps11}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps12(!isCollaps12)}
                                                    data-toggle="collapse" data-target="#collapseicon14" aria-expanded={isCollaps12} aria-controls="collapseicon2">
                                                    <HelpCircle />WordPress Site Maintenance ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps12}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps13(!isCollaps13)}
                                                    data-toggle="collapse" data-target="#collapseicon16" aria-expanded={isCollaps13} aria-controls="collapseicon2">
                                                    <HelpCircle /> WordPress in Your Language ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps13}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps14(!isCollaps14)}
                                                    data-toggle="collapse" data-target="#collapseicon17" aria-expanded={isCollaps14} aria-controls="collapseicon2">
                                                    <HelpCircle />  Validating a Website ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps14}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed pl-0" onClick={() => setIsCollaps15(!isCollaps15)}
                                                    data-toggle="collapse" data-target="#collapseicon18" aria-expanded={isCollaps15} aria-controls="collapseicon2">
                                                    <HelpCircle />Meta Tags in WordPress ?</button>
                                            </h5>
                                        </div>
                                        <Collapse isOpen={isCollaps15}>
                                            <div className="card-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FaqComponent;