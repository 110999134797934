import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tablereact from '../common/tabulator/tablereact'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Empresas = (props) => {

	const [empresas, setEmpresas] = useState(false);

	useEffect(() => {
		fetch('/p_empresas')
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
			if(res.status === 'OK'){
				setEmpresas(res.result)
			}else{
				console.log('ERROR')
			}
		})
		.catch((err) => {
			console.log('ERROR: '+err)
		})
	}, []);

	const cabecera = [
        { Header: <b>Id</b>, accessor: 'ID', style: { textAlign: 'center'}, width:'80', show:false },
		{ Header: <b>Nombre</b>, accessor: 'NOMBRE_INTERNO', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
		{ Header: <b>Razon Social</b>, accessor: 'RAZON_SOCIAL', style: { textAlign: 'center'}, width:'auto', editable:true , show:true},
        { Header: <b>Fecha de publicación</b>, accessor: 'FECHA_ALTA', style: { textAlign: 'center'}, width:'200', editable:true , show:true}
	];

	const actualizar=(dato)=>props.history.push('/empresas/editar/'+dato.ID)
	const agregar=()=>props.history.push('/empresas/agregar')
	const eliminar=(dato)=>{
		fetch('/p_empresas',{ method: 'DELETE',	body: JSON.stringify({id: dato.ID}), headers: { 'Content-Type': 'application/json' }})
		.then( res => res.json() )
		.then( res => {
			if(res.status === 'OK'){
				setEmpresas(empresas.filter(x=>x.ID!==dato.ID))
				toast.success("Se eliminó el registro", { position: toast.POSITION.BOTTOM_RIGHT })
			}
			else
				toast.warn("No se pudo borrar el registro", { position: toast.POSITION.BOTTOM_RIGHT })
		})
		.catch( (err) => {
			toast.error("Ha ocurrido un error", { position: toast.POSITION.BOTTOM_RIGHT })
		})
	}

	return (
		<Fragment>
			<Breadcrumb parent="Empresas"/>
			<div className="card">
				<div className="container-fluid">
					{
						!empresas ?
						<div className="d-flex justify-content-center align-items-center">
							<div className="loader-box mr-4">
								<div className="loader">
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
									<div className="line bg-warning"></div>
								</div>
							</div>
							<h5 className="text-uppercase">Cargando...</h5>
						</div>
						:
					<div className="row">
						<div className="col-sm-12 mt-3">
						<Tablereact
                            		myData={empresas}
                            		cabecera={cabecera}
                            		pageSize={10}
                            		paginacion={true}
                            		buscador='Buscar Empresa'
                            		eliminar_actualizar={{eliminar,actualizar}}
                            		boton={{text:'Agregar una Empresa',function:agregar}}
                        		/>
						</div>
					</div>
					}
				</div>
			</div>
		</Fragment>
	);
}
 
export default Empresas;