import React, { useState } from 'react';
import IconoLogo from '../assets/images/logo/icono-logo.png'
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import buzonPublico from '../assets/images/ilustraciones/buzon-publico.png'
import usuario from '../assets/images/ilustraciones/login-usuario.png'
import usuarioBrazo from '../assets/images/ilustraciones/login-usuario-brazo.png'
import usuarioSombra from '../assets/images/ilustraciones/login-sombra.png'
import usuarioMail from '../assets/images/ilustraciones/login-mail.png'
import usuarioMailSobre from '../assets/images/ilustraciones/login-sobre.png'
import dialogo from '../assets/images/ilustraciones/login-dialogo.png'
import buzon from '../assets/images/ilustraciones/login-buzon.png'
import buzonPlantas from '../assets/images/ilustraciones/login-plantas.png'
import bgShape from '../assets/images/ilustraciones/login-bg.png'
import nubes from '../assets/images/ilustraciones/login-nubes.png'

const Login = () => {

	const [view, setView] = useState(true);
	const [chk_doble_auth, setChk_doble_auth] = useState(false);
	const [c_auth] = useState(localStorage.getItem('c_auth') ? localStorage.getItem('c_auth') : '');

	const setAlert = (type, text) => {
		let alert = document.getElementById('alerta_login')
		alert.style['display'] = 'block'
		alert.innerText = text
		alert.className = 'alert mt-3 mb-0 alert-' + type
	}

	const validarCorreoRec = () => {
		let correo = document.getElementById('rcorreo').value;
		fetch('/z_recuperar', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ correo })
		})
			.then(res => res.json())
			.then(res => {
				console.log(res)
				if (res.RESULT === 1)
					setAlert('success', 'Te hemos enviado un correo.')
				else if (res.RESULT === 3)
					setAlert('warning', 'Este correo no ha sido activado.')
				else
					setAlert('warning', 'El correo no existe.')
			})
			.catch(err => {
				setAlert('danger', 'Ha ocurrido un error, inténtelo más tarde')
			})
	}

	const validateUser = () => {
		document.getElementById('alerta_login').style['display'] = 'none'
		let form_data = new FormData(document.getElementById('form_login'));

		var object = {};
		form_data.forEach((value, key) => object[key] = value);

		fetch('/z_login', {
			method: 'POST',
			body: JSON.stringify({
				...object,
				c_auth,
				chk_doble_auth
			}),
			headers: { 'Content-Type': 'application/json' }
		})
			.then(res => res.json())
			.then(res => {
				console.log(res);
				if (res.RESULT === 1) {
					localStorage.setItem('id_usr', res.ID)
					localStorage.setItem('tipo_envio', res.TIPO_ENVIO)
					if (res.CAUT && res.UAGENT) {
						window.location.replace('/comunicados/inicio')
					}
					else {
						if (res.TIPO === 3 || res.TIPO === 4) localStorage.setItem('new_usr', 1)
						else localStorage.setItem('new_usr', 0)

						if (res.TIPO === 1) {
							localStorage.setItem('type_usr', 1)
							localStorage.setItem('tipo', 'admin')
						} else {
							localStorage.setItem('type_usr', 2)
							localStorage.setItem('tipo', 'usr')
						}
						if (res.TIPO === 1 || res.TIPO === 2) {
							window.location.replace('/autenticacion')
						} else {
							window.location.replace('/cambiar_contrasena')
						}
					}

				}
				else if (res.RESULT === 2) {
					setAlert('danger', 'Usuario o contraseña incorrectos')
				}
				else if (res.RESULT === 3) {
					setAlert('danger', 'Ya hay una sesion activa')
				}
				else if (res.RESULT === 4) {
					localStorage.removeItem('c_auth')
					window.location.replace('/comunicados/inicio')
				}
			})
			.catch(err => {
				console.log(err)
				setAlert('danger', 'Ha ocurrido un error, inténtelo más tarde')
			})
	}

	return (
		<div>
			<div className="page-wrapper">
				<div className="container-fluid p-0">
					{/* <!-- login page start--> */}
					<div className="authentication-main p-0">
						<div className="row">
							<div className="col-sm-12 col-md-6 col-lg-8 bg-login-ilustracion">
								<div className="animation-wrapper">
									<div className="buzon-publico">
										<Bounce duration={1400} top>
											<img src={buzonPublico} alt=""/>
										</Bounce>
									</div>
									<div className="usuario">
										<Bounce duration={1400} right>
											<img src={usuario} alt=""/>
										</Bounce>
									</div>
									<div className="usuario-brazo">
										<Bounce duration={1400} right>
											<img src={usuarioBrazo} alt=""/>
										</Bounce>
									</div>
									<div className="usuario-sombra">
										<Bounce duration={1400} bottom>
											<img src={usuarioSombra} alt=""/>
										</Bounce>
									</div>
									<div className="usuario-mail">
										<Bounce duration={1400} delay={400} top>
											<img src={usuarioMail} alt=""/>
										</Bounce>
									</div>
									<div className="usuario-mail-sobre">
										<Bounce duration={1400} right>
											<img src={usuarioMailSobre} alt=""/>
										</Bounce>
									</div>
									<div className="dialogo">
										<Bounce delay={1400} duration={1200}>
											<img src={dialogo} alt=""/>
										</Bounce>
									</div>
									<div className="buzon">
										<Bounce delay={300}>
											<img src={buzon} alt=""/>
										</Bounce>
									</div>
									<div className="buzon-plantas">
										<Bounce bottom delay={200}>
											<img src={buzonPlantas} alt=""/>
										</Bounce>
									</div>
									<div className="bg-shape">
										<Fade>
											<img src={bgShape} alt=""/>
										</Fade>
									</div>
									<div className="nubes">
										<Fade duration={5000} left>
											<img src={nubes} alt=""/>
										</Fade>
									</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-4 bg-login-form">
								<div className="auth-innerright">
									<div className="authentication-box p-4">
										<div className="text-center"><img className="img-responsive" width="150" height="150" src={IconoLogo} alt="" /></div>
										{
											view ?
												<div className="mt-4">
													<div className="text-center">
														<h4 className="txt-naranja-buzon">INGRESAR</h4>
													</div>
													<form id="form_login" className="login-form">
														<div className="row">
															<div className="col-11 col-md-8 mx-auto">
																<div className="mb-3 mt-3">
																	{/* <label className="col-form-label pt-0">Usuario</label> */}
																	<input className="form-control" placeholder="Usuario" type="text" name="correo" required="" />
																</div>
															</div>
															<div className="col-11 col-md-8 mx-auto">
																<div className="mb-3 mt-3">
																	{/* <label className="col-form-label">Contraseña</label> */}
																	<input className="form-control" placeholder="Contraseña" type="password" name="contrasena" required="" />
																</div>
															</div>
															{c_auth !== '' &&
																<div className="col-11 col-md-8 mx-auto">
																	<div className="mb-3 mt-3">
																		<div className="form-group mb-0">
																			<div className="form-group btn-showcase d-flex mb-0 justify-content-center">
																				<div className="checkbox p-0">
																					<input id="checkbox1" type="checkbox" onChange={() => { setChk_doble_auth(!chk_doble_auth) }} value={chk_doble_auth} />
																					<label htmlFor="checkbox1">No confiar en este dispositivo</label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															}
															<div className="col-12 mx-auto">
																<div className="form-group form-row mt-3 mb-0">
																	<button onClick={validateUser} className="btn btn-pill btn-warning btn-lg mx-auto" type="button">Entrar</button>
																</div>
															</div>
														</div>
														<div className="col-md-12 text-center mt-4">
															<a href="#" style={{ color: '#FB803D' }} onClick={() => { document.getElementById('alerta_login').style['display'] = 'none'; setView(!view) }}>¿Olvidó su contraseña? <i className="fa fa-arrow-circle-right"></i></a>
														</div>
													</form>
													<div id="alerta_login" style={{ display: 'none' }}></div>
												</div>
												:
												<div className="mt-4">
													<div className="text-center">
														<h4 className="txt-naranja-buzon">¿OLVIDÓ SU CONTRASEÑA?</h4>
													</div>
													<form id="form_recuperacion" className="login-form">
														<div className="row">
															<div className="col-11 col-md-8 mx-auto">
																<div className="mb-3 mt-3">
																	<input id="rcorreo" className="form-control" placeholder="Ingrese el Correo" type="text" name="rcorreo" required="" />
																</div>
															</div>
															<div className="col-12 mx-auto">
																<div className="form-group form-row mt-3 mb-0">
																	<button onClick={() => { validarCorreoRec() }} className="btn btn-pill btn-warning btn-lg mx-auto" type="button">Solicitar contraseña</button>
																</div>
															</div>
															<div className="col-md-12 text-center mt-4">
																<a href="#" style={{ color: '#FB803D' }} onClick={() => { document.getElementById('alerta_login').style['display'] = 'none'; setView(!view) }}><i className="fa fa-arrow-circle-left"></i> Regresar</a>
															</div>
														</div>
													</form>
													<div id="alerta_login" style={{ display: 'none' }}></div>
												</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;

