import React, {useState} from 'react'

const Notificaciones = () => {

    const [publicVapidKey] = useState("BENKnyC9VOLHFnWhvTi2AZpgaWES6bFjNHhlDZo47-xtAyFSU54r02nl_ciubUqs-Yk7z8wYaj20eEIWdmd8agA");

    const urlBase64ToUint8Array=base64String=>{
        const padding = "=".repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
          .replace(/\/-/g, "+")
          .replace(/_/g, "/");
      
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
      
        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    const activarNotificaciones= async () =>{
        if ("serviceWorker" in navigator) {
            const register = await navigator.serviceWorker.register('/worker.js');
            const suscripcion = await register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
            });

            console.log("Sending Push...", suscripcion);
            await fetch("/z_notificaciones", {
                method: "POST",
                body: JSON.stringify({ID: localStorage.getItem('id_usr'), SUSCRIPCION: suscripcion}),
                headers: {
                "content-type": "application/json"
                }
            });
        }
    }
    
    return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="form-control">Activar Notificaciones</label>
                            <button className="btn btn-primary" onClick={activarNotificaciones()}>Activar</button>
                        </div>
                    </div>
                </div>
            </div>
    );
}
 
export default Notificaciones;

