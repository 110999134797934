import React from 'react';
import { UncontrolledCarousel } from 'reactstrap';

/* const items = [
  {
    src:'https://ep01.epimg.net/elpais/imagenes/2019/10/30/album/1572424649_614672_1572453030_noticia_normal.jpg',
    altText: 'Slide 1',
    //caption: 'Slide 1',
    header: 'Slide 1 Header'
  },
  {
    src:'https://ep01.epimg.net/elpais/imagenes/2019/10/30/album/1572424649_614672_1572453030_noticia_normal.jpg',
    altText: 'Slide 2',
    caption: 'Slide 2',
    header: 'Slide 2 Header'
  },
  {
    src:'https://ep01.epimg.net/elpais/imagenes/2019/10/30/album/1572424649_614672_1572453030_noticia_normal.jpg',
    altText: 'Slide 3',
    caption: 'Slide 3',
    header: 'Slide 3 Header'
  }
]; */

const CarouselTwo = (props) => <UncontrolledCarousel  items={props.galeria} interval={false}/>;

export default CarouselTwo;