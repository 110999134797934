import React, { Fragment } from 'react';
import Breadcrumb from "../common/breadcrumb";
import 'react-dropzone-uploader/dist/styles.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class EventosEditar extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			ready: false
		}
		this.id = this.props.match.params.id
		fetch('/z_eventos',{
			method: 'POST',
			body: JSON.stringify({ID: this.id}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then((res) => res.json())
		.then((res) => {
			console.log(res);
				if(res.status === 'OK'){
						this.evento = res.result[0]
						this.setState((s) => ({ ready: true }))
				}else{
						console.log('ERROR')
				}
		})
		.catch((err) => {
				console.log('ERROR: '+err)
		})
	}

	saveButton(){
		let form_data = new FormData(document.getElementById('form_eventos'))
		form_data.append('ID', this.id)
		fetch('/p_eventos', {
			method: 'PUT',
			body: form_data
		})
		.then(res => res.json())
		.then(res => {
			if(res.status === 'OK' && res.rows[0] > 0){
				toast.success("Actualizado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
				console.log('Actualizado');
			}
			else{
				console.log('No se pudo actualizar');
			}
		})
		.catch(err => {console.log('Error: '+err);})
	}

	backButton = ()=> {
		this.props.history.push('/eventos')
	}

	mostrarFiltros(){
		console.log(this.filtro_empresas);
	}

	showFiles(){
		console.log(document.getElementById('form_archivos').files)
	}

	editorData(event){
		this.contenido_html = event.editor.getData()
	}

	render(){
		return (
		<Fragment>
			<Breadcrumb title="Editar" parent="Eventos" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<form id="form_eventos" className="form theme-form">
							<div className="card">
								<div className="card-header">
									<h5>Información del evento</h5>
								</div>
								{
									this.state.ready === true ?
								<div className="card-body add-post">
										<div className="col-sm-12">
											<div className="form-group">
												<label className="form-label">Título:</label>
												<input className="form-control" name="TITULO" defaultValue={this.evento.TITULO} type="text" required="" />
											</div>
											<div className="form-group">
												<label className="form-label">Fecha:</label>
												<input className="form-control" name="FECHA" defaultValue={this.evento.FECHA} type="date" required="" />
											</div>
											<div className="form-group">
												<label className="form-label">Horario:</label>
												<input className="form-control" name="HORARIO" defaultValue={this.evento.HORARIO} type="text" required="" />
											</div>
											<div className="form-group">
												<label className="form-label">Detalles del evento: </label>
												<textarea name="DESCRIPCION" defaultValue={this.evento.DESCRIPCION} className="form-control" rows="5" />
											</div>
										</div>
								</div>
									:
									<div className="card-body d-flex justify-content-center align-items-center">
										<div className="loader-box mr-4">
											<div className="loader">
												<div className="line bg-warning"></div>
												<div className="line bg-warning"></div>
												<div className="line bg-warning"></div>
												<div className="line bg-warning"></div>
											</div>
										</div>
										<h5 className="text-uppercase">Cargando...</h5>
									</div>
								}
								<div className="card-footer">
									<div className="row">
										<div className="col-12">
											<div className="btn-showcase">
												<button onClick={this.saveButton.bind(this)} className="btn btn-primary mr-1" type="button">Guardar</button>
												<button onClick={this.backButton} className="btn btn-light txt-dark" type="button">Atrás</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	)};
}