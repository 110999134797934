import React, {Fragment, useState, useEffect} from 'react';
import Breadcrumb from '../common/breadcrumb';

const Publicacion = (props) => {

	const [id] = useState(props.match.params.id);
	const [ready, setReady] = useState(false);
	const [publicacion, setPublicacion] = useState([]);
	const [videos, setVideos] = useState([]);

	useEffect(() => {
		fetch('/z_publicaciones', {
			method: 'POST',
			body: JSON.stringify({ID: id}),
			headers: {'Content-Type': 'application/json'}
		})
		.then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status === 'OK'){
				setPublicacion(res.result[0][0])
				setVideos(res.result[1])
			}else{
				console.log('Error interno');
			}
			setReady(true)
		})
		.catch(err => {
			console.log('Error: '+err);
		})
		// eslint-disable-next-line
	}, []);

	const tags = {
		borderBottom: "1px solid #e5e5e5",
		borderTop: "1px solid #e5e5e5",
		padding: "20px 0"
	}
	const etiquetasA = {
		color: '#222',
		position: 'relative',
		padding: '5px 15px',
		display: 'block',
		fontWeight: '600',
	}
	const etiquetasLi = {
		display: 'inline-block',
		lineHeight: '33px',
		backgroundColor: '#f1f1f1',
		margin: '10px 5px'
	}

	return (
		<Fragment>
			<Breadcrumb title="Detalles" parent="Publicación" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
								{ ready === true ? 
								publicacion ?
								<Fragment>
									<div className="card-header text-center">
										<h2 className="f-w-600 m-0">{publicacion.TITULO}</h2>
									</div>
									<div className="card-body">
										<div className="blog-single container">
											<div className="blog-box blog-details">
												<img className="img-fluid w-100" src={`/z_publicaciones?img=${id+publicacion.IMAGEN	}`} alt="blog-main" />
												<div className="blog-details">
													<ul className="blog-social">
														<li className="digits">{publicacion.FECHA_ALTA}</li>
														<li><i className="icofont icofont-user"></i>{publicacion.NOMBRE+' '+publicacion.APELLIDO_PATERNO+' '+publicacion.APELLIDO_MATERNO}</li>
													</ul>
													<div className="mt-3" dangerouslySetInnerHTML={{__html: publicacion.CONTENIDO}} />
													<div className="row justify-content-center">
													{
														!!videos.length &&
														videos.map((x,i)=>{
															return <div key={i} className="mt-4 col-11">
																<div className="embed-responsive embed-responsive-16by9 text-center">
															  		<iframe title={i} className="embed-responsive-item text-center" src={x.CODE}></iframe>
																</div>
															</div>
														})
													}
													</div>
													<div>
														<p>
															Fuente de información: <a><em>{publicacion.FUENTE}</em></a>
														</p>
														<div className="tags" style={tags}>
															<h6 style={{display: 'inline-block',
																		marginRight: '20px'}}>Etiquetas:</h6>
															<ul style={{display: 'inline-block'}}>
																{
																	publicacion.ETIQUETAS.split(',').map((elem, index) => <li key={index} style={etiquetasLi}><a style={etiquetasA} href="../../">{elem}</a></li>)
																}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Fragment>
								:
								<div className="card-body d-flex justify-content-center">
									<h5 className="text-uppercase">Sin contenido</h5>
								</div>
								:
								<div className="card-body d-flex justify-content-center align-items-center">
									<div className="loader-box mr-4">
										<div className="loader">
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
											<div className="line bg-warning"></div>
										</div>
									</div>
									<h5 className="text-uppercase">Cargando...</h5>
								</div>
								}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default Publicacion;