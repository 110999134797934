import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PuestosAgregar = ({view, setView}) => {

	const [puesto, setPuesto] = useState('');

	useEffect(() => {
		if(view){
			fetch('/p_puestos', { method: 'PATCH', body: JSON.stringify({ id: view }), headers: { 'Content-Type': 'application/json' }})
				.then(res => res.json())
				.then(res => { setPuesto(res.result.NOMBRE) })
				.catch(err => {console.log('Error: '+err);})
		}
		// eslint-disable-next-line
	}, []);

	const saveButton=()=>{
		if(puesto.trim()!==''){
			if(!view){
				fetch('/p_puestos', { method: 'POST', body: JSON.stringify({ nombre:puesto }), headers: { 'Content-Type': 'application/json' }})
				.then(res => res.json())
				.then(res => {
					if(res.status === 'OK' && res.rows[0] > 0){
						toast.success("Agregado correctamente", { position: toast.POSITION.BOTTOM_RIGHT })
						setView(-1)
					}
					else{
						toast.warn("No se pudo agregar, inténtelo más tarde", { position: toast.POSITION.BOTTOM_RIGHT })
					}
				})
				.catch(err => {console.log('Error: '+err);})
			}
			else{
				fetch('/p_puestos', { method: 'PUT', body: JSON.stringify({id: view, nombre :puesto}), headers: { 'Content-Type': 'application/json' } })
				.then( res => res.json() )
				.then( res => {
					if(res.status === 'OK'){
						toast.success("Se actualizó el registro", { position: toast.POSITION.BOTTOM_RIGHT })
						setView(-1)
					}
					else
						toast.warn("No se pudo borrar el registro", { position: toast.POSITION.BOTTOM_RIGHT })
				})
				.catch( (err) => {
					console.log(err);
					toast.error("Ha ocurrido un error", { position: toast.POSITION.BOTTOM_RIGHT })
				})
			}
		}
		else{
			toast.warn("Tienes campos vacíos", { position: toast.POSITION.BOTTOM_RIGHT })
		}
	}

	const backButton=()=>setView(-1)

	return (
		<Fragment>
			<Breadcrumb title="Agregar" parent="Puestos" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<h5>Datos del puesto</h5>
							</div>
							<form id="form_puestos" className="form theme-form">
								<div className="card-body">
									<div className="row">
										<div className="col">
											<div className="form-group">
												<label>Nombre: </label>
												<div>
													<input name="nombre" className="form-control" type="text" value={puesto} onChange={(e)=>{setPuesto(e.target.value)}} />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-12">
											<div className="btn-showcase">
												<button onClick={saveButton} className="btn btn-primary mr-1" type="button">Guardar</button>
												<button onClick={backButton} className="btn btn-light txt-dark" type="button">Atrás</button>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default PuestosAgregar;