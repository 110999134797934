import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Star, Gift } from 'react-feather';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/sass/styles.scss';

const localizer = momentLocalizer(moment)
let allViews = Object.keys(Views).map(k => Views[k])
require('moment/locale/es.js');


const Calendario = () => {

	const [events, setEvents] = useState([]);
	const [eventos, setEventos] = useState([]);
	const [ready, setReady] = useState(false);
	const [month] = useState([
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre'
	]);

	useEffect(() => {
		fetch('/p_calendario')
		.then(res => res.json())
		.then(res => {
			console.log(res);
			if(res.status == 'OK'){
				let date = new Date(), endDate, eventos_tmp
				let events_tmp=[]
				let year = date.getFullYear()
				res.result[0] = res.result[0].map(fecha => {fecha.FECHA = fecha.FECHA+' '+year; return fecha})
				eventos_tmp = res.result[0].concat(res.result[1])
				eventos_tmp = eventos_tmp.concat(res.result[2])
				eventos_tmp.map(evento => {
					evento.DATE = new Date(evento.FECHA)
					if(evento.FESTIVO)
						evento.DATE.setYear(year)
					endDate = new Date(evento.DATE)
					endDate.setDate(endDate.getDate()+1)
					events_tmp.push({
						id: evento.ID,
						title:evento.TITULO || (evento.NOMBRE+' '+evento.APELLIDO_PATERNO+' '+evento.APELLIDO_MATERNO),
						allDay: true,
						start:evento.DATE,
						end: endDate
					})
				})
				eventos_tmp.sort((a, b) => b.DATE - a.DATE)
				setEventos(eventos_tmp)
				setEvents(events_tmp)
			}
			setReady(true)
		})
	}, []);

	const formatDate=(date)=>(date.getDate())+' de '+ month[date.getMonth()]+', '+date.getFullYear();

	return (
		<Fragment>
			<Breadcrumb parent="Calendario" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<h5>Eventos próximos</h5>
							</div>
							{ ready ?
							<div className="card-body col-sm-12 d-block d-sm-block d-md-none d-lg-none d-xl-none">
							<VerticalTimeline>
								{eventos.map((evento, index) => {
									if(evento.TIPO === 0){
										return <VerticalTimelineElement 
											key={index}
											className="vertical-timeline-element--work birthday"
											animate={true}
											date = {formatDate(evento.DATE)}
											icon={<Gift />}
										>
											<div className="row">
												<div className="col-md-4">
													<img id="img_usr" className="pro my-4 img-fluid" alt="" src={'/image?img='+evento.ID+evento.FOTOGRAFIA} data-intro="This is Profile image"/>
												</div>
												<div className="col-md-8">
													<h3 className="vertical-timeline-element-subtitle f-w-600 mb-3">Cumpleaños</h3>
													<h5>{evento.NOMBRE+' '+evento.APELLIDO_PATERNO+' '+evento.APELLIDO_MATERNO}</h5>
												</div>
											</div>
										</VerticalTimelineElement>
									}else{
										return <VerticalTimelineElement 
											key={index}
											className="vertical-timeline-element--work"
											animate={true}
											date = {formatDate(evento.DATE)}
											icon={<Star />}
										>
											<h3 className="vertical-timeline-element-subtitle f-w-600">{evento.TITULO}</h3>
											{evento.HORARIO && <h6 className="f-w-400 mt-3 mb-5">Horario: {evento.HORARIO}</h6>}
											<p>{evento.DESCRIPCION}</p>
										</VerticalTimelineElement>
									}
								})
								}
							</VerticalTimeline>
							</div>
							: <div className="card-body d-flex justify-content-center align-items-center">
								<div className="loader-box mr-4">
									<div className="loader">
										<div className="line bg-warning"></div>
										<div className="line bg-warning"></div>
										<div className="line bg-warning"></div>
										<div className="line bg-warning"></div>
									</div>
								</div>
								<h5 className="text-uppercase">Cargando...</h5>
							</div>
							}
							<div className="card-body col-sm-12 d-none d-sm-none d-md-block d-lg-block d-xl-block">
								<div className="card-body">
									{ ready &&
									<Calendar
										localizer={localizer}
										scrollToTime={new Date(1970, 1, 1, 6)}
										onSelectEvent={event => alert(event.title)}
										views={allViews}
										events={events}
										eventOverlap
										dragRevertDuration={500}
										dragScroll
										showMultiDayTimes
										step={60}
										startAccessor="start"
										endAccessor="end"
										messages={{
											next: ">",
											previous: "<",
											today: "Hoy",
											month: "Mes",
											week: "Semana",
											day: "Día"
										  }}
									/>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
 
export default Calendario;