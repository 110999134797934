import React from "react";

export default function SVGFile() {
  return (
    <svg
        version="1.1"
        id="cloudLightningFill"
        className="climacon climacon_cloudLightningFill"
        viewBox="15 15 70 70">
        <g className="climacon_iconWrap climacon_iconWrap-cloudLightningFill">
            <g className="climacon_wrapperComponent climacon_wrapperComponent-lightning">
                <polygon
                    className="yellow-fill climacon_component climacon_component-stroke climacon_component-stroke_lightning"
                    points="48.001,51.641 57.999,51.641 52,61.641 58.999,61.641 46.001,77.639 49.601,65.641 43.001,65.641 " />
            </g>
            <g className="climacon_componentWrap climacon_componentWrap_cloud">
                <path
                    className="gray-fill climacon_component climacon_component-stroke climacon_component-stroke_cloud"
                    d="M43.945,65.639c-8.835,0-15.998-7.162-15.998-15.998c0-8.836,7.163-15.998,15.998-15.998c6.004,0,11.229,3.312,13.965,8.203c0.664-0.113,1.338-0.205,2.033-0.205c6.627,0,11.998,5.373,11.998,12c0,6.625-5.371,11.998-11.998,11.998C57.168,65.639,47.143,65.639,43.945,65.639z" />
                <path
                    className="gray-fill climacon_component climacon_component-fill climacon_component-fill_cloud"
                    fill="#FFFFFF"
                    d="M59.943,61.639c4.418,0,8-3.582,8-7.998c0-4.417-3.582-8-8-8c-1.601,0-3.082,0.481-4.334,1.291c-1.23-5.316-5.973-9.29-11.665-9.29c-6.626,0-11.998,5.372-11.998,11.999c0,6.626,5.372,11.998,11.998,11.998C47.562,61.639,56.924,61.639,59.943,61.639z" />
            </g>
        </g>
    </svg>

);
}